import styled from '~/utils/styled';
import { Box } from 'rebass';

export const VideoWrapper = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%'
});

export const VideoBox = styled(Box)({
  paddingTop: (9/16 * 100)+'%',
  position: 'relative'
});


VideoBox.defaultProps = {
  my: 3, 
};

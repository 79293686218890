import React, { useRef, Fragment } from 'react';
import Button from '~/components/common/Button';
import { Input, Select, Textarea } from '@rebass/forms';
import FormField, { FormFieldRow } from '~/components/common/FormField';
import ButtonGroup, { ButtonGroupItem } from '~/components/common/ButtonGroup';
import {
  useCraftTranslations,
  useCraftGlobals,
} from '~/utils/hooks/useCraftGlobals';
import { Formik } from 'formik';
import { useLocalStorage } from '~/utils/hooks/useLocalStorage';
import { Box, Text } from 'rebass';
import Link from '../Link';
import { replaceComponents } from '~/utils/replaceComponents';

const FORTNIGHT = 12096e5;

/**
 * The initial values for this component are aggregated
 * into the main wizard component
 */
export type FormContactValues = {
  senderName: string;
  email: string;
  subject: string;
  message: string;
  'form-name': string;
};

type FormContactProps = {
  name: string;
  subjects: { subject: string }[];
};

function postForm(form: HTMLFormElement, values: any) {
  const action = form.getAttribute('action');

  if (!action) throw new Error('No action');

  return fetch(action, {
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams(values),
  });
}

export default function FormContact({ name, subjects }: FormContactProps) {
  const t = useCraftTranslations();
  const { globalPrivacy } = useCraftGlobals();
  const privacyUrl = globalPrivacy?.cookieInfoLink?.url ?? undefined;

  const formRef = useRef<any>();
  const [formSent, setFormSent] = useLocalStorage<boolean>(
    `ContactFormSent(${name})`,
    false,
    FORTNIGHT,
  );

  const validateEmail = (value: any) => {
    if (!value) {
      return `${t('companyData.email')} ${t('is required')}`;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return `${t('companyData.email')} ${t('is invalid')}`;
    }
  };

  if (formSent) {
    return (
      <Box py={2}>
        <Text css={{ textAlign: 'center', fontWeight: 600 }}>
          {t(
            'We have received you message. We will get back to you as soon as possible.',
          )}
        </Text>
        <ButtonGroup justifyContent="center">
          <ButtonGroupItem>
            <Button
              width="100%"
              iconName="none"
              textTransform="uppercase"
              variant="filledWhite"
              onClick={() => {
                setFormSent(false);
              }}
            >
              {t('Send another message')}
            </Button>
          </ButtonGroupItem>
        </ButtonGroup>
      </Box>
    );
  }

  return (
    <Formik
      initialValues={{
        senderName: '',
        email: '',
        subject: '',
        message: '',
        'form-name': name,
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          if (!formRef.current) return false;
          const { ok } = await postForm(formRef.current, values);
          if (ok) {
            resetForm();
            setFormSent(true);
          }
        } catch (o_O) {
          /* noop */
        }

        setSubmitting(false);
      }}
    >
      {formikProps => (
        <form
          ref={formRef}
          onSubmit={formikProps.handleSubmit}
          method="post"
          action="/"
          data-netlify="true"
          name={name}
        >
          <FormFieldRow>
            <FormField
              name="senderName"
              type="text"
              component={Input}
              required
            />
            <FormField
              name="email"
              type="email"
              component={Input}
              required
              validate={validateEmail}
            />
          </FormFieldRow>

          <FormFieldRow>
            <FormField name="subject" component={Select} required>
              <option value="">{t('Please choose')}</option>
              {subjects.map(({ subject }) => (
                <option key={subject} value={subject}>
                  {subject}
                </option>
              ))}
            </FormField>
          </FormFieldRow>

          <FormFieldRow>
            <FormField name="message" component={Textarea} required />
          </FormFieldRow>

          <ButtonGroup
            justifyContent="stretch"
            flexWrap={{ _: 'wrap', xl: 'nowrap' }}
          >
            <ButtonGroupItem flex="1 1 55%">
              <Text fontSize={1} color="dim">
                {replaceComponents(
                  t(
                    'By sending a message I confirm that I have read and accepted the %s.',
                  ),
                  '%s',
                  <Link to={privacyUrl} target="_blank">
                    {t('privacy policy')}
                  </Link>,
                )}
              </Text>
            </ButtonGroupItem>
            <ButtonGroupItem flex="1 0 260px">
              <Button
                width="100%"
                iconName="none"
                textTransform="uppercase"
                type="submit"
                variant="filled"
                disabled={formikProps && formikProps.isSubmitting}
              >
                {formikProps && formikProps.isSubmitting
                  ? t('Sending message…')
                  : t('Send message')}
              </Button>
            </ButtonGroupItem>
          </ButtonGroup>
        </form>
      )}
    </Formik>
  );
}

import React from 'react';
import { Box } from 'rebass';
import media from '~/utils/mediaqueries';
import { sizes } from '~/themes/baseTheme';

export default function PageHeaderSpacing() {
  return (
    <Box
      css={{
        paddingTop: sizes.header.height.default,
        [media('sm')]: { paddingTop: sizes.header.height.sm },
        [media('md')]: { paddingTop: sizes.header.height.md },
        [media('lg')]: { paddingTop: sizes.header.height.lg },
        [media('xl')]: { paddingTop: sizes.header.height.xl },
      }}
    />
  );
}

import React from 'react';
import { graphql } from 'gatsby';

import BlockService from '~/components/common/BlockService';
import CraftComponents from '~/components/craft/CraftComponents';
import { Craft_PageBuilderBlockService } from '~/query';

type CraftBlockServiceProps = {
  data: Craft_PageBuilderBlockService;
};

export default function CraftBlockService({ data }: CraftBlockServiceProps) {
  const { children, blockServiceImage } = data;

  // prettier-ignore
  const availableComponents = {
    ElementAnchor: require('~/components/craft/CraftElementAnchor').default,
    ElementButtonGroup: require('~/components/craft/CraftElementButtonGroup').default,
    ElementContactInfo: require('~/components/craft/CraftElementContactInfo').default,
    ElementHeadline: require('~/components/craft/CraftElementHeadline').default,
    ElementIconList: require('~/components/craft/CraftElementIconList').default,
    ElementText: require('~/components/craft/CraftElementText').default,
  };

  return (
    <BlockService image={blockServiceImage?.[0] ?? undefined}>
      <CraftComponents
        components={children}
        availableComponents={availableComponents}
        prefix="Craft_PageBuilder"
      />
    </BlockService>
  );
}

export const query = graphql`
  fragment CraftPageBuilderBlockServiceQuery on Craft_PageBuilderBlockService {
    id
    blockServiceImage {
      ...CraftImageFluid
    }
    # Content
    children {
      __typename
      # Content: Elements
      ...CraftPageBuilderElementAnchorQuery
      ...CraftPageBuilderElementButtonGroupQuery
      ...CraftPageBuilderElementContactInfoQuery
      ...CraftPageBuilderElementHeadlineQuery
      ...CraftPageBuilderElementIconListQuery
      ...CraftPageBuilderElementTextQuery
    }
  }
`;

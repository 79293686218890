import React from 'react';
import PropTypes from 'prop-types';

import ThemeProvider from '~/components/common/ThemeProvider';

import { TextWrapperStyled } from './TextWrapper.styles';

/**
 * Component: TextWrapper
 *
 * Wraps multiple paragraphs into a wrapping div.
 *
 * @param className
 * @param children
 *
 * @returns {null|object}
 */
export default function TextWrapper({
  className,
  themeName,
  removeMargins,
  children,
}) {
  return (
    <ThemeProvider name={themeName}>
      <TextWrapperStyled
        hasTheme={typeof themeName !== 'undefined'}
        removeMargins={removeMargins}
        className={className}
        fontSize={2}
      >
        {children}
      </TextWrapperStyled>
    </ThemeProvider>
  );
}

TextWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

TextWrapper.defaultProps = {};

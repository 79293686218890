import { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { PageContext } from '~/components/common/Layout';
import Parser from '~/components/common/Parser';

type MaterialExample = {
  iconName?: string;
  title?: string;
  titleShort?: string;
};

type Material = {
  id?: string;
  identifier: string;
  title?: string;
  image?: any;
  infoText?: React.ReactNode;
  examples: MaterialExample[];
};

type Materials = Material[];

type MaterialMap = {
  [key: string]: Material;
};

export function useCraftMaterials() {
  const { site = '' } = useContext(PageContext);

  // query static data from craft's sorts (as in, materials)
  const staticData: any = useStaticQuery(graphql`
    {
      craft {
        entries(site: "*", type: SortsSort) {
          site {
            handle
          }
          id
          title
          ... on Craft_SortsSort {
            id
            sortTeaserTitle
            sortTeaserText
            sortTeaserImage {
              ...CraftImageFluid
            }
            sortInfoText {
              content
            }
            sortIdentifier
            iconlist {
              __typename
              ... on Craft_IconlistIcon {
                iconName
                iconTitle
                iconTitleShort
              }
            }
          }
        }
      }
    }
  `);

  // filter craft data from static query and prepare materials to pass on
  const filteredStaticData: any = staticData.craft.entries.filter(
    (entry: any) => entry.site.handle === site,
  );
  const materials = filteredStaticData
    ? filteredStaticData
        .map((sort: any) => {
          if (!sort) return undefined;

          return {
            id: sort.id || undefined,
            identifier: sort.sortIdentifier,
            title: sort.sortTeaserTitle || undefined,
            image: sort.sortTeaserImage[0] || undefined,
            infoText: sort.sortInfoText ? (
              <Parser html={sort.sortInfoText.content} />
            ) : (
              undefined
            ),
            examples: (sort.iconlist || [])
              .map((icon: any) => {
                if (!icon) return;
                return {
                  iconName: icon.iconName || undefined,
                  title: icon.iconTitle || undefined,
                  titleShort: icon.iconTitleShort || undefined,
                };
              })
              .filter(Boolean),
          };
        })
        .filter(Boolean)
    : [];

  const materialMap = materials.reduce(
    (map: any, material: any) => ({ ...map, [material.identifier]: material }),
    {},
  );

  return { materials, materialMap } as {
    materials: Materials;
    materialMap: MaterialMap;
  };
}

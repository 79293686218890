import React, { useEffect } from 'react';
import { useSnackbar, OptionsObject } from 'notistack';
import { useLocalStorage } from '~/utils/hooks/useLocalStorage';

const defaultProps = {
  autoHideDuration: 6e4,
};

type SnackbarProps = {
  children: React.ReactNode;
  autoHideDuration?: number | null;
  variant?: OptionsObject['variant'];
  id: string | number;
  showAgainAfter?: number;
} & typeof defaultProps;

export default function Snackbar({
  children,
  autoHideDuration,
  variant,
  id,
  showAgainAfter,
}: SnackbarProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [snackbarShown, setSnackbarShown] = useLocalStorage(
    `snackbar-${id}-shown`,
    false,
    showAgainAfter,
  );

  useEffect(() => {
    if (!snackbarShown) {
      // console.log('enqueueSnackbar', id);
      enqueueSnackbar(children, {
        autoHideDuration,
        variant,
        preventDuplicate: true,
        key: id,
      });
      setSnackbarShown(true);
    }
  }, [
    enqueueSnackbar,
    snackbarShown,
    setSnackbarShown,
    children,
    id,
    autoHideDuration,
    variant,
  ]);

  return null;
}

Snackbar.defaultProps = defaultProps;

import React from 'react';
import { connect } from 'formik';

type FormikConditionalProps = {
  children: React.ReactNode;
  _if: (values: any) => boolean;
};

export default connect<FormikConditionalProps, any>(function FormikConditional({
  children,
  _if,
  formik,
}) {
  // `_if` has the potential to throw.
  // if it does, we probably don't want to render
  // this is similar to idx' behaviour
  let shouldRender;
  try {
    shouldRender = _if(formik.values);
  } catch (o_O) {
    shouldRender = false;
  }

  return <>{shouldRender ? children : null}</>;
});

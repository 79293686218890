import React from 'react';
import { Box } from 'rebass';
import Headline from '~/components/common/Headline';
import Text from '~/components/common/Text';
import {
  DownloadsStyled,
  Download,
  Wrapper,
  Title,
  Link,
  Icon,
} from './Downloads.styles';

export type DownloadItem = {
  id?: number;
  title?: string;
  altText?: React.ReactNode;
  url?: string;
  size?: number;
};

type DownloadsProps = {
  items?: DownloadItem[];
};

export default function Downloads({ items }: DownloadsProps) {
  return items ? (
    <DownloadsStyled mx={{ _: -1, md: -1, lg: -1 }} mt={{ _: 1, md: 2 }}>
      {items.map(({ id, title, altText, url, size }: any) => (
        <Download
          key={id}
          px={{ _: 1, md: 1, lg: 1 }}
          mt={{ _: 0, md: 0 }}
          mb={{ _: 1, md: 2 }}
          width={{ _: 1, md: 1 / 3 }}
        >
          <Link to={url}>
            <Icon name="Download" />
            <Title>{altText}</Title>
          </Link>
        </Download>
      ))}
    </DownloadsStyled>
  ) : null;
}

import React from 'react';
import { Box } from 'rebass';

type InfoBoxProps = {
  children: React.ReactNode;
};

export default function InfoBox({ children }: InfoBoxProps) {
  return (
    <Box
      color="tertiaryText"
      bg="tertiary"
      my={1}
      px={2}
      py={'25px'}
      css={{ borderRadius: 5 }}
    >
      {children}
    </Box>
  );
}

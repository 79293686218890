import React from 'react';
import ReactPlayer from 'react-player';

import { VideoBox, VideoWrapper } from './Video.styles';

export default function Video({ url }: any) {
  return (
    <VideoBox my={{ _: 0, lg: 40 }}>
      <VideoWrapper>
        <ReactPlayer width="100%" height="100%" url={url} />
      </VideoWrapper>
    </VideoBox>
  );
}

Video.defaultProps = {
  videoUrl: null,
};

type Video = {
  videoUrl: string;
};

import React from 'react';
import { Box, Flex } from 'rebass';
import { AccordionItem } from '../Accordion';
import Icon from '~/components/common/Icon';
import {
  AccordionItemRenderProps,
  AccordionItemWrapperRenderProps,
} from '../Accordion/Accordion';
import Headline from '../Headline';

export default function AccordionBasicItem({ name, title, children }: any) {
  return (
    <AccordionItem
      name={name}
      renderItem={({
        control,
        content,
        isOpened,
      }: AccordionItemWrapperRenderProps) => (
        <Box
          sx={{
            borderStyle: 'solid',
            borderWidth: 2,
            borderColor: 'faded',
            borderBottomColor: isOpened ? 'faded' : 'transparent',
            borderRadius: 5,
            overflow: 'hidden',
            transition: 'border-color 300ms 50ms ease-in-out',
            my: 2,
            '&:first-of-type': {
              mt: 0,
            },
          }}
        >
          {control}
          {content}
        </Box>
      )}
      renderControl={({
        name,
        handleControlClick,
        isOpened,
      }: AccordionItemRenderProps) => (
        <Flex
          as="button"
          px={4}
          py={2}
          width="100%"
          bg="faded"
          justifyContent="space-between"
          alignItems="center"
          onClick={() => {
            if (!isOpened) {
              requestAnimationFrame(() => {
                history.replaceState({}, '', `#${name}`);
              });
            }
            handleControlClick();
          }}
        >
          <Headline
            level="h5"
            color={isOpened ? 'primary' : 'dim'}
            sx={{
              margin: '0px !important',
              transition: 'color 350ms cubic-bezier(0.3, 0.1, 0.3, 1)',
            }}
          >
            <a id={name}>{title}</a>
          </Headline>
          <Icon
            name="ChevronRight"
            sx={{
              svg: {
                transform: isOpened ? 'rotate(270deg)' : 'rotate(90deg)',
                transition: 'transform 600ms cubic-bezier(0.46, 0, 0.08, 1)',
                height: '1.5em',
                '@media all and (-ms-high-contrast:none)': {
                  width: '1.5em',
                },
              },
            }}
          />
        </Flex>
      )}
    >
      {children}
    </AccordionItem>
  );
}

import React from 'react';
import { graphql } from 'gatsby';

import Text from '~/components/common/Text';
import Parser from '~/components/common/Parser';

import {
  TeaserTileStyled,
  Link,
  ContentWrapper,
  ContentMain,
  Headline,
  Button,
  Image,
} from './TeaserTile.styles';

export default function TeaserTile({ image, title, text, link }: any) {
  const linkProps = {
    to: link.url,
    target: link.target,
  };

  return (
    <TeaserTileStyled>
      {image && (
        <Image
          fluid={image}
          args={{
            maxWidth: 600,
            width: image.width,
          }}
        />
      )}
      <Link {...linkProps}>
        <ContentWrapper p={2}>
          <ContentMain>
            {title && (
              <Headline level="h3">
                <Parser html={title} />
              </Headline>
            )}
            {text && (
              <Text mb={2} fontSize={1}>
                {text}
              </Text>
            )}
          </ContentMain>
          <Button variant="blank">{link.text}</Button>
        </ContentWrapper>
      </Link>
    </TeaserTileStyled>
  );
}

TeaserTile.defaultProps = {};

import React from 'react';
import { Box } from 'rebass';
import { useCraftGlobals } from '~/utils/hooks/useCraftGlobals';
import { sizes } from '~/themes/baseTheme';
import Segment from '~/components/common/Segment';
import { Overlay, PageHeaderInner, PageHeaderTitle } from './PageHeader.styles';

type PageHeaderProps = {
  backgroundImage?: any;
  title?: string;
  description?: string;
  bigSpaceBottom?: boolean;
  overlay?: boolean;
  reserveBannerSpace?: boolean;
};

export default function PageHeader({
  backgroundImage,
  title,
  description,
  bigSpaceBottom,
  overlay,
  reserveBannerSpace,
}: PageHeaderProps) {
  const { globalPages } = useCraftGlobals();
  const fallbackBackgroundImage =
    (globalPages &&
      globalPages.globalPagesHeaderImage &&
      globalPages.globalPagesHeaderImage[0]) ||
    undefined;

  return (
    <Segment
      themeName="secondary"
      backgroundImage={
        backgroundImage ||
        (fallbackBackgroundImage
          ? fallbackBackgroundImage || undefined
          : undefined)
      }
      backgroundHasOverlay={overlay ? true : false}
    >
      <PageHeaderInner
        reserveBannerSpace={reserveBannerSpace}
        pt={{
          _: sizes.header.height.default + 40,
          sm: sizes.header.height.sm + 40,
          md: sizes.header.height.md + 40,
          lg: sizes.header.height.lg + 60,
          xl: sizes.header.height.lg + 105,
        }}
        pb={{ _: bigSpaceBottom ? 8 : 2, lg: bigSpaceBottom ? 8 : 5 }}
      >
        {title && <PageHeaderTitle as="h1">{title}</PageHeaderTitle>}
        {description && <Box maxWidth={700}>{description}</Box>}
      </PageHeaderInner>
    </Segment>
  );
}

import React from 'react';
import { graphql } from 'gatsby';
import Gallery from '~/components/common/Gallery';

export default function CraftElementGallery({ data }) {
  const { gallerySubtitle, galleryImageFiles } = data;
  
  return (
    <Gallery 
      images={galleryImageFiles}
      caption={gallerySubtitle || undefined}
    />
  );
}

export const query = graphql`
  # Fragment for usage in PageBuilder
  fragment CraftPageBuilderBlogElementGalleryQuery on Craft_PageBuilderBlogElementGallery {
    id
    # Content
    gallerySubtitle
    galleryImageFiles {
      id
      ...CraftImageFluid
    }
  }
`;

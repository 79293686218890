import React from 'react';
import { Helmet } from 'react-helmet';

type JsonLdSchemaProps = {
  schema: any;
};

export default function JsonLdSchema({ schema }: JsonLdSchemaProps) {
  return (
    <Helmet>
      {/* JsonLD Schema, https://json-ld.org/ */}
      {/* Script Tag could also possibly be rendered in Body, not nescessarily in head */}
      {schema && <script type="application/ld+json">{JSON.stringify(schema)}</script>}
    </Helmet>
  );
}

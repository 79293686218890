import styled from '~/utils/styled';
import { Text, Flex, Box } from 'rebass';
import media from '~/utils/mediaqueries';
import BaseIcon from '~/components/common/Icon';

export const IconBlocksStyled = styled(Flex)({
  flexWrap: 'wrap',
});

export const IconBlock = styled(Box)({});

export const Header = styled(Flex)({
  alignItems: 'center',
});

export const IconWrap = styled(Box)(({ theme }) => ({
  flex: 'none',
  borderRadius: '50%',
  width: '4em',
  height: '4em',
  backgroundColor: theme.colors.tertiaryBright,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Icon = styled(BaseIcon)({});

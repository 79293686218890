import React from 'react';
import { graphql } from 'gatsby';

import Discount from '~/components/common/Discount';

export default function CraftElementButton({ data }: any) {
  const {
    discountInputText,
    discountCode,
    discountCalculatorLink,
    discountLinkText,
  } = data;

  return (
    <Discount
      discountInputText={discountInputText}
      discountCode={discountCode}
      discountCalculatorLink={discountCalculatorLink}
      discountLinkText={discountLinkText}
    />
  );
}

export const query = graphql`
  # Fragment for usage in PageBuilder
  fragment CraftPageBuilderElementDiscountQuery on Craft_PageBuilderElementDiscount {
    id
    # Content
    discountInputText
    discountCode
    discountCalculatorLink {
      fullUri
    }
    discountLinkText
  }
`;

import { Flex, Box } from 'rebass';
import styled from '~/utils/styled';
import media from '~/utils/mediaqueries';
import CraftGatsbyImage from '~/components/craft/CraftGatsbyImage';

import { keyframes } from '@emotion/core';

const flareAnimation = keyframes`
  0%, 100% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.35;
  }
`;

export const BlockServiceStyled = styled(Flex)({});

export const Decoration = styled(Box)(({ theme }) => ({
  width: '40%',
  position: 'relative',
  zIndex: 0,
  alignSelf: 'center',
  display: 'none',
  [media('lg')]: {
    display: 'block',
  },
  '&::before': { display: 'block', content: '""', paddingTop: '100%' },
  '&::after': {
    transform: 'scale(1.55) translateX(-33%)',
    display: 'block',
    content: '""',
    borderRadius: '50%',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    boxShadow: `0 40px 80px ${theme.colors.primary}`,
    opacity: 0.3,
    animation: flareAnimation,
    animationDuration: '9s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
    //backgroundColor: 'green',
  },
}));

export const Image = styled(CraftGatsbyImage)({
  [media('lg')]: {
    transform: 'scale(1.55) translateX(-33%)',
    borderRadius: '50%',
    position: 'absolute !important' as any,
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
});

// opacity: 0.1,
// [media('lg')]: {
//   opacity: 1,
// },
// svg: {
//   position: 'absolute',
//   width: '70vw',
//   height: '70vw',
//   top: '20%',
//   left: 0,
//   transform: 'translate(-40%, -30%)',
//   [media('lg')]: {
//     width: '45vw',
//     height: '45vw',
//     top: '50%',
//     left: 0,
//     transform: 'translate(-40%, -55%)',
//     maxWidth: 700,
//   },
// },

import React from 'react';
import { graphql } from 'gatsby';

import BlockLogos from '~/components/common/BlockLogos';
import { Craft_PageBuilderBlockLogos } from '~/query';

type CraftBlockLogosProps = {
  data: Craft_PageBuilderBlockLogos;
};

export default function CraftBlockLogos({ data }: CraftBlockLogosProps) {
  const { children, logosVariant } = data;

  const logos = data.logos
    ? data.logos
        .map((logo: any) => {
          if (!logo) return undefined;

          return {
            id: logo.id || undefined,
            logoImage: logo.logoImage || undefined,
          };
        })
        .filter(Boolean)
    : [];

  return (
    <BlockLogos logos={logos} variant={logosVariant}>
      {children}
    </BlockLogos>
  );
}

export const query = graphql`
  fragment CraftPageBuilderBlockLogosQuery on Craft_PageBuilderBlockLogos {
    id
    # Content
    children {
      __typename
      # Content: Elements
      ...CraftPageBuilderElementHeadlineQuery
      ...CraftPageBuilderElementTextQuery
    }

    # Logos
    logosVariant
    logos {
      __typename
      ... on Craft_LogosLogo {
        id
        logoImage {
          ...CraftImageFluid
        }
      }
    }
  }
`;

import React, { useContext } from 'react';
import { connect } from 'formik';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import FormField, { FormFieldRow } from '~/components/common/FormField';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/de';
import 'react-day-picker/lib/style.css';
import { PageContext } from '~/components/common/Layout';
import { Input } from '@rebass/forms';
import FormFieldPseudo from '~/components/common/FormFieldPseudo';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
import { Global } from '@emotion/core';
import media from '~/utils/mediaqueries';

export default connect<
  {
    error?: string;
    contractYearsStartDateRequired: { [year: string]: boolean };
  },
  { startYear: string; startDate: string }
>(function CalculatorContractStartInput({
  formik,
  error,
  contractYearsStartDateRequired,
}) {
  const pageContext = useContext(PageContext);
  const t = useCraftTranslations();

  if (!contractYearsStartDateRequired[formik.values.startYear]) return null;

  const startYear = parseInt(formik.values.startYear, 10);
  return (
    <>
      <FormFieldRow>
        <FormFieldPseudo label={t('startDate')} error={error}>
          <Global
            styles={{
              '.DayPickerInput-Overlay': {
                bottom: -90,
                left: 35,
                [media('lg')]: {
                  bottom: 'auto',
                  left: 'auto',
                },
              },
              '.DayPicker-Day': {
                padding: '0.3em 0.5em',
                borderRadius: '10em',
                [media('lg')]: {
                  padding: '0.5em',
                },
              },
              '.DayPicker-Month': {
                margin: '0 0.5em',
                marginTop: '0.5em',
                [media('lg')]: {
                  margin: '0 1em',
                  marginTop: '1em',
                },
              },
              '.DayPicker-wrapper': {
                paddingBottom: '0.5em',
                [media('lg')]: {
                  paddingBottom: '1em',
                },
              },
              '.DayPicker': {
                fontSize: '0.92rem',
                [media('md')]: {
                  fontSize: '0.96rem',
                },
                [media('lg')]: {
                  fontSize: '1rem',
                },
              },
            }}
          />

          <DayPickerInput
            value={
              formik.values.startDate
                ? new Date(formik.values.startDate)
                : undefined
            }
            dayPickerProps={{
              localeUtils: MomentLocaleUtils,
              locale: pageContext.language,
              month: new Date(startYear, 0),
              fromMonth: new Date(startYear, 0),
              toMonth: new Date(startYear, 11),
            }}
            formatDate={MomentLocaleUtils.formatDate}
            parseDate={MomentLocaleUtils.parseDate}
            format="L"
            component={Input}
            inputProps={{ readOnly: true }}
            placeholder={`${MomentLocaleUtils.formatDate(
              new Date(startYear, 0),
              'L',
              pageContext.language,
            )}`.replace(/[0-9]/g, '_')}
            onDayChange={selectedDay => {
              // this is a hack to get submitForm to work
              // hehe, spreading the hack ~RS
              setTimeout(() => {
                formik.setFieldValue(
                  'startDate',
                  selectedDay ? selectedDay.getTime() : '',
                );
                formik.submitForm();
              }, 0);
            }}
          />
        </FormFieldPseudo>
      </FormFieldRow>
    </>
  );
});

import React, { useMemo, useRef, useEffect, useState, Fragment } from 'react';
import { connect } from 'formik';
import { Box } from 'rebass';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';

export type FormError = {
  message: string;
};

export type MaybeFormError = FormError | undefined;

type FormikErrorsProps = {
  errors?: MaybeFormError[];
};

const IDENTS = '__formik-error-identities';

function filterErrors(maybeErrors: MaybeFormError[] | undefined) {
  return (maybeErrors ? maybeErrors.filter(Boolean) : []) as FormError[];
}

/**
 * This component auto-resets errors, when the formik values change.
 */
export default connect<FormikErrorsProps, any>(function FormikErrors({
  errors: _errors,
  formik,
}) {
  const errors = filterErrors(_errors);
  const formikIdentity = JSON.stringify(formik.values);
  const errorsIdentity = JSON.stringify(errors);

  if (!(formik as any)[IDENTS]) {
    (formik as any)[IDENTS] = { formikIdentity, errorsIdentity };
  }

  const [errorState, setErrorState] = useState(errors);

  useEffect(() => {
    if (errorsIdentity === (formik as any)[IDENTS].errorsIdentity) {
      if (formikIdentity !== (formik as any)[IDENTS].formikIdentity) {
        // formik changed, but errors stayed the same => reset errors
        setErrorState([]);
      }
    } else {
      // errors changed => show new errors
      setErrorState(errors);
      (formik as any)[IDENTS].errorsIdentity = JSON.stringify(errors);
    }
    (formik as any)[IDENTS].formikIdentity = formikIdentity;
  }, [setErrorState, formikIdentity, errorsIdentity]);

  return <FormikErrorsRenderer errors={errorState} />;
});

type FormikErrorsRendererProps = {
  errors: FormError[];
};

function FormikErrorsRenderer({ errors }: FormikErrorsRendererProps) {
  const t = useCraftTranslations();

  if (!errors.length) return null;

  return (
    <Box color="errorText" my={1}>
      <strong>
        {errors.length === 1
          ? t('An error occured processing your data:')
          : t('Errors occured processing your data:')}
      </strong>
      {errors.map(({ message }) => (
        <Box key={message} mt="3px">
          <em>{nl2br(t(message))}</em>
        </Box>
      ))}
    </Box>
  );
}

var nlRegex = /(\r\n|\r|\n)/g;

function nl2br(str: string) {
  if (typeof str !== 'string') {
    return str;
  }

  return str.split(nlRegex).map(function(line, i) {
    if (line.match(nlRegex)) {
      return <br key={i} />;
    }
    return line;
  });
}

import React from 'react';
import { Helmet } from 'react-helmet';
import { useCraftGlobals } from '~/utils/hooks/useCraftGlobals';

type SeoProps = {
  title: string;
  description?: string;
  canonical?: string;
};

export default function Seo({ title, description, canonical }: SeoProps) {
  const { globalSeo } = useCraftGlobals();

  // Default config from globals
  const titleTemplate =
    globalSeo && globalSeo.seoTitleTemplate ? globalSeo.seoTitleTemplate : `%s`;

  return (
    <Helmet title={title} titleTemplate={titleTemplate}>
      {/* Basics */}
      {description && <meta name="description" content={description} />}
      {canonical && <link rel="canonical" href={canonical} />}

      {/* Coming soon: language related meta tags */}
      {/* Coming soon: social network meta tags */}
    </Helmet>
  );
}

import React from 'react';
import { Flex, Box } from 'rebass';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
import { PricingBox, Header } from './CalculatorPricingBox.styles';
import { Flipped } from 'react-flip-toolkit';
import Loadspinner from '~/components/common/Loadspinner';

type CalculatorPricingBoxProps = {
  title?: React.ReactNode;
  abovePriceContent?: React.ReactNode;
  price?: number;
  discount?: number;
  priceLoading?: boolean;
  children?: React.ReactNode;
  id?: string;
};

const PriceFormatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
});

const PercentageFormatter = new Intl.NumberFormat('de-DE', {
  style: 'percent',
  minimumFractionDigits: 2,
});

export default function CalculatorPricingBox({
  title,
  abovePriceContent,
  price,
  discount,
  priceLoading,
  children,
  id,
}: CalculatorPricingBoxProps) {
  const t = useCraftTranslations();
  const priceString = price ? PriceFormatter.format(price) : undefined;

  // @todo: make sure values are set to the right ones.
  const priceWithoutDiscountString =
    price && discount ? PriceFormatter.format(price + discount) : undefined;
  const priceOnlyDiscountString = discount
    ? PriceFormatter.format(discount)
    : undefined;

  const showPrice = Boolean(priceString || priceLoading);

  return (
    <Flipped flipId={id}>
      <PricingBox my={2}>
        {showPrice ? (
          <Header>
            <Flipped inverseFlipId={id} scale>
              <div>{title ? title : t('Your calculation')}</div>
            </Flipped>
          </Header>
        ) : null}
        <Box p={{ _: 1, lg: 2 }} bg="tertiaryBright" color="tertiaryBrightText">
          <Flipped inverseFlipId={id} scale>
            <div>
              {abovePriceContent ? (
                <Box mb={{ _: '10px', lg: '25px' }}>{abovePriceContent}</Box>
              ) : null}

              {/* Show additional information about discount price */}
              {showPrice && discount ? (
                <>
                  {priceWithoutDiscountString && (
                    <Flex justifyContent="space-between">
                      <Box>
                        <strong>{t('Original price')}</strong>
                      </Box>
                      <Flex alignItems="center">
                        <Box>
                          <strong>{priceWithoutDiscountString}</strong>{' '}
                          {t('/ year')}
                        </Box>
                      </Flex>
                    </Flex>
                  )}
                  {priceOnlyDiscountString && (
                    <Flex
                      mb={{ _: '5px', lg: '10px' }}
                      mt={{ _: '5px', lg: '10px' }}
                      color="dim"
                      justifyContent="space-between"
                    >
                      <Box>{t('Discount price')}</Box>
                      <Box>
                        <strong>{priceOnlyDiscountString}</strong> {t('/ year')}
                      </Box>
                    </Flex>
                  )}
                </>
              ) : null}

              {showPrice ? (
                <Flex fontSize={4} justifyContent="space-between">
                  <Box>
                    <strong>{t('Price')}</strong>
                  </Box>
                  <Flex alignItems="center">
                    <Loadspinner
                      loading={priceLoading}
                      css={{ marginRight: '10px' }}
                    />
                    {priceString ? (
                      <Box>
                        <strong>{priceString}</strong> {t('/ year')}
                      </Box>
                    ) : null}
                  </Flex>
                </Flex>
              ) : (
                <Box>{t("Choose the materials you'd like to license.")}</Box>
              )}

              {showPrice ? (
                <Flex
                  mt={{ _: '5px', lg: '10px' }}
                  color="dim"
                  justifyContent="space-between"
                >
                  <Box>{t('Net price exclusive of VAT')}</Box>
                </Flex>
              ) : null}

              {children ? (
                <Box mt={{ _: '10px', lg: '25px' }}>{children}</Box>
              ) : null}
            </div>
          </Flipped>
        </Box>
      </PricingBox>
    </Flipped>
  );
}

import React, { useState, useCallback, createContext, useContext } from 'react';
import { Collapse } from 'react-collapse';

type AccordionProps = {
  children: React.ReactNode;
  allowMultipleOpen?: boolean;
  initialOpenedItems?:
    | {
        [label: string]: boolean;
      }
    | (() => {
        [label: string]: boolean;
      });
};

type OpenedItems = {
  [label: string]: boolean;
};

type Name = string;

type HandleControlClick = (name: Name) => void;

const AccordionContext = createContext<{
  openedItems: OpenedItems;
  handleControlClick: HandleControlClick;
}>({
  openedItems: {},
  handleControlClick: () => {},
});

/**
 * Accordion React Component
 * Basic usage:
 * ```
 * <Accordion>
 *   <AccordionItem name="Alligator Mississippiensis">
 *     <p>
 *       <strong>Common Name:</strong> American Alligator
 *     </p>
 *     <p>
 *       <strong>Distribution:</strong> Texas to North Carolina, United
 *       States
 *     </p>
 *     <p>
 *       <strong>Endangered Status:</strong> Currently Not Endangered
 *     </p>
 *   </AccordionItem>
 *   <AccordionItem name="Alligator Sinensis">
 *     <p>
 *       <strong>Common Name:</strong> Chinese Alligator
 *     </p>
 *     <p>
 *       <strong>Distribution:</strong> Eastern China
 *     </p>
 *     <p>
 *       <strong>Endangered Status:</strong> Critically Endangered
 *     </p>
 *   </AccordionItem>
 * </Accordion>
 * ```
 */
export default function Accordion({
  children,
  allowMultipleOpen,
  initialOpenedItems,
}: AccordionProps) {
  const [openedItems, setOpenedItems] = useState<OpenedItems>(
    initialOpenedItems ?? {},
  );

  const handleControlClick = useCallback(
    (name: string) => {
      const isOpened = openedItems[name];

      if (allowMultipleOpen) {
        setOpenedItems({
          ...openedItems,
          [name]: !isOpened,
        });
      } else {
        setOpenedItems({
          [name]: !isOpened,
        });
      }
    },
    [openedItems, allowMultipleOpen, setOpenedItems],
  );

  return (
    <AccordionContext.Provider
      value={{ openedItems, handleControlClick }}
      children={children}
    />
  );
}

export type AccordionItemRenderProps = {
  name: Name;
  isOpened: boolean;
  handleControlClick: () => void;
};

export type AccordionItemWrapperRenderProps = {
  control: JSX.Element;
  content: JSX.Element;
} & AccordionItemRenderProps;

type AccordionItemProps = {
  name: Name;
  children:
    | React.ReactNode
    | ((props: AccordionItemRenderProps) => JSX.Element);
  renderControl?: (props: AccordionItemRenderProps) => JSX.Element;
  renderItem?: (props: AccordionItemWrapperRenderProps) => JSX.Element;
} & typeof AccordionItem.defaultProps;

export function AccordionItem({
  children,
  renderControl,
  renderItem,
  name,
}: AccordionItemProps) {
  const { openedItems, handleControlClick: _handleControlClick } = useContext(
    AccordionContext,
  );
  const isOpened = openedItems[name] ?? false;
  const handleControlClick = () => _handleControlClick(name);

  return renderItem({
    name,
    isOpened,
    handleControlClick,
    control: renderControl({ name, isOpened, handleControlClick }),
    content: (
      <Collapse isOpened={isOpened}>
        {children instanceof Function
          ? children({ name, isOpened, handleControlClick })
          : children}
      </Collapse>
    ),
  });
}

/**
 * Adds basic Accordion implementation - you'll probably want more than this ;)
 */
AccordionItem.defaultProps = {
  renderItem: ({ control, content }: AccordionItemWrapperRenderProps) => (
    <>
      {control}
      {content}
    </>
  ),
  renderControl: ({ name, handleControlClick }: AccordionItemRenderProps) => (
    <button onClick={handleControlClick}>{name}</button>
  ),
};

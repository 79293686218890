import React from 'react';
import { graphql } from 'gatsby';
import { Craft_PageBuilderSegmentFaq, Craft_FaqCategory } from '~/query';
import Parser, { ALLOWED_TAGS_BLOCK } from '~/components/common/Parser';
import Faq from '~/components/common/Faq';

type CraftSegmentFaqProps = {
  data: Craft_PageBuilderSegmentFaq;
};

export default function CraftSegmentFaq({ data }: CraftSegmentFaqProps) {
  const _faqSections = data.faqSections ?? [];

  const sections = _faqSections.map(faqSection => {
    return {
      title: faqSection?.title ?? '',
      slug: faqSection?.slug ?? '',
      faqs: ((faqSection as Craft_FaqCategory)?.faq ?? []).map(faq => {
        return {
          question: faq?.faqQuestion ?? '',
          answer: faq?.faqAnswer?.content ? (
            <Parser
              html={faq.faqAnswer.content as string}
              allowedTags={ALLOWED_TAGS_BLOCK}
            />
          ) : null,
        };
      }),
    };
  });

  return <Faq sections={sections} />;
}

export const query = graphql`
  fragment CraftSegmentFaqQuery on Craft_PageBuilderSegmentFaq {
    id
    faqSections {
      title
      slug
      ... on Craft_FaqCategory {
        faq {
          ... on Craft_FaqFrageAntwort {
            faqQuestion
            faqAnswer {
              content
            }
          }
        }
      }
    }
  }
`;

import React from 'react';
import { graphql } from 'gatsby';

import ContactInfo from '~/components/common/ContactInfo';

import { Craft_PageBuilderElementContactInfo } from '~/query';

type CraftContactInfoProps = {
  data?: Craft_PageBuilderElementContactInfo;
  children?: any;
};

export default function CraftElementContactInfo({
  data,
}: CraftContactInfoProps) {
  if (!data) return null;
  const {
    contactHeadline,
    contactNumber,
    contactNumberUrl,
    contactTime,
    contactEmail,
    contactEmailUrl,
    headlineElement,
    headlineElementSemantic,
  } = data;

  const contactList = data.contactList
    ? data.contactList
        .map((item: any) => {
          if (!item) return undefined;

          return {
            id: item.id || undefined,
            text: item.itemText
              ? {
                  content: item.itemText.content || undefined,
                }
              : undefined,
          };
        })
        .filter(Boolean)
    : [];

  return (
    <ContactInfo
      contactHeadline={contactHeadline}
      contactHeadlineLevel={headlineElement ?? 'h4'}
      contactHeadlineTag={headlineElementSemantic ?? 'inherit'}
      contactList={contactList}
      contactNumber={contactNumber}
      contactNumberUrl={contactNumberUrl}
      contactTime={contactTime}
      contactEmail={contactEmail}
      contactEmailUrl={contactEmailUrl}
    />
  );
}

export const query = graphql`
  # Fragment for usage in PageBuilder
  fragment CraftPageBuilderElementContactInfoQuery on Craft_PageBuilderElementContactInfo {
    id
    # Contact
    contactHeadline {
      content
    }
    headlineElement
    headlineElementSemantic
    contactNumber
    contactNumberUrl
    contactTime
    contactEmail
    contactEmailUrl
    contactList {
      __typename
      ... on Craft_ContactListItem {
        id
        itemText {
          content
        }
      }
    }
  }
`;

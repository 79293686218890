import React, { useMemo, useEffect } from 'react';
import { Input } from '@rebass/forms';
import FormField, { FormFieldRow } from '~/components/common/FormField';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
import FormRadioPayment from '~/components/common/FormRadioPayment';
import FormikConditional from '~/components/common/FormikConditional';
import { connect, FormikContext } from 'formik';
import { useMandate } from '~/utils/payone/manageMandate';
import { useCreditCardIframes } from '~/utils/payone/hostedIFrames';
import { CalculatorWizardStep01CompanyDataValues } from '../CalculatorWizardStep01CompanyData/CalculatorWizardStep01CompanyData';
import { CalculatorWizardStep02TaxDataValues } from '../CalculatorWizardStep02TaxData/CalculatorWizardStep02TaxData';
import InfoBox from '~/components/common/InfoBox';
import { Flex, Box } from 'rebass';
import { useDebounce } from 'use-debounce/lib';
import { Validate } from '../../../common/Wizard/Wizard';
import FormFieldPseudo from '~/components/common/FormFieldPseudo';
import FormikErrors from '~/components/common/FormikErrors';
import { PaymentMethod, countries } from '~/utils/calculator';
import { IconNames } from '~/components/common/Icon/Icon';

type Values = CalculatorWizardStep04PaymentMethodValues &
  CalculatorWizardStep01CompanyDataValues &
  CalculatorWizardStep02TaxDataValues;

function useMandateFromFormik(formik: FormikContext<Values>) {
  const { companyData, paymentMethod } = formik.values;

  const { companyName, address1, zipCode, city, country, email } = companyData;

  const { iban = undefined, bankAccountHolder = undefined } =
    paymentMethod || {};

  const [slowIban] = useDebounce(iban, 666);
  const [slowBankAccountHolder] = useDebounce(bankAccountHolder, 666);

  const mandateData = useMemo(
    () => ({
      lastname: slowBankAccountHolder,
      company: companyName,
      street: address1,
      zip: zipCode,
      city: city,
      country: country,
      email: email,
      language: 'de',
      iban: slowIban,
    }),
    [
      slowBankAccountHolder,
      companyName,
      address1,
      zipCode,
      city,
      country,
      email,
      slowIban,
    ],
  );

  const [mandate, mandateLoading] = useMandate(mandateData);

  useEffect(() => {
    if (mandate?.mandateIdentification) {
      formik.setFieldValue(
        'paymentMethod.mandateIdentification' as any,
        mandate.mandateIdentification,
        true,
      );
    }
    formik.validateForm();
  }, [mandate, slowIban, slowBankAccountHolder]);

  return [mandate, mandateLoading] as [typeof mandate, typeof mandateLoading];
}

const inputOpts = {
  cardcvc2: {
    length: { V: 3, M: 3 },
    size: '4',
    maxlength: '4',
  },
  cardexpiremonth: {
    iframe: { height: '20px' },
    size: '2',
    maxlength: '2',
  },
  cardexpireyear: {
    iframe: { height: '20px' },
    size: '4',
    maxlength: '4',
  },
  cardpan: {},
};

const CalculatorWizardStep04PaymentMethodImpl = connect<{}, Values>(
  function CalculatorWizardStep04PaymentMethodImpl({ formik }) {
    const t = useCraftTranslations();

    const [mandate, mandateLoading] = useMandateFromFormik(formik);

    const { inputRefs, cardtype, creditCardCheck } = useCreditCardIframes({
      language: 'de',
      shouldLoad: formik.values.paymentMethod.type === 'CreditCard',
      inputOpts,
    });

    const country = formik.values?.companyData?.country;
    const paymentMethods = countries[country]?._payment;

    useEffect(() => {
      // if country is set to a country that does not support the
      // current payment method type, set it to the first one available
      const currPaymentMethod = formik.values?.paymentMethod?.type;
      if (currPaymentMethod) {
        if (paymentMethods && !paymentMethods.includes(currPaymentMethod)) {
          formik.setFieldValue('paymentMethod.type', paymentMethods[0]);
        }
      }
    }, [formik, country, paymentMethods]);

    const paymentMethodOptions = [
      {
        label: t('Credit card'),
        value: 'CreditCard' as PaymentMethod,
        iconName: 'PaymentCreditCard' as IconNames,
      },
      {
        label: t('Direct debit'),
        value: 'Debit' as PaymentMethod,
        iconName: 'PaymentDebit' as IconNames,
      },
      {
        label: t('Invoice'),
        value: 'Invoice' as PaymentMethod,
        iconName: 'PaymentInvoice' as IconNames,
      },
      {
        label: t('PayPal'),
        value: 'PayPal' as PaymentMethod,
        iconName: 'PaymentPayPal' as IconNames,
      },
      // {
      //   label: t('Alipay'),
      //   value: 'Alipay' as PaymentMethod,
      //   iconName: 'PaymentAlipay' as IconNames,
      // },
    ].filter(({ value }) => paymentMethods?.includes(value));

    return (
      <>
        <FormFieldRow>
          <FormRadioPayment
            name="paymentMethod.type"
            options={paymentMethodOptions}
            hideOptionalIndicator
          />
        </FormFieldRow>
        <FormikConditional
          _if={({ paymentMethod }) => paymentMethod.type === 'CreditCard'}
        >
          <FormFieldRow>
            <FormFieldPseudo label={t('paymentMethod.cardpan')}>
              <Box css={{ position: 'relative' }}>
                <span
                  ref={inputRefs.cardpan}
                  data-style="width: 100%; box-sizing: border-box; font-family: 'Open Sans', sans-serif; font-size: 15px; padding: 12px 100px 13px 17px; width: 100%; border: 1px solid #d9d9d9; border-radius: 5px; color: #484848;"
                  data-style-focus="width: 100%; box-sizing: border-box; font-family: 'Open Sans', sans-serif; font-size: 15px; padding: 12px 100px 13px 17px; width: 100%; border: 1px solid #808790; border-radius: 5px; color: #484848; box-shadow: inset 0 0 7px rgba(0,0,0,.1); outline: none;"
                  data-type="input"
                />
                {cardtype && cardtype.string ? (
                  <Box
                    sx={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      backgroundColor: 'primary',
                      padding: '12px 17px',
                      color: 'primaryText',
                      textTransform: 'uppercase',
                      borderRadius: '0 5px 5px 0',
                      height: 'calc(100% - 7px)',
                      fontSize: '13px',
                      fontWeight: 700,
                    }}
                  >
                    {cardtype.string}
                  </Box>
                ) : null}
              </Box>
            </FormFieldPseudo>
          </FormFieldRow>
          <FormFieldRow>
            <FormFieldPseudo
              label={t('paymentMethod.cardexpire') + ' ' + t('(mm/yyyy)')}
            >
              <Input
                as="div"
                css={{
                  paddingBottom: 3,
                  iframe: { transform: 'translateY(2px)' },
                }}
              >
                <Flex>
                  <Box>
                    <span
                      ref={inputRefs.cardexpiremonth}
                      data-style="width: 100%; box-sizing: border-box; font-family: 'Open Sans', sans-serif; font-size: 15px; border: none; padding: 0; margin: 0; text-align: center;"
                      data-style-focus="width: 100%; box-sizing: border-box; font-family: 'Open Sans', sans-serif; font-size: 15px; border: none; padding: 0; margin: 0; outline: none; text-align: center;"
                      data-type="input"
                    ></span>
                  </Box>
                  <Box
                    css={{ position: 'relative', zIndex: 1, paddingBottom: 7 }}
                  >
                    {'/'}
                  </Box>
                  <Box>
                    <span
                      ref={inputRefs.cardexpireyear}
                      data-style="width: 100%; box-sizing: border-box; font-family: 'Open Sans', sans-serif; font-size: 15px; border: none; padding: 0; margin: 0; text-align: center;"
                      data-style-focus="width: 100%; box-sizing: border-box; font-family: 'Open Sans', sans-serif; font-size: 15px; border: none; padding: 0; margin: 0; outline: none; text-align: center;"
                      data-type="input"
                    ></span>
                  </Box>
                </Flex>
              </Input>
            </FormFieldPseudo>
            <FormFieldPseudo label={t('paymentMethod.cardcvc2')}>
              <span
                className="inputIframe"
                ref={inputRefs.cardcvc2}
                data-style="width: 100%; box-sizing: border-box; font-family: 'Open Sans', sans-serif; font-size: 15px; padding: 12px 17px 13px 17px; width: 100%; border: 1px solid #d9d9d9; border-radius: 5px; color: #484848;"
                data-style-focus="width: 100%; box-sizing: border-box; font-family: 'Open Sans', sans-serif; font-size: 15px; padding: 12px 17px 13px 17px; width: 100%; border: 1px solid #808790; border-radius: 5px; color: #484848; box-shadow: inset 0 0 7px rgba(0,0,0,.1); outline: none;"
                data-type="input"
                data-size="4"
              />
            </FormFieldPseudo>
          </FormFieldRow>
          <FormFieldRow>
            <FormField
              name="paymentMethod.pseudocardpan"
              type="hidden"
              component={Input}
              required
              validate={async () => {
                const data = await creditCardCheck();
                if (data && data.pseudocardpan) {
                  formik.setFieldValue(
                    'paymentMethod.pseudocardpan' as any,
                    data.pseudocardpan,
                    false,
                  );
                  formik.setFieldValue(
                    'paymentMethod.cardtype' as any,
                    data.cardtype,
                    false,
                  );
                  formik.setFieldValue(
                    'paymentMethod.cardexpiredate' as any,
                    data.cardexpiredate,
                    false,
                  );
                } else {
                  formik.setFieldValue(
                    'paymentMethod.pseudocardpan' as any,
                    '',
                    false,
                  );
                  formik.setFieldValue(
                    'paymentMethod.cardtype' as any,
                    '',
                    false,
                  );
                  formik.setFieldValue(
                    'paymentMethod.cardexpiredate' as any,
                    '',
                    false,
                  );
                  return data && data.errormessage
                    ? data.errormessage
                    : `${t('Please provide your credit card information.')}`;
                }
              }}
            />
          </FormFieldRow>
          <FormFieldRow>
            <FormField
              name="paymentMethod.cardtype"
              type="hidden"
              component={Input}
            />
            <FormField
              name="paymentMethod.cardexpiredate"
              type="hidden"
              component={Input}
            />
          </FormFieldRow>
        </FormikConditional>

        <FormikConditional
          _if={({ paymentMethod }) => paymentMethod.type === 'Debit'}
        >
          {/* <InfoBox>{t('paymentMethod.debit.hint')}</InfoBox> */}
          <FormFieldRow>
            <FormField
              name="paymentMethod.bankAccountHolder"
              type="text"
              component={Input}
              required
            />
          </FormFieldRow>

          <FormFieldRow>
            <FormField
              name="paymentMethod.iban"
              type="text"
              component={Input}
              required
              validate={
                // Possible errors:
                // Error 888 Invalid IBAN
                // Error 1088 Parameter {IBAN} faulty or missing
                function validateIban(value: any) {
                  if (!value) {
                    return `${t('paymentMethod.iban')} ${t('is required')}`;
                  } else if (
                    mandate &&
                    mandate.errorCode &&
                    mandate.customerMessage
                  ) {
                    return mandate.customerMessage;
                  }
                }
              }
              onChange={(e: any, form: any) => {
                form.setFieldValue(
                  'paymentMethod.iban',
                  e.target.value.replace(/\s/g, '').toUpperCase(),
                );
              }}
            />
            <FormField
              name="paymentMethod.mandateIdentification"
              type="hidden"
              component={Input}
            />
          </FormFieldRow>

          {mandate && mandate.mandateText ? (
            <>
              <Box my={1}>{t('By proceeding I affirm the mandate below.')}</Box>
              <InfoBox>
                <div
                  dangerouslySetInnerHTML={{ __html: mandate.mandateText }}
                />
              </InfoBox>
            </>
          ) : mandateLoading ? (
            <Box my={1}>{t('Retrieving mandate…')}</Box>
          ) : (
            <Box my={1}>{t('Please enter your bank data.')}</Box>
          )}
        </FormikConditional>

        {/* <FormikConditional
          _if={({ paymentMethod }) => paymentMethod.type === 'Invoice'}
        >
          <InfoBox>{t('paymentMethod.invoice.hint')}</InfoBox>
        </FormikConditional> */}
      </>
    );
  },
);

export default function CalculatorWizardStep04PaymentMethod() {
  return <CalculatorWizardStep04PaymentMethodImpl />;
}

/**
 * The initial values for this component are aggregated
 * into the main wizard component
 */
export type CalculatorWizardStep04PaymentMethodValues = {
  paymentMethod: {
    type?: PaymentMethod;
    iban: string;
    bankAccountHolder: string;
    pseudocardpan: string;
    cardtype: string;
    cardexpiredate: string;
    mandateIdentification?: string;
  };
};

CalculatorWizardStep04PaymentMethod.initialValues = {
  paymentMethod: {
    type: 'Debit',
    iban: '',
    bankAccountHolder: '',
    pseudocardpan: '',
    cardtype: '',
    cardexpiredate: '',
    mandateIdentification: '',
  },
} as CalculatorWizardStep04PaymentMethodValues;

CalculatorWizardStep04PaymentMethod.title = 'Step 4';
CalculatorWizardStep04PaymentMethod.description = 'Payment';

CalculatorWizardStep04PaymentMethod.validate = function(
  values: CalculatorWizardStep04PaymentMethodValues,
) {
  if (
    values.paymentMethod.type === 'Debit' &&
    values.paymentMethod.mandateIdentification === ''
  ) {
    return { mandate_identification: 'Missing mandate' };
  }
} as Validate;

import React from 'react';
import { Flex, Box } from 'rebass';
import Parser from '~/components/common/Parser';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
import Headline from '../Headline';
import {
  ContactInfoStyled,
  ContactList,
  ContactListItem,
  Link,
  Time,
} from './ContactInfo.styles';

type ContactInfoProps = {
  contactList?: any;
  contactNumber?: any;
  contactNumberUrl?: any;
  contactTime?: any;
  contactEmail?: any;
  contactEmailUrl?: any;
  contactHeadline?: any;
  contactHeadlineLevel?: any;
  contactHeadlineTag?: any;
};

export default function ContactInfo({
  contactList,
  contactNumber,
  contactNumberUrl,
  contactTime,
  contactEmail,
  contactEmailUrl,
  contactHeadline,
  contactHeadlineLevel,
  contactHeadlineTag,
}: ContactInfoProps) {
  const t = useCraftTranslations();

  return (
    <ContactInfoStyled>
      {contactList && (
        <ContactList>
          {contactList.map((item: any) => (
            <ContactListItem key={item.id}>
              <Parser html={item.text.content} />
            </ContactListItem>
          ))}
        </ContactList>
      )}

      {(contactNumber || contactEmail) && (
        <Box mt={2}>
          {contactHeadline && contactHeadline.content ? (
            <Box>
              <Headline level={contactHeadlineLevel} tag={contactHeadlineTag}>
                <Parser html={contactHeadline.content} />
              </Headline>
            </Box>
          ) : null}

          {contactNumber && (
            <Flex alignItems="center" width="100%" mt={1} flexWrap="wrap">
              <Box mr={2} minWidth="12%" color="dim" as="strong">
                {t('Phone')}:
              </Box>
              <Box mr={1}>
                <Link href={contactNumberUrl || `tel:${contactNumber}`}>
                  {contactNumber}
                </Link>
              </Box>
              {contactTime && <Time>{contactTime}</Time>}
            </Flex>
          )}

          {contactEmail && (
            <Flex alignItems="center" mt={1} flexWrap="wrap">
              <Box mr={2} minWidth="12%" color="dim" as="strong">
                {t('Mail')}:
              </Box>
              <Box mr={1}>
                <Link href={contactEmailUrl || `mailto:${contactEmail}`}>
                  {contactEmail}
                </Link>
              </Box>
            </Flex>
          )}
        </Box>
      )}
    </ContactInfoStyled>
  );
}

import React, { useState } from 'react';
import CalculatorMaterialInput from '~/components/calculator/CalculatorMaterialInput';
import {
  CalculatorMaterialStyled,
  ImageBox,
  ContentBox,
} from './CalculatorMaterial.styles';
import { connect } from 'formik';
import { MaterialValues } from '~/components/common/Calculator/Calculator';

type CalculatorMaterialProps = {
  material: any;
};

export default connect<CalculatorMaterialProps, MaterialValues>(
  function CalculatorMaterial({ material /*isEmptyAmounts, formik*/ }) {
    const [showInfo, setShowInfo] = useState(false);
    const toggleShowInfo = () => setShowInfo(prevState => !prevState);

    /**
     * ich sehe gerade, dass die Fraktionen die nicht ausgewählt wurden
     * verschwimmen. Ist das nur eine Zwischenlösung oder der finale Stand?
     * Weil das ist nicht ganz optimal wenn ich mehr als zwei Fraktionen
     * auswählen möchte. Falls das der finale Stand ist dann stellt das besser
     * aus. Alternativ könnt ihr die ausgewählten Fraktionen hervorheben oder
     * mit einem leicht grünen Rand versehen.
     */
    // const dim =
    //   !isEmptyAmounts && formik.values.materials[material.identifier] <= 0;
    const dim = false;

    return (
      <CalculatorMaterialStyled dim={dim}>
        {material.image && (
          <ImageBox
            fluid={material.image}
            args={{
              maxWidth: 400,
              width: material.image.width,
            }}
          />
        )}
        <ContentBox showInfo={showInfo}>
          <CalculatorMaterialInput
            title={material.title}
            identifier={material.identifier}
            infoText={material.infoText}
            examples={material.examples}
            showInfo={showInfo}
            toggleShowInfo={toggleShowInfo}
          />
        </ContentBox>
      </CalculatorMaterialStyled>
    );
  },
);

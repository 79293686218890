import styled from '~/utils/styled';
import { Box, Flex } from 'rebass';
import media from '~/utils/mediaqueries';

export const FormFieldRow = styled(Flex)({
  flexWrap: 'wrap',
  [media('md')]: {
    flexWrap: 'nowrap',
  },
});

FormFieldRow.defaultProps = {
  mx: -1,
};

export const LabelGroup = styled(Box)({
  marginBottom: '10px',
});

export const LabelText = styled(Box)({
  textTransform: 'uppercase',
  fontWeight: 700,
});

LabelText.defaultProps = {
  color: 'text',
  fontSize: 0,
};

export const OptionalText = styled(Box)({
  textTransform: 'uppercase',
  fontWeight: 700,
});

OptionalText.defaultProps = {
  color: 'moreDim',
  fontSize: 0,
  pl: 1,
};

export const Description = styled(Box)({
  paddingTop: '5px',
});

Description.defaultProps = {
  color: 'dim',
  fontSize: 0,
};

export const ErrorText = styled(Box)({
  paddingTop: '10px',
  fontWeight: 600,
  fontStyle: 'italic',
});

ErrorText.defaultProps = {
  color: 'errorText',
  fontSize: 0,
};

import React from 'react';
import PropTypes from 'prop-types';
import { ObjectFitProperty } from 'csstype';
import { Flex } from 'rebass';
import Video from '~/components/common/Video';

import { ContentWrapper, VideoWrapper } from './BlockVideoText.styles';

type BlockVideoTextProps = {
  children: React.ReactNode;
  flip?: boolean;
  sticky?: boolean | any;
  imagePositionY?: string;
  videoUrl?: string | null;
  layout?: string | null;
};

export default function BlockVideoText({
  flip,
  videoUrl,
  imagePositionY,
  layout,
  children,
  sticky,
}: BlockVideoTextProps) {
  return (
    <Flex
      alignItems="center"
      flexWrap="wrap"
      mx={{ _: -1, md: -4, lg: -5, xl: -6 }}
    >
      {videoUrl && (
        <VideoWrapper
          width={{
            _: 1,
            lg: layout === 'layout60' ? 0.6 : layout === 'layout40' ? 0.4 : 0.5,
          }}
          py={{ _: 2, md: 4, lg: 5, xl: 6 }}
          pb={{ _: 0, md: 0, lg: 5, xl: 6 }}
          sticky={sticky}
          alignSelf={
            imagePositionY === 'center'
              ? 'center'
              : imagePositionY === 'bottom'
              ? 'flex-end'
              : 'flex-start'
          }
        >
          <Video url={videoUrl} />
        </VideoWrapper>
      )}
      <ContentWrapper
        width={{
          _: 1,
          lg: layout === 'layout60' ? 0.4 : layout === 'layout40' ? 0.6 : 0.5,
        }}
        p={{ _: 2, md: 4, lg: 5, xl: 6 }}
        mb={{ _: 1, md: 0 }}
        flip={flip}
      >
        {children}
      </ContentWrapper>
    </Flex>
  );
}

BlockVideoText.propTypes = {
  children: PropTypes.node.isRequired,
};

BlockVideoText.defaultProps = {};

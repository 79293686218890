import React, { useState } from 'react';
import { Input } from '@rebass/forms';
import { connect } from 'formik';
import throttle from 'lodash.throttle';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
import FormField, { FormFieldRow } from '~/components/common/FormField';
import Button from '~/components/common/Button';
import { ErrorText } from '~/components/common/FormField/FormField.styles';
import { useVoucherCode } from '~/utils/hooks/useVoucherCode';

export const submitFormThrottled = throttle(
  (form: any) => requestAnimationFrame(() => form.submitForm()),
  2500,
  { leading: false },
);

type CalculatorVoucherInputProps = {
  error?: string;
};

export default connect<CalculatorVoucherInputProps, { voucherCode: string }>(
  function CalculatorVoucherInput({ formik, error }) {
    const t = useCraftTranslations();
    const [buttonClicked, setButtonClicked] = useState(false);
    const { voucherCode } = formik.values;
    const [
      voucherCodeFromLocalStorage,
      setVoucherCodeFromLocalStorage,
    ] = useVoucherCode();

    const showInput = buttonClicked || Boolean(voucherCode);

    return (
      <>
        {!showInput ? (
          <Button
            type="button"
            variant="toggle"
            onClick={() => setButtonClicked(true)}
            iconName="none"
          >
            {t('Add voucher code')}
          </Button>
        ) : null}

        {showInput ? (
          <>
            <FormFieldRow>
              <FormField
                component={Input}
                name="voucherCode"
                onBlur={() => {
                  // this is a hack to get submitForm to work
                  setTimeout(() => formik.submitForm(), 0);
                }}
                onChange={() => {
                  // this is a hack to get submitForm to work
                  if (voucherCodeFromLocalStorage) {
                    setVoucherCodeFromLocalStorage(undefined);
                  }
                  submitFormThrottled(formik);
                }}
                hideOptionalIndicator
              />
            </FormFieldRow>
            {error && <ErrorText>{t(error)}</ErrorText>}
            <Button
              type="button"
              variant="toggle"
              onClick={() => {
                setButtonClicked(false);
                if (voucherCodeFromLocalStorage) {
                  setVoucherCodeFromLocalStorage(undefined);
                }
                formik.setFieldValue('voucherCode', '');
                setTimeout(() => formik.submitForm(), 0);
              }}
              iconName="none"
            >
              {t('Remove voucher code')}
            </Button>
          </>
        ) : null}
      </>
    );
  },
);

import { Flex, Box, Text } from 'rebass';
import styled from '~/utils/styled';
import media from '~/utils/mediaqueries';

export const FactsStyled = styled(Flex)({});

export const Fact = styled(Flex)(() => ({}));

export type FactNumberProps = {
  colorize?: boolean | null | undefined;
};

export const FactNumber = styled(Box)<FactNumberProps>(
  ({ theme, colorize }) => ({
    fontWeight: 700,
    fontSize: 36,
    [media('md')]: {
      fontSize: 56,
    },
    color: colorize ? theme.colors.primary : theme.colors.tertiary,
    textTransform: 'uppercase',
    letterSpacing: '0.02em',
    lineHeight: 1.4,
    fontFamily: theme.fonts.headline,
    marginRight: 17,
  }),
);

export const FactText = styled(Text)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 16,
  color: theme.colors.headline,
  textTransform: 'uppercase',
  letterSpacing: '0.02em',
  lineHeight: 1.4,
  fontFamily: theme.fonts.headline,
  marginRight: 17,
}));

export const FactsDate = styled(Box)(({ theme }) => ({
  marginTop: 18,
  color: theme.colors.pallet.grey300,
  textAlign: 'right',
}));

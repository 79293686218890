import React, { Fragment, useContext, useState } from 'react';
import { Input, Select } from '@rebass/forms';
import FormField, { FormFieldRow } from '~/components/common/FormField';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
import { countries } from '~/utils/calculator';
import { Validate } from '../../../common/Wizard/Wizard';
import { PageContext } from '~/components/common/Layout';
import { useWizard } from '~/components/common/Wizard';
import Checkbox from '~/components/common/Checkbox';

export default function CalculatorWizardStep01CompanyData() {
  const t = useCraftTranslations();

  const wizardContext = useWizard();
  const companyData = wizardContext?.formikProps?.values?.companyData;
  const [isAgent, setIsAgent] = useState(false);
  const pageLanguage = useContext(PageContext).language;
  const language =
    pageLanguage === 'de' || pageLanguage === 'en' ? pageLanguage : 'en';

  const validateEmail = (value: any) => {
    if (!value) {
      return `${t('companyData.email')} ${t('is required')}`;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)) {
      return `${t('companyData.email')} ${t('is invalid')}`;
    }
  };

  const validateAgentId = (value: any) => {
    if (isAgent && !value) {
      return `${t('companyData.agentNumber')} ${t('is required')}`;
    }
  };

  const validateZipCode = (value: any) => {
    if (!value) {
      return `${t('companyData.zipCode')} ${t('is required')}`;
    } else if (companyData?.country === 'DE' && (value.length !== 5 || !/^\d+$/.test(value))) {
      // For DE - validate numericality and length of 5 for zip codes
      return `${t('companyData.zipCode')} ${t('is invalid')}`;
    }
  }

  return (
    <>
      <FormFieldRow>
        <FormField
          description={t(
            'For a sole proprietorship, please repeat first and last name here.',
          )}
          name="companyData.companyName"
          type="text"
          component={Input}
          required
        />
      </FormFieldRow>

      <FormFieldRow>
        <FormField name="companyData.salutation" component={Select} required>
          <option value="">{t('Please choose')}</option>
          <option value="Frau">{t('Mrs.')}</option>
          <option value="Herr">{t('Mr.')}</option>
          <option value="Keine Angabe">{t('Prefer not to say')}</option>
        </FormField>

        <FormField name="companyData.title" component={Select}>
          <option value="">-</option>
          <option value="Dr.">Dr.</option>
          <option value="Prof.">Prof.</option>
          <option value="Prof. Dr.">Prof. Dr.</option>
        </FormField>
      </FormFieldRow>

      <FormFieldRow>
        <FormField
          name="companyData.firstName"
          type="text"
          component={Input}
          required
        />
        <FormField
          name="companyData.lastName"
          type="text"
          component={Input}
          required
        />
      </FormFieldRow>

      <FormFieldRow>
        <FormField
          name="companyData.address1"
          type="text"
          component={Input}
          required
        />
      </FormFieldRow>

      <FormFieldRow>
        <FormField
          name="companyData.zipCode"
          type="text"
          component={Input}
          required
          validate={validateZipCode}
        />
        <FormField
          name="companyData.city"
          type="text"
          component={Input}
          required
        />
      </FormFieldRow>

      <FormFieldRow>
        <FormField
          name="companyData.country"
          type="text"
          component={Select}
          required
        >
          <option value="">{t('Please choose')}</option>
          {Object.keys(countries).map(country => (
            <Fragment key={country}>
              <option value={country}>{countries[country][language]}</option>
              {country === 'DE' ? (
                <option value="">{t('——————')}</option>
              ) : null}
            </Fragment>
          ))}
        </FormField>
      </FormFieldRow>

      <FormFieldRow>
        <FormField
          name="companyData.email"
          type="email"
          component={Input}
          required
          validate={validateEmail}
        />
      </FormFieldRow>

      <FormFieldRow>
        <FormField name="companyData.telephone" type="text" component={Input} />
        <FormField name="companyData.fax" type="text" component={Input} />
      </FormFieldRow>

      <FormFieldRow style={{ marginTop: '1rem', marginLeft: '0.5rem' }}>
        <Checkbox
          onChange={() => setIsAgent(!isAgent)}
          text={t('companyData.isAgent')}
          value={isAgent}
          disabled={false}
          labelFontSize={14}
        />
      </FormFieldRow>

      {isAgent ?
        <FormFieldRow>
          <FormField
            name="companyData.agentNumber"
            type="text"
            component={Input}
            required
            validate={validateAgentId}
          />
        </FormFieldRow>
      : null}
    </>
  );
}

/**
 * The initial values for this component are aggregated
 * into the main wizard component
 */
export type CalculatorWizardStep01CompanyDataValues = {
  companyData: {
    companyName: string;
    agentNumber?: string;
    title?: string;
    salutation: string;
    firstName: string;
    lastName: string;
    address1: string;
    address2?: string;
    city: string;
    country: string;
    zipCode: string;
    email: string;
    telephone?: string;
    fax?: string;
  };
};

CalculatorWizardStep01CompanyData.initialValues = {
  companyData: {
    companyName: '',
    title: '',
    agentNumber: '',
    salutation: '',
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    country: '',
    zipCode: '',
    email: '',
    telephone: '',
    fax: '',
  },
} as CalculatorWizardStep01CompanyDataValues;

CalculatorWizardStep01CompanyData.title = 'Step 1';
CalculatorWizardStep01CompanyData.description = 'Common information';

CalculatorWizardStep01CompanyData.validate = undefined as Validate;

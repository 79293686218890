import React, { useState } from 'react';
import { Box, Flex } from 'rebass';
import CraftGatsbyImage from '~/components/craft/CraftGatsbyImage';
import SlideShow from '~/components/common/SlideShow';
import Button from '~/components/common/Button';

import {
  GalleryStyled,
  GalleryWrapperStyled,
  CaptionStyled,
  SlideShowWrapper,
  CloseButtonWrapper
} from './Gallery.styles';

export default function Gallery({
  images,
  caption,
} : any) {

  const imageCount = images.length;

  const [galleryVisible, setGalleryVisible] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);

  const hideGallery = () => {
    setFadeOut(true);
    setTimeout(() => {
      setGalleryVisible(false);
      setFadeOut(false);
    }, 500);
  }

  return (
    <GalleryStyled>
      <GalleryWrapperStyled>
        <Flex flexWrap='wrap' m={{_: '-4px', md: '-9px'}}>
          {
            images.map((image : any, index : number) => {
              return (
                <Box
                  p={{_: '4px', md: '9px'}}
                  width={imageCount > 1 ? 1/2 : 1}
                  key={image?.id}
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setInitialSlide(index)
                    setGalleryVisible(true)
                  }}
                >
                    <CraftGatsbyImage
                      fluid={image}
                      aspectRatio={16/9}
                    />
                </Box>
              )
            })
          }
          </Flex>
        <Box px={{ _: '30px', md: 4, lg: 5, xl: 6 }} >
          {caption && <CaptionStyled>{caption}</CaptionStyled>}
        </Box>
      </GalleryWrapperStyled>

      {galleryVisible && (
        <SlideShowWrapper fadeOut={fadeOut}>
          <SlideShow
            images={images}
            height="full"
            options={{initialSlide: initialSlide}}
          >
            <CloseButtonWrapper>
              <Button
                iconName="Cross"
                iconPosition=""
                mobileWidth="auto"
                hoverStyle="zoom"
                onClick={()=>{hideGallery()}}
                bg="rgba(255,255,255,0.5)"
              />
            </CloseButtonWrapper>
          </SlideShow>
        </SlideShowWrapper>
      ) }

    </GalleryStyled>
  );
}

Gallery.defaultProps = {
  images: null,
  caption: null,
};

type Gallery = {
  caption: string;
  images: any;
};



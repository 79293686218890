import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ObjectFitProperty } from 'csstype';
import { Flex } from 'rebass';

import {
  ImageWrapper,
  ImageAspectRatio,
  Image,
  ContentWrapper,
} from './BlockImageText.styles';
import { Craft_ImagesVolume } from '~/query';
import PageBuilderContext from '../PageBuilderContext';

type BlockImageTextProps = {
  children: React.ReactNode;
  flip?: boolean;
  stretch?: boolean;
  sticky?: boolean;
  imageRemovePadding?: boolean;
  imageFit?: ObjectFitProperty;
  imagePositionY?: string;
  image?: Craft_ImagesVolume | null;
};

export default function BlockImageText({
  flip,
  stretch,
  sticky,
  image,
  imageFit,
  imageRemovePadding,
  imagePositionY,
  children,
}: BlockImageTextProps) {
  const pageBuilder = useContext(PageBuilderContext);

  const padding = pageBuilder.lessSpacing
    ? { _: 2, md: 4 }
    : { _: 2, md: 4, lg: 5, xl: 6 };

  const margin = pageBuilder.lessSpacing
    ? { _: -1, md: -4 }
    : { _: -1, md: -4, lg: -5, xl: -6 };

  // Putting together some image wrapper attributes depending on props
  let imageWrapperProps = {};

  if (stretch) {
    imageWrapperProps = {
      p: imageRemovePadding ? 0 : padding,
      height: 'auto',
    };
  }

  return (
    <Flex alignItems="center" flexWrap="wrap" mx={margin as any}>
      {image && (
        <ImageWrapper
          width={{ _: 1, lg: 0.5 }}
          sticky={sticky}
          {...imageWrapperProps}
        >
          <ImageAspectRatio>
            <Image
              fluid={image}
              args={{
                maxWidth: 1000,
                width: image.width,
              }}
              alt={image.altText || undefined}
              objectFit={imageFit || 'cover'}
              objectPosition={
                imagePositionY ? `center ${imagePositionY}` : 'center center'
              }
            />
          </ImageAspectRatio>
        </ImageWrapper>
      )}
      <ContentWrapper
        width={{ _: 1, lg: 0.5 }}
        p={padding as any}
        mb={{ _: 1, md: 0 }}
        flip={flip}
        stretch={stretch}
      >
        {children}
      </ContentWrapper>
    </Flex>
  );
}

BlockImageText.propTypes = {
  children: PropTypes.node.isRequired,
};

BlockImageText.defaultProps = {};

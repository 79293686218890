import React, { useContext } from 'react';
import { Box } from 'rebass';
import { IsBlogContext } from '~/components/craft/CraftPageBuilderBlog/IsBlogContext';

type PaddingSize = 'none' | 'small' | 'medium' | 'large' | null;

type BlockContentProps = {
  children: React.ReactNode;
  py?: PaddingSize;
  pb?: 'asPy' | PaddingSize;
  backgroundColor?: string | undefined | null;
};

function paddingSizeToTopBottom(
  paddingSize?: PaddingSize,
  isBlogContext?: boolean,
) {
  let pt, pb;

  switch (paddingSize) {
    case 'none':
      pt = pb = 0;
      break;
    case 'small':
      pt = isBlogContext ? 0 : { _: 2, md: 2, lg: 2 };
      pb = isBlogContext ? 0 : { _: 1, md: 1, lg: 1 };
      break;
    case 'medium':
      pt = pb = isBlogContext ? 0 : { _: 2, md: 2, lg: 3 };
      break;
    case 'large':
    default:
      pt = pb = isBlogContext ? 0 : { _: 4, md: 4, lg: 6 };
      break;
  }

  return [pt, pb] as const;
}

export default function BlockContent({
  children,
  py,
  pb,
  backgroundColor,
}: BlockContentProps) {
  const isBlogContext = useContext(IsBlogContext);

  let [_pt, _pb] = paddingSizeToTopBottom(py, isBlogContext);

  if (pb !== 'asPy' && typeof pb !== 'undefined') {
    [, _pb] = paddingSizeToTopBottom(pb, isBlogContext);
  }

  // console.log({ isBlogContext, _pt, _pb, py, pb });

  return (
    <Box pt={_pt} pb={_pb} px={isBlogContext ? 0 : undefined} className="px0">
      {backgroundColor ? (
        <Box p={{ _: 2, md: 3, lg: 4 }} bg={backgroundColor}>
          {children}
        </Box>
      ) : (
        children
      )}
    </Box>
  );
}

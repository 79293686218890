import React from 'react';
import { keyframes } from '@emotion/core';

const colors = ['#F6A86E', '#F08041', '#FDEFD9'];

const loadAnimation = keyframes`
  0%, 100% {
    fill: ${colors[0]};
  }
  33.332% {
    fill: ${colors[1]};
  }
  66.667% {
    fill: ${colors[2]};
  }
`;

export default function Loadspinner() {
  return (
    <>
      <polygon
        css={{
          animation: loadAnimation,
          animationIterationCount: 'infinite',
          animationDuration: '600ms',
          animationDelay: '0ms',
        }}
        fill={colors[0]}
        points="5.6,8.3 10.7,5.4 10.9,3 8.6,1.9 1.5,6 1.5,6 1.5,6 1.5,6.1 1.5,10.7 1.5,10.7 1.5,18 3.6,19.2 3.4,16.7 5.6,15.7 5.6,8.3"
      />
      <polygon
        fill="#fff"
        points="12.2,2.3 9.9,1.2 8.6,1.9 10.9,3 10.7,5.4 11.9,4.7"
      />
      <path
        css={{
          animation: loadAnimation,
          animationIterationCount: 'infinite',
          animationDuration: '600ms',
          animationDelay: '200ms',
        }}
        fill={colors[1]}
        d="M12,19.3l-5.1-2.9l-2.2,1l0.1,2.5L12,24v0l10.5-6l0-2.4c0,0-2,1.5-2.1,1.5l-2-1.5L12,19.3z"
      />
      <path
        fill="#fff"
        d="M3.4,16.7l0.3,2.5l1.1,0.7l-0.1-2.5l2.2-1l-1.2-0.7C4.9,16,4.1,16.4,3.4,16.7z"
      />
      <path
        css={{
          animation: loadAnimation,
          animationIterationCount: 'infinite',
          animationDuration: '600ms',
          animationDelay: '400ms',
        }}
        fill={colors[2]}
        d="M22.5,6L12,0L9.9,1.2l2.3,1l-0.3,2.4c2.1,1.2,4.3,2.5,6.4,3.7l0,0l0.1,5.8l2,1.4l2.1-1.5L22.5,6L22.5,6z"
      />
      <path
        fill="#fff"
        d="M20.4,17.1l2.1-1.5l0-1.4l-2.1,1.5l-2-1.4l0,1.4C19.1,16.1,19.7,16.6,20.4,17.1z"
      />
    </>
  );
}

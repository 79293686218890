import React from 'react';
import { graphql } from 'gatsby';
import { Craft_PageBuilderUnion, Maybe } from '~/query';
import CraftComponents from '../CraftComponents';

/**
 * Available components.
 *
 * Defines available first-level components within
 * the page builder. Don't forget to add it
 * to the query as well.
 */
const availableComponents = {
  Segment: require('~/components/craft/CraftSegment').default,
  SegmentCalculator: require('~/components/craft/CraftSegmentCalculator')
    .default,
  SegmentFaq: require('~/components/craft/CraftSegmentFaq').default,
  SegmentSnackbar: require('~/components/craft/CraftSegmentSnackbar').default,
};

type PageBuilderProps = {
  components?: Maybe<Craft_PageBuilderUnion>[] | null;
};

/**
 * Component:
 * Page builder.
 *
 * Renders predefined components dynamically.
 */
export default function PageBuilder({ components }: PageBuilderProps) {
  return (
    <CraftComponents
      components={components}
      availableComponents={availableComponents}
      prefix={/Craft_PageBuilder(Blog)?/}
    />
  );
}

/**
 * Fragment:
 * Page builder.
 *
 * The page builder query includes all queries
 * for the given available components.
 */
export const query = graphql`
  fragment CraftPageBuilderQuery on Craft_PageBuilderUnion {
    __typename

    ...CraftSegmentQuery
    ...CraftSegmentCalculatorQuery
    ...CraftSegmentFaqQuery
    ...CraftSegmentSnackbarQuery
  }

  # this is a almost an exact duplicate of the normal query, but exluding nested types
  fragment CraftPageBuilderQuery_NoNesting on Craft_PageBuilderUnion {
    __typename

    ...CraftSegmentQuery_NoNesting
    ...CraftSegmentCalculatorQuery
    ...CraftSegmentFaqQuery
    ...CraftSegmentSnackbarQuery
  }
`;

import styled from '~/utils/styled';
import { Text } from 'rebass';

import media from '~/utils/mediaqueries';
import { fonts } from '~/themes/baseTheme';

export const TextWrapperStyled = styled(Text)(
  {
    img: {
      maxWidth: '100%',
    }
  },
  ({ hasTheme, theme }) =>
    hasTheme && {
      // Theme
      color: theme.colors.text,
      background: theme.colors.background,
      padding: `${theme.space[1]}px ${theme.space[2]}px`,

      [media('md')]: {
        padding: `${theme.space[2]}px ${theme.space[3]}px`,
      },

      [media('lg')]: {
        padding: `${theme.space[2]}px ${theme.space[4]}px`,
      },
    },
  ({ removeMargins }) =>
    removeMargins && {
      p: {
        marginTop: '0 !important',
        marginBottom: '0 !important',
      },
    },
);

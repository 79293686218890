import React from 'react';
import {
  CalculatorStepSectionStyled,
  Step,
  StepHeader,
  StepContent,
} from './CalculatorStepSection.styles';
import ThemeProvider from '~/components/common/ThemeProvider';
import Headline from '~/components/common/Headline';

type CalculatodStepSectionProps = {
  aboveSectionContent?: React.ReactNode;
  headline?: React.ReactNode;
  subHeadline?: React.ReactNode;
  headerContent?: React.ReactNode;
  children?: React.ReactNode;
};

export default function CalcWizardStepSection({
  aboveSectionContent,
  headline,
  subHeadline,
  headerContent,
  children
}: CalculatodStepSectionProps) {
  return (
    <CalculatorStepSectionStyled>
      {aboveSectionContent}
      <ThemeProvider name="default">
        <Step>
          <StepHeader>
            <Headline>{headline}</Headline>
            <Headline level="h3" css={{ opacity: 0.4 }}>
              {subHeadline}
            </Headline>
            {headerContent}
          </StepHeader>
          <StepContent>{children}</StepContent>
        </Step>
      </ThemeProvider>
    </CalculatorStepSectionStyled>
  );
}

import React from 'react';

import { Flex, Box } from 'rebass';
import CalculatorMaterial from '~/components/calculator/CalculatorMaterial';
import { CalculatorMaterialSectionStyled } from './CalculatorMaterialSection.styles';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
import media from '~/utils/mediaqueries';

type CalculatorMaterialSectionProps = {
  materials: any[];
};

export default function CalculatorMaterialSection({
  materials,
}: CalculatorMaterialSectionProps) {
  const t = useCraftTranslations();

  return (
    <CalculatorMaterialSectionStyled pr={{ _: 0, xxl: 3 }} mb={4}>
      <Flex flexWrap="wrap" flexDirection="row" mx={{ _: -1, md: -2, xl: -3 }}>
        {materials.map(material => (
          <Box
            px={{ _: 1, md: 2, xl: 3 }}
            pt={{ _: 5, xl: 6 }}
            css={{
              flexBasis: '100%',
              [media(646)]: { flexBasis: '50%', maxWidth: '50%' },
              [media('lg')]: { flexBasis: '100%', maxWidth: '100%' },
              [media('xl')]: { flexBasis: '50%', maxWidth: '50%' },
            }}
            key={material.id}
          >
            <CalculatorMaterial
              material={material}
            />
          </Box>
        ))}
      </Flex>
    </CalculatorMaterialSectionStyled>
  );
}

import React from 'react';
import { keyframes } from '@emotion/core';

const colors = ['#aed6b1', '#007257', '#5da793'];

const loadAnimation = keyframes`
  0%, 100% {
    fill: ${colors[0]};
  }
  33.332% {
    fill: ${colors[1]};
  }
  66.667% {
    fill: ${colors[2]};
  }
`;

export default function Loadspinner() {
  return (
    <>
      <polygon
        css={{
          animation: loadAnimation,
          animationIterationCount: 'infinite',
          animationDuration: '600ms',
          animationDelay: '0ms',
        }}
        fill={colors[0]}
        points="5.85 8.52 10.76 5.62 10.94 3.34 8.75 2.37 1.9 6.23 1.9 6.23 1.9 6.23 1.9 6.32 1.9 10.71 1.9 10.71 1.9 17.74 4.01 18.96 3.74 16.51 5.85 15.54 5.85 8.52"
      />
      <polygon
        fill="#fff"
        points="12.17 2.63 9.98 1.67 8.75 2.37 10.94 3.34 10.76 5.62 11.91 5.01 12.17 2.63"
      />
      <path
        css={{
          animation: loadAnimation,
          animationIterationCount: 'infinite',
          animationDuration: '600ms',
          animationDelay: '200ms',
        }}
        fill={colors[1]}
        d="M12,19.05l-4.9-2.81-2.2,1,.18,2.37L12,23.56h0l10.1-5.8V15.44s-1.93,1.41-2,1.41l-2-1.41Z"
      />
      <path
        fill="#fff"
        d="M3.74,16.51,4,19l1.15.62L5,17.21l2.2-1-1.23-.7C5.15,15.89,4.44,16.24,3.74,16.51Z"
      />
      <path
        css={{
          animation: loadAnimation,
          animationIterationCount: 'infinite',
          animationDuration: '600ms',
          animationDelay: '400ms',
        }}
        fill={colors[2]}
        d="M22.09,6.23,12,.44,10,1.58l2.2,1L11.93,5c2,1.23,4.12,2.37,6.14,3.6h0l.09,5.62,1.93,1.27,2-1.4Z"
      />
      <path
        fill="#fff"
        d="M20.09,16.86l2-1.42V14l-2,1.4-1.93-1.31v1.31C18.84,16,19.46,16.44,20.09,16.86Z"
      />
    </>
  );
}

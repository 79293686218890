import React from 'react';
import { graphql } from 'gatsby';
import CraftComponents from '~/components/craft/CraftComponents';
import BlockVideoText from '~/components/common/BlockVideoText';
import { Craft_PageBuilderBlockVideoText } from '~/query';

/**
 * Available components.
 *
 * Defines available components
 * within this component.
 */
const availableComponents = {
  ElementHeadline: require('~/components/craft/CraftElementHeadline').default,
  ElementText: require('~/components/craft/CraftElementText').default,
  ElementImage: require('~/components/craft/CraftElementImage').default,
  ElementButtonGroup: require('~/components/craft/CraftElementButtonGroup')
    .default,
  ElementIconList: require('~/components/craft/CraftElementIconList').default,
  ElementDownloads: require('~/components/craft/CraftElementDownloads').default,
};

type CraftBlockVideoTextProps = {
  data: Craft_PageBuilderBlockVideoText;
};

export default function CraftBlockVideoText({
  data,
}: CraftBlockVideoTextProps) {
  const {
    children,
    imageTextImagePositionY,
    imageTextFlip,
    imageTextLayout,
    videoUrl,
    imageTextSticky,
  } = data;

  return (
    <BlockVideoText
      flip={Boolean(imageTextFlip)}
      videoUrl={videoUrl ? videoUrl : undefined}
      imagePositionY={
        imageTextImagePositionY ? imageTextImagePositionY : undefined
      }
      sticky={Boolean(imageTextSticky)}
      layout={imageTextLayout ? imageTextLayout : undefined}
    >
      <CraftComponents
        components={children}
        availableComponents={availableComponents}
        prefix={/Craft_PageBuilder(Blog)?/}
      />
    </BlockVideoText>
  );
}

export const query = graphql`
  fragment CraftPageBuilderBlockVideoTextQuery on Craft_PageBuilderBlockVideoText {
    id
    # Content
    children {
      __typename
      # Content: Elements
      ...CraftPageBuilderElementImageQuery
      ...CraftPageBuilderElementHeadlineQuery
      ...CraftPageBuilderElementTextQuery
      ...CraftPageBuilderElementButtonGroupQuery
      ...CraftPageBuilderElementIconListQuery
      ...CraftPageBuilderElementDownloadsQuery
    }

    # Appearance
    imageTextImagePositionY
    videoUrl
    imageTextFlip
    imageTextLayout
    imageTextSticky
  }
`;

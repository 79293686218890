/**
 * Copied from https://github.com/jaredpalmer/formik-persist
 */

import * as React from 'react';
import { FormikProps, connect, FormikState } from 'formik';
import debounce from 'lodash.debounce';
import isEqual from 'react-fast-compare';
import ls from 'localstorage-ttl';

type JSONReplacer = (this: any, key: string, value: any) => any;

type PartialFormikState = Pick<
  FormikState<any>,
  | 'values'
  | 'error'
  | 'errors'
  | 'touched'
  | 'isValidating'
  | 'isSubmitting'
  | 'status'
  | 'submitCount'
>;

export interface PersistProps {
  name: string;
  debounce?: number;
  replacer?: JSONReplacer;
  modifyNewState?: (newState: PartialFormikState) => PartialFormikState;
  onRehydrate?: (formik: FormikProps<any>) => void;
  ttl?: number;
}

class PersistImpl extends React.Component<
  PersistProps & { formik: FormikProps<any> },
  {}
> {
  static defaultProps = {
    debounce: 300,
  };

  saveForm = debounce((data: FormikProps<{}>) => {
    const json = JSON.stringify(data, this.props.replacer);
    if (typeof window !== 'undefined') {
      ls.set(this.props.name, json, this.props.ttl);
    }
  }, this.props.debounce);

  componentDidUpdate(prevProps: PersistProps & { formik: FormikProps<any> }) {
    if (!isEqual(prevProps.formik, this.props.formik)) {
      this.saveForm(this.props.formik);
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const maybeState = ls.get(this.props.name);
      if (maybeState && maybeState !== null) {
        let newState = JSON.parse(maybeState);
        if (this.props.modifyNewState) {
          newState = this.props.modifyNewState(newState);
        }
        this.props.formik.setFormikState(newState);
        if (this.props.onRehydrate) {
          this.props.onRehydrate(this.props.formik);
        }
      }
    }
  }

  render() {
    return null;
  }
}

export default connect<PersistProps, any>(PersistImpl);

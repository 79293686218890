import React from 'react';
import { Flex, Box, Heading } from 'rebass';
import Segment from '../Segment';
import Scrollspy from 'react-scrollspy';
import Link from '../Link';
import media from '~/utils/mediaqueries';
import { ShowMobile } from '~/components/calculator/CalculatorMediaUtils';
import JsonLdSchema from '~/components/common/JsonLdSchema';
import { generateFaqMainEntities } from './faqHelper';


type FaqProps = {
  sections: SectionType[];
};
export interface FaqType  {
  question: string;
  answer: any;
}

export interface SectionType {
  title: string;
  slug: string;
  faqs: FaqType[];
}

const HEADER_OFFSET = 165;

export const generateLdSchema = (sections: SectionType[]) => (
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": generateFaqMainEntities(sections)
  }
)

export default function Faq({ sections }: FaqProps) {
  return (
    <Segment>
      <JsonLdSchema schema={generateLdSchema(sections)}/>
      <Flex>
        <Box
          width="33%"
          sx={{
            position: 'relative',
            zIndex: 0,
            display: 'none',
            pr: 3,
            pb: 4,
            [media('lg')]: {
              display: 'block',
            },
            ['&::after']: {
              zIndex: '-1',
              content: '""',
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              width: '100vw',
              bg: 'quaternary',
            },
          }}
        >
          <Box css={{ position: 'sticky', top: 213 }}>
            <Heading
              as="h1"
              sx={{
                fontSize: 9,
                fontFamily: 'headline',
                color: 'secondary',
                textTransform: 'uppercase',
                fontWeight: 700,
                my: 4,
              }}
            >
              FAQ
            </Heading>
            <Scrollspy
              items={sections.map(({ slug }) => slug)}
              offset={-HEADER_OFFSET}
              currentClassName="is-current"
            >
              {sections.map(({ title, slug }) => (
                <Box
                  key={slug}
                  sx={
                    {
                      display: 'block',
                      fontSize: 4,
                      fontFamily: 'headline',
                      fontWeight: 500,
                      textTransform: 'uppercase',
                      color: 'secondary',
                      opacity: 0.4,
                      mb: 1,
                      pb: '5px',
                      transition:
                        'opacity 150ms ease-in-out, box-shadow 150ms ease-in-out',
                      a: {
                        textDecoration: 'none',
                        boxShadow: (theme: any) =>
                          `inset 0 0 ${theme.colors.secondary}`,
                      },
                      ['&:hover']: {
                        opacity: 0.75,
                      },
                      ['&.is-current']: {
                        opacity: 1,
                        a: {
                          boxShadow: (theme: any) =>
                            `inset 0 -2px ${theme.colors.secondary}`,
                        },
                      },
                    } as any
                  }
                >
                  <Link key={slug} to={`#${slug}`}>
                    {title}
                  </Link>
                </Box>
              ))}
            </Scrollspy>
          </Box>
        </Box>
        <Box width={{ _: '100%', lg: '67%' }} pl={{ lg: 7 }} mb={4}>
          <ShowMobile>
            <Heading
              as="h1"
              sx={{
                fontSize: 7,
                fontFamily: 'headline',
                color: 'secondary',
                textTransform: 'uppercase',
                fontWeight: 700,
                my: 3,
              }}
            >
              FAQ
            </Heading>
          </ShowMobile>
          {sections.map(({ title, slug, faqs }) => (
            <Box id={slug} key={slug}>
              <Box
                as="h2"
                sx={{
                  fontFamily: 'headline',
                  fontSize: 4,
                  fontWeight: 500,
                  textTransform: 'uppercase',
                  color: 'secondary',
                  mt: 5,
                }}
              >
                {title}
              </Box>
              {faqs.map(({ question, answer }) => (
                <Box key={question} mt={4}>
                  <Box fontSize={3}>
                    <strong>{question}</strong>
                  </Box>
                  <Box mt={1}>{answer as React.ReactNode}</Box>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Flex>
    </Segment>
  );
}

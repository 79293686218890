import { useContext } from 'react';
import { PageContext } from '../Layout';
import { navigate } from '@reach/router';

export function useSetStepIndex() {
  const pageContext = useContext(PageContext);

  const calculatorBaseUri = pageContext.calculatorWizard?.calculatorBaseUri;
  const stepSlug = pageContext.calculatorWizard?.stepSlug;

  return function setStepIndex(newStep: number | undefined, error?: boolean) {
    if (calculatorBaseUri) {
      if (newStep !== undefined) {
        navigate(
          `${calculatorBaseUri}/${stepSlug}-${newStep + 1}${
            error ? '?error=1' : ''
          }`,
        );
      } else {
        navigate(`${calculatorBaseUri}${error ? '?error=1' : ''}`);
      }
    }
  };
}

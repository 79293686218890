import React from 'react';
import { useMedia } from 'use-media';
import { breakpoints } from '~/themes/baseTheme';
import TreeCampaignTeaserBoxCraft from './TreeCampaignTeaserBoxCraft';
import TreeCampaignTeaserMobileCraft from './TreeCampaignTeaserMobileCraft';

type TreeCampaignTeaserProps = {
  text: React.ReactNode;
  title: string;
};

export default React.memo(function TreeCampaignTeaser({
  text,
  title,
}: TreeCampaignTeaserProps) {
  const isXl = useMedia({ minWidth: breakpoints.xl });
  if (isXl) {
    return <TreeCampaignTeaserBoxCraft text={text} title={title} />;
  } else {
    return (
      <TreeCampaignTeaserMobileCraft text={text} title={title} />
    );
  }
});

import React from 'react';
import { graphql } from 'gatsby';
import { Craft_PageBuilderElementList } from '~/query';
import List from '~/components/common/List';

type CraftElementListProps = {
  data?: Craft_PageBuilderElementList;
};

export default function CraftElementList({ data }: CraftElementListProps) {
  if (!data) return null;
  const { listVariant, listItems } = data;

  return <List element={listVariant} listItems={listItems} />;
}

export const query = graphql`
  # Fragment for usage in PageBuilder
  fragment CraftPageBuilderElementListQuery on Craft_PageBuilderElementList {
    id
    listVariant
    listItems {
      ... on Craft_ListItemsItem {
        id
        itemContent {
          content
        }
      }
    }
  }
`;

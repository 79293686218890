import styled from '~/utils/styled';
import { Text, Flex, Box } from 'rebass';
import media from '~/utils/mediaqueries';
import { toPx } from '~/utils/styles';
import BaseIcon from '~/components/common/Icon';
import BaseLink from '~/components/common/Link';

export const DownloadsStyled = styled(Flex)({
  flexWrap: 'wrap',
  justifyContent: 'center'
});

export const Download = styled(Box)({
  [media('lg')]: {
    minHeight: 184,
  },
});

export const Wrapper = styled(Box)({
  position: 'relative',
});

export const Link = styled(BaseLink)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  backgroundColor: 'rgba(247, 247, 247, 1)',
  boxShadow: '0 3px 16px rgba(0,0,0,.1)',
  borderRadius: 3,
  transition: 'background-color .2s',
  textDecoration: 'none',
  padding: `${toPx(theme.space[2])} ${toPx(theme.space[1])}`,
  textAlign: 'center',

  '&:hover': {
    backgroundColor: 'white',
  },
}));

export const Title = styled('span')({
  marginTop: 'auto',
  fontWeight: 600,
  flex: 1,
});

export const Icon = styled(BaseIcon)(({ theme }) => ({
  fontSize: 24,
  marginTop: theme.space[1],
  marginBottom: theme.space[2],
  flex: 'none',
}));

import React, { useState, useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useCraftTranslations,
  useCraftGlobals,
} from '~/utils/hooks/useCraftGlobals';
import { Box } from 'rebass';
import CalculatorMaterialSection from '~/components/calculator/CalculatorMaterialSection';
import CalculatorMaterialSectionTable from '~/components/calculator/CalculatorMaterialSectionTable';
import CalculatorPricingSection from '~/components/calculator/CalculatorPricingSection';
import CalculatorLayout from '~/components/calculator/CalculatorLayout';
import { Formik } from 'formik';
import Wizard, { WizardStep } from '../Wizard';
import CalculatorWizardActiveStepSection from '~/components/calculator/CalculatorWizardActiveStepSection';
import CalculatorWizardPricingSection from '~/components/calculator/CalculatorWizardPricingSection';
import CalculatorWizardSteps from '~/components/calculator/CalculatorWizardSteps';
import PageHeader from '~/components/common/PageHeader';
import { Flipper } from 'react-flip-toolkit';
import FormikPersist from '../FormikPersist';
import { CalculatorWizardStep01CompanyDataValues } from '~/components/calculator/CalculatorWizardSteps/CalculatorWizardStep01CompanyData/CalculatorWizardStep01CompanyData';
import { CalculatorWizardStep02TaxDataValues } from '~/components/calculator/CalculatorWizardSteps/CalculatorWizardStep02TaxData/CalculatorWizardStep02TaxData';
import { CalculatorWizardStep03LicenseValues } from '~/components/calculator/CalculatorWizardSteps/CalculatorWizardStep03License/CalculatorWizardStep03License';
import { CalculatorWizardStep04PaymentMethodValues } from '~/components/calculator/CalculatorWizardSteps/CalculatorWizardStep04PaymentMethod/CalculatorWizardStep04PaymentMethod';
import IntCampaign from '~/components/common/IntCampaign';
import { useSearchParam } from '~/utils/hooks/useSearchParam';
import { useWizardSubmit } from './useWizardSubmit';
import { useCalculate } from './useCalculate';
import { PageContext } from '../Layout';
import { useSetStepIndex } from './useSetStepIndex';
import { ShowMobile } from '~/components/calculator/CalculatorMediaUtils';
import CraftGatsbyImage from '~/components/craft/CraftGatsbyImage';
import Segment from '../Segment';
import ls from 'localstorage-ttl';
import media from '~/utils/mediaqueries';

type WizardValues = CalculatorWizardStep01CompanyDataValues &
  CalculatorWizardStep02TaxDataValues &
  CalculatorWizardStep03LicenseValues &
  CalculatorWizardStep04PaymentMethodValues;

const initialWizardValues = CalculatorWizardSteps.map(
  step => step.initialValues,
).reduce((allInitialValues, initialValues) => {
  return { ...allInitialValues, ...initialValues };
}, {}) as WizardValues;

export type MaterialValues = {
  materials: { [key: string]: number };
  startYear: string;
  startDate: string;
  voucherCode: string;
};

export type CalculatedPrice = number;

type CalculatorProps = {
  className?: string;
  backgroundImage?: any;
  materials: any[];
  contractYears: string[];
  defaultContractYear: string | undefined;
  contractYearsStartDateRequired: { [year: string]: boolean };
  registrationNumberInfo: React.ReactNode;
  quote?: object;
  showHeader: boolean;
  variant: 'imageList' | 'table';
  headerImage: any;
  paymentIconsImage: any;
};

export default function Calculator({
  className,
  backgroundImage,
  materials,
  contractYears,
  contractYearsStartDateRequired,
  defaultContractYear,
  registrationNumberInfo,
  quote,
  showHeader,
  variant,
  headerImage,
  paymentIconsImage,
}: CalculatorProps) {
  const t = useCraftTranslations();
  const errorParam = useSearchParam('error');
  const initialExternalPaymentError = useState(() =>
    Boolean(errorParam || false),
  )[0];

  const pageContext = useContext(PageContext);

  const {
    materialValues,
    amounts,
    startYear,
    startDate,
    couponNumber,
    couponCodeError,
    calcResult,
    handleMaterialsSubmit,
    voucherCodeFromLocalStorage,
    calculateError,
  } = useCalculate({
    contractYears,
    contractYearsStartDateRequired,
    defaultContractYear,
    materials,
  });

  useEffect(() => {
    const maybeState = ls.get('CalculatorMaterials');
    if (maybeState && maybeState !== null) {
      let newState = JSON.parse(maybeState);
      if (voucherCodeFromLocalStorage) {
        newState.values.voucherCode = voucherCodeFromLocalStorage;
      }
      if (newState && newState.values) {
        setTimeout(handleMaterialsSubmit(newState.values), 0);
      }
    }
  }, [handleMaterialsSubmit]);

  const { handleWizardSubmit, requestPaymentError } = useWizardSubmit({
    amounts,
    startYear,
    startDate,
    couponNumber,
  });

  const stepIndex = pageContext.calculatorWizard?.stepIndex;
  const initialStepIndex = useState(() => stepIndex)[0];

  const setStepIndex = useSetStepIndex();

  const showLicensing = stepIndex !== undefined;

  useEffect(() => {
    if (
      (initialStepIndex && initialStepIndex > 0) ||
      initialExternalPaymentError
    ) {
      setStepIndex(0);
    }
  }, [initialStepIndex, initialExternalPaymentError]);

  const handleGotoLicensing = useCallback(
    function handleGotoLicensing() {
      if (!calculateError) {
        setStepIndex(0);
      }
    },
    [setStepIndex, calculateError],
  );

  const handleGotoCalculator = useCallback(
    function handleGotoLicensing() {
      setStepIndex(undefined);
    },
    [setStepIndex],
  );

  const legalAndPaymentMethods = (
    <>
      {paymentIconsImage && (
        <Box mt={2} css={{ maxWidth: 448 }} mx="auto">
          <CraftGatsbyImage fluid={paymentIconsImage} />
        </Box>
      )}

      <Box mt={2} fontSize={0} color="headline">
        <strong>{t('Please note:')}</strong>{' '}
        {t('CalculatorLegalNotice') === 'CalculatorLegalNotice'
          ? 'Das Angebot richtet sich ausschließlich an Gewerbetreibende nach $ 7 Verpackungsgesetz. Alle Preise in Euro zzgl. der gesetzlichen Mehrwertsteuer. Sie schließen einen Laufzeitvertrag ab, der 3 Monate zum Jahreswechsel kündbar ist.'
          : 'The offer is exclusively for traders according to §7 VerpackG. It does not apply in conjunction with other discounts. All prices in Euro plus statutory value added tax and any separately stated late delivery surcharges. You conclude a term contract. This can be terminated with a notice period of 3 months to the end of the year.'}
      </Box>
    </>
  );

  if (showLicensing) {
    return (
      <Flipper flipKey={showLicensing}>
        <Wizard
          initialValues={initialWizardValues}
          activeStepState={[stepIndex, setStepIndex] as any}
          onSubmit={handleWizardSubmit}
          initialStep={0}
          steps={CalculatorWizardSteps.map(Step => (
            <WizardStep
              attributes={{
                title: t(Step.title),
                description: t(Step.description),
              }}
              validate={Step.validate}
            >
              <Step
                registrationNumberInfo={registrationNumberInfo}
                checkRegNumber={calcResult.checkRegNumber}
              />
            </WizardStep>
          ))}
        >
          <FormikPersist
            name="CalculatorWizard"
            replacer={(key, value) => {
              // remove payment method from persisted form state
              // for security reasons
              if (key === 'paymentMethod') {
                return initialWizardValues.paymentMethod;
              } else {
                return value;
              }
            }}
            ttl={8e9} // roughly 3 month
          />
          <Box className={className}>
            <CalculatorLayout
              themeName="secondary"
              main={
                <CalculatorWizardActiveStepSection
                  onBackButtonClick={handleGotoCalculator}
                  requestPaymentError={requestPaymentError}
                  externalPaymentError={initialExternalPaymentError}
                />
              }
              aside={
                <CalculatorWizardPricingSection
                  price={calcResult.price}
                  priceLoading={calcResult.loading}
                  discount={calcResult.discount}
                />
              }
              asideMobileSticky={false}
              asideMt={161}
            />
          </Box>
        </Wizard>
      </Flipper>
    );
  }

  return (
    <Flipper flipKey={showLicensing}>
      <Box className={className}>
        <Formik
          initialValues={materialValues}
          enableReinitialize={false}
          onSubmit={handleMaterialsSubmit}
          render={formikProps => {
            return (
              <form onSubmit={formikProps.handleSubmit}>
                <FormikPersist
                  name="CalculatorMaterials"
                  modifyNewState={newState => {
                    if (voucherCodeFromLocalStorage) {
                      newState.values.voucherCode = voucherCodeFromLocalStorage;
                    }

                    return newState;
                  }}
                  onRehydrate={formik => {
                    // submit form to get price
                    requestAnimationFrame(() => formik.submitForm());
                  }}
                  ttl={8e9} // roughly 3 month
                />
                {showHeader ? (
                  <Box css={{ position: 'relative' }}>
                    <PageHeader
                      reserveBannerSpace
                      backgroundImage={headerImage}
                      title={t('Calculator')}
                      description={t(
                        "Choose the materials you'd like to license.",
                      )}
                      overlay
                    />
                    {/* <IntCampaign /> */}
                  </Box>
                ) : null}
                <CalculatorLayout
                  css={{
                    backgroundColor: 'transparent !important',
                    [media('lg')]: {
                      marginTop: showHeader ? 0 : -30,
                    },
                    [media('xl')]: {
                      marginTop: showHeader ? 0 : -95,
                    },
                  }}
                  main={
                    variant === 'imageList' ? (
                      <CalculatorMaterialSection materials={materials} />
                    ) : (
                      <CalculatorMaterialSectionTable
                        materials={materials}
                        contractYears={contractYears}
                      />
                    )
                  }
                  aside={
                    <CalculatorPricingSection
                      contractYears={
                        variant === 'imageList' ? contractYears : undefined
                      }
                      contractYearsStartDateRequired={
                        contractYearsStartDateRequired
                      }
                      discount={calcResult.discount}
                      price={calcResult.price}
                      priceLoading={calcResult.loading}
                      quote={quote}
                      couponCodeError={couponCodeError}
                      legalAndPaymentMethods={legalAndPaymentMethods}
                      calculateError={calculateError}
                      onLicenseButtonClick={() => {
                        formikProps.submitForm();
                        handleGotoLicensing();
                      }}
                    />
                  }
                  asideMt={0}
                  //asideMt={-8}
                />
                <ShowMobile>
                  <Segment>
                    <Box mt={-1} mb={2}>
                      {legalAndPaymentMethods}
                    </Box>
                  </Segment>
                </ShowMobile>
              </form>
            );
          }}
        />
      </Box>
    </Flipper>
  );
}

Calculator.propTypes = {
  themeName: PropTypes.string,
};

Calculator.defaultProps = {
  themeName: 'default',
};

import { Box } from 'rebass';
import styled from '~/utils/styled';
import media from '~/utils/mediaqueries';

export const ContactInfoStyled = styled(Box)({});

export const Link = styled('a')(
  {
    fontWeight: 700,
    textDecoration: 'none',
    transition: 'color 0.4s',
  },
  ({ theme }) => ({
    fontSize: theme.fontSizes[4],
    [media('md')]: {
      fontSize: theme.fontSizes[6],
    },
    [media('lg')]: {
      fontSize: theme.fontSizes[7],
    },
    [media('xl')]: {
      fontSize: theme.fontSizes[8],
    },
    color: theme.colors.primary,
    '&:hover': {
      color: theme.colors.secondary,
    },
  }),
);

export const Time = styled(Box)({
  opacity: 0.7,
});

export const ContactList = styled(Box)({
  [media('md')]: {
    columns: 2,
    columnGap: '2rem',
  },
});

export const ContactListItem = styled(Box)(
  {
    paddingLeft: 17,
    marginBottom: 17,
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 10,
      width: 5,
      height: 5,
      borderRadius: '50%',
    },
  },
  ({ theme }) => ({
    '&:before': {
      background: theme.colors.text,
    },
  }),
);

import styled from '~/utils/styled';
import { Box } from 'rebass';

export const HintBox = styled(Box)(({ theme }) => ({
  borderRadius: 5,
  overflow: 'hidden',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
  color: theme.colors.text,
  position: 'relative',
}));

export const Header = styled(Box)(({ theme }) => ({
  textTransform: 'uppercase',
  textAlign: 'center',
  fontWeight: 700,
}));

Header.defaultProps = {
  bg: 'secondaryBright',
  color: 'secondaryBrightText',
  px: 1,
  py: { _: '10px', lg: '14px' },
};

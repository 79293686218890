import React from 'react';
import { Box } from 'rebass';

import {
  Step,
  StepTopline,
  StepHeadline,
  StepSticky,
  StepBox,
  StepFlex,
  Content,
} from './Elevator.styles';

export default ({
  index,
  currentIndex,
  clickDirection,
  headline,
  topline,
  content,
}) => {
  const isActive = index === currentIndex;
  return (
    <StepFlex isActive={isActive} clickDirection={clickDirection}>
      <StepBox width={{ _: 1, md: 1 / 2 }}>
        <StepSticky>
          <Step>
            <Box>
              {topline && <StepTopline>{topline}</StepTopline>}
              {headline && (
                <StepHeadline fontSize={{ _: 3, sm: 5, md: 4 }}>
                  {headline}
                </StepHeadline>
              )}
            </Box>
          </Step>
        </StepSticky>
      </StepBox>
      <Content width={{ _: 1, md: 1 / 2 }}>{content}</Content>
    </StepFlex>
  );
};

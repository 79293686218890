import CalculatorWizardStep01CompanyData from './CalculatorWizardStep01CompanyData';
import CalculatorWizardStep02TaxData from './CalculatorWizardStep02TaxData';
import CalculatorWizardStep03License from './CalculatorWizardStep03License';
import CalculatorWizardStep04PaymentMethod from './CalculatorWizardStep04PaymentMethod';

export default [
  CalculatorWizardStep01CompanyData,
  CalculatorWizardStep02TaxData,
  CalculatorWizardStep03License,
  CalculatorWizardStep04PaymentMethod,
] as [
  typeof CalculatorWizardStep01CompanyData,
  typeof CalculatorWizardStep02TaxData,
  typeof CalculatorWizardStep03License,
  typeof CalculatorWizardStep04PaymentMethod,
];

import React, { createElement, useContext } from 'react';
import { Svg } from './Loadspinner.styles';
import * as loadspinners from './loadspinners';
import { PageContext } from '~/components/common/Layout';

export type LoadspinnerNames = keyof typeof loadspinners;

type LoadspinnerProps = React.ComponentProps<typeof Svg> & { loading?: boolean };

/**
 * Available loadspinners.
 *
 * To add a new logo, simply create a component in loadspinners/ and
 * import it in loadspinners/index.js
 */
interface LoadspinnerMap {
  [logo: string]: React.ReactNode;
}

const availableLoadspinners: LoadspinnerMap = (Object.keys(
  loadspinners,
) as Array<LoadspinnerNames>).reduce(
  (logoElements, logoKey) => ({
    ...logoElements,
    [logoKey]: createElement(loadspinners[logoKey]),
  }),
  {},
);

export default function Loadspinner({ loading, ...props }: LoadspinnerProps) {
  const { site = '' } = useContext(PageContext);
  const logo = availableLoadspinners[site] || availableLoadspinners['mainDe'];
  if (!logo || !loading) return null;
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      {logo}
    </Svg>
  );
}

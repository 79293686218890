import React from 'react';
import { graphql } from 'gatsby';
import Video from '~/components/common/Video';

export default function CraftElementVideo({ data }) {
  const { videoUrl } = data;;

  return videoUrl ? <Video url={videoUrl} /> : null;
}

export const query = graphql`
  # Fragment for usage in PageBuilder
  fragment CraftPageBuilderElementVideoQuery on Craft_PageBuilderElementVideo {
    id
    videoUrl
  }

  # Fragment for usage in PageBuilder
  fragment CraftPageBuilderBlogElementVideoQuery on Craft_PageBuilderBlogElementVideo {
    id
    videoUrl
  }
`;

import { useState, useCallback, useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useVoucherCode } from '~/utils/hooks/useVoucherCode';
import { ApolloError } from 'apollo-client';
import {
  useCraftTranslations,
  useCraftGlobals,
} from '~/utils/hooks/useCraftGlobals';

export type MaterialValues = {
  materials: { [key: string]: number };
  startYear: string;
  startDate: string;
  voucherCode: string;
};

export type CalculatedPrice = number;

function getCouponCodeError(
  error: ApolloError | undefined,
): string | undefined {
  if (error && error.graphQLErrors) {
    const graphqlError = error.graphQLErrors[0];
    if (graphqlError && graphqlError.extensions) {
      const couponError = graphqlError.extensions.exception.couponNumber;
      if (typeof couponError === 'string') {
        return couponError;
      }
    }
  }
  return undefined;
}

export function useCalculate({
  contractYears,
  contractYearsStartDateRequired,
  defaultContractYear,
  materials,
}: {
  contractYears: string[];
  contractYearsStartDateRequired: { [year: string]: boolean };
  defaultContractYear: string | undefined;
  materials: any[];
}) {
  const t = useCraftTranslations();
  const [voucherCodeFromLocalStorage] = useVoucherCode();
  const { globalSiteSettings } = useCraftGlobals();

  const [materialValues, setMaterialValues] = useState<MaterialValues>(() => {
    return {
      materials: materials.reduce(
        (values, material) => ({
          ...values,
          [material.identifier]: 0,
        }),
        {},
      ),
      // default from CMS or median year with tendency to choose lower years
      startYear:
        defaultContractYear ||
        contractYears[Math.floor((contractYears.length - 1) / 2)],
      startDate: '',
      voucherCode: voucherCodeFromLocalStorage || '',
    };
  });

  const amounts = Object.keys(materialValues.materials).map(materialId => ({
    materialId: parseInt(materialId, 10),
    amount: materialValues.materials[materialId],
  }));

  // Convert other data to match API requirements
  const startYear = Number(materialValues.startYear);
  const startDate = contractYearsStartDateRequired[
    String(materialValues.startYear)
  ]
    ? materialValues.startDate
      ? Number(materialValues.startDate) / 1000
      : undefined
    : undefined;
  const couponNumber =
    globalSiteSettings?.forcedVoucherCode || String(materialValues.voucherCode);

  const isEmptyAmounts =
    Object.values(materialValues.materials).reduce(
      (sum, val) => sum + Number(val),
      0,
    ) <= 0;

  const calculateQuery = gql`
    query CalculateQuery(
      $amounts: [MaterialWithAmountInput!]!
      $startYear: Int!
      $startDate: Int
      $couponNumber: String
    ) {
      c: calculate(
        input: {
          amounts: $amounts
          startYear: $startYear
          startDate: $startDate
          couponNumber: $couponNumber
        }
      ) {
        price
        discount
        checkRegNumber
      }
    }
  `;

  const {
    loading: priceLoading,
    error: priceError,
    data: _priceData,
  } = useQuery(calculateQuery, {
    variables: { amounts, startYear, startDate, couponNumber },
    skip: isEmptyAmounts,
    errorPolicy: 'all',
  });

  const priceData = !priceError
    ? _priceData
    : {
        c: {
          ..._priceData?.c,
          discount: undefined,
          price: undefined,
        },
      };

  const calcResult = {
    loading: priceLoading,
    error: priceError,
    price: priceData?.c?.price as number | undefined,
    checkRegNumber: priceData?.c?.checkRegNumber as boolean | undefined,
    discount: priceData?.c?.discount as number | undefined,
  };

  const couponCodeError = getCouponCodeError(calcResult.error);

  const [calculateError, setCalculateError] = useState<string>();

  const handleMaterialsSubmit = useCallback(
    function handleMaterialsSubmit(values: MaterialValues) {
      const startDate = new Date(values.startDate);

      if (contractYearsStartDateRequired[String(values.startYear)]) {
        if (!values.startDate) {
          setCalculateError(t('startDate') + ' ' + t('is required'));
        } else if (startDate.getFullYear() !== parseInt(values.startYear, 10)) {
          setCalculateError(
            t('startDate') + ' ' + t('must be within start year'),
          );
        } else {
          setCalculateError(undefined);
        }
      } else {
        setCalculateError(undefined);
      }

      setMaterialValues(values);
    },
    [setMaterialValues],
  );

  return {
    materialValues,
    amounts,
    isEmptyAmounts,
    startYear,
    startDate,
    couponNumber,
    couponCodeError,
    calcResult,
    handleMaterialsSubmit,
    voucherCodeFromLocalStorage,
    calculateError,
  };
}

import React from 'react';
import { IconNames } from '../Icon/Icon';
import {
  IconItem,
  IconListStyled,
  Icon,
  TitleDesktop,
  TitleMobile,
} from './IconList.styles';
import { Box } from 'rebass';

export type IconListItem = {
  iconName: IconNames;
  iconTitle: React.ReactNode;
  iconTitleShort: React.ReactNode;
};

type PaddingSize = 'none' | 'small' | 'medium' | 'large' | null;

function paddingSizeToTopBottom(paddingSize?: PaddingSize) {
  let pt, pb;

  switch (paddingSize) {
    case 'none':
      pt = pb = 0;
      break;
    case 'small':
      pt = pb = { _: 1, md: 1 };
      break;
    case 'medium':
      pt = pb = { _: 1, md: 2 };
      break;
    case 'large':
    default:
      pt = pb = { _: 1, md: 4 };
      break;
  }

  return [pt, pb] as const;
}

type IconListProps = {
  direction?: 'row' | 'column';
  items?: IconListItem[];
  py?: PaddingSize;
  pb?: 'asPy' | PaddingSize;
};

export default function IconList({ py, pb, direction, items }: IconListProps) {
  let [_pt, _pb] = paddingSizeToTopBottom(py);

  if (pb !== 'asPy' && typeof pb !== 'undefined') {
    [, _pb] = paddingSizeToTopBottom(pb);
  }

  return items ? (
    <IconListStyled mx={-1} mt={_pt} mb={_pb} flexDirection={direction}>
      {items.map(({ iconName, iconTitle, iconTitleShort }) => (
        <IconItem px={1} key={iconName}>
          <Icon name={iconName} fontSize={8} color="primary" />
          <Box ml={1}>
            <TitleDesktop>{iconTitle}</TitleDesktop>
            <TitleMobile>{iconTitleShort}</TitleMobile>
          </Box>
        </IconItem>
      ))}
    </IconListStyled>
  ) : null;
}

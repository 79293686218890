import React from 'react';
import { graphql } from 'gatsby';

import FormContact from '~/components/common/FormContact';

export default function CraftElementFormContact({ data }) {
  const { formContactName, formContactSubjects } = data;

  return (
    <FormContact
      name={formContactName ?? undefined}
      subjects={formContactSubjects ?? undefined}
    />
  );
}

export const query = graphql`
  # Fragment for usage in PageBuilder
  fragment CraftPageBuilderElementFormContactQuery on Craft_PageBuilderElementFormContact {
    id
    formContactName
    formContactSubjects {
      subject
    }
  }
`;

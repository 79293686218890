import React from 'react';
import { graphql } from 'gatsby';
import CraftPageBuilder from '~/components/craft/CraftPageBuilder';
import Accordion, { AccordionItem } from '~/components/common/AccordionBasic';
import PageBuilderContext from '~/components/common/PageBuilderContext';
import slugify from '~/utils/slugify';

type CraftBlockAccordionProps = {
  data: any;
};

export default function CraftBlockAccordion({
  data,
}: CraftBlockAccordionProps) {
  const { accordionItems } = data;

  const items = accordionItems
    ? accordionItems
        .map((item: any) => {
          if (!item) return undefined;

          return {
            id: item.id ?? undefined,
            title: item.title ?? undefined,
            pageBuilder: item.pageBuilder ?? undefined,
          };
        })
        .filter(Boolean)
    : [];

  return (
    <Accordion
      initialOpenedItems={() => {
        if (typeof window !== 'undefined') {
          const hash = window.location.hash.replace(/^#/, '');
          return { [hash]: true };
        }
        return {};
      }}
    >
      {items.map((item: any) => (
        <AccordionItem
          key={item.id}
          name={slugify(item.title)}
          title={item.title}
        >
          <PageBuilderContext.Provider value={{ lessSpacing: true }}>
            <CraftPageBuilder components={item.pageBuilder} />
          </PageBuilderContext.Provider>
        </AccordionItem>
      ))}
    </Accordion>
  );
}

export const query = graphql`
  fragment CraftPageBuilderBlockAccordionQuery on Craft_PageBuilderBlockAccordion {
    id

    # Items
    accordionItems {
      __typename
      ... on Craft_AccordionContent {
        id
        title
        # accordionContentTeasertext {
        #   content
        # }
        # accordionContentTeaserimage {
        #   url
        # }
        pageBuilder {
          ...CraftPageBuilderQuery_NoNesting
        }
      }
    }
  }
`;

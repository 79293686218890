import React from 'react';
import { graphql } from 'gatsby';
import Parser from '~/components/common/Parser';
import TreeCampaignTeaser from '~/components/common/TreeCampaignTeaser';

type CraftBlockTreeCampaignProps = {
  data: any;
};

export default function CraftBlockTreeCampaign({
  data,
}: CraftBlockTreeCampaignProps) {
  const { blockTreeCampaignTitle, blockTreeCampaignText } = data;

  const text = blockTreeCampaignText ? (
    <Parser html={blockTreeCampaignText.content} />
  ) : (
    undefined
  );

  const title = blockTreeCampaignTitle ?? undefined;

  return <TreeCampaignTeaser text={text} title={title} />;
}

export const query = graphql`
  fragment CraftPageBuilderBlockTreeCampaignQuery on Craft_PageBuilderBlockTreeCampaign {
    id
    blockTreeCampaignTitle
    blockTreeCampaignText {
      content
    }
  }
`;

import styled from '~/utils/styled';
import { toPx, gradientLinear } from '~/utils/styles';

export const PictureStyled = styled('picture')({
  position: 'relative',
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
});

export const ImgStyled = styled('img')({
  // Fun fact: https://stackoverflow.com/questions/10844205/html-5-strange-img-always-adds-3px-margin-at-bottom
  verticalAlign: 'middle',
  maxWidth: '100%',
  height: 'auto',
});

// This needs to be down here because we reference the above styles.
export const TextImageWrapperStyled = styled('figure')(
  props => ({
    position: 'relative',
    margin: 0,
    marginTop: props.theme.space[1],
    marginBottom: props.theme.space[2],
  }),
  props =>
    props.maxWidth && {
      maxWidth: props.maxWidth,
    },
);

export const TextImageStyled = styled('div')({
  flex: 'none',
});

export const PictureWrapperStyled = styled('div')(
  {
    position: 'relative',
  },
  props =>
    props.appearance === 'colored_shadow' && {
      '&::before': {
        position: 'absolute',
        display: 'block',
        content: '""',
        top: 0,
        left: 0,
        height: '100%',
        transform: 'translate(20px, -20px)',
        width: 'calc(100% - 20px)',
        clipPath: 'polygon(0 15%, 100% 0%, 100% 85%, 0% 100%)',
      },

      [`${PictureStyled}`]: {
        clipPath: 'polygon(0 15%, 100% 0%, 100% 85%, 0% 100%)',
      },

      [`${ImgStyled}`]: {
        width: 'calc(100% - 20px)',
      },
    },
);

export const CaptionStyled = styled('figcaption')(props => ({
  fontSize: props.theme.fontSizes[1],
  marginTop: props.theme.space[1],
  fontWeight: 700,
}));

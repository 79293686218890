import { useState, useEffect } from 'react';
import queryString from 'query-string';

function getParamValue(param: string) {
  if (typeof location !== 'undefined') {
    const params = queryString.parse(location.search);
    const _newParamValue = params[param];
    const newParamValue = Array.isArray(_newParamValue)
      ? _newParamValue[0]
      : _newParamValue;
    return newParamValue ?? null;
  } else {
    return null;
  }
}

export function useSearchParam(param: string) {
  const [paramValue, setParamValue] = useState<string | null>(() =>
    getParamValue(param),
  );

  useEffect(() => {
    setParamValue(getParamValue(param));
  }, [
    typeof location !== 'undefined' ? location.search : undefined,
    setParamValue,
  ]);

  return paramValue;
}

import React from 'react';
import { keyframes } from '@emotion/core';

const colors = ['#ABC4DD', '#144899', '#0799B4'];

const loadAnimation = keyframes`
  0%, 100% {
    fill: ${colors[0]};
  }
  33.332% {
    fill: ${colors[1]};
  }
  66.667% {
    fill: ${colors[2]};
  }
`;

export default function Loadspinner() {
  return (
    <>
      <path
        css={{
          animation: loadAnimation,
          animationIterationCount: 'infinite',
          animationDuration: '600ms',
          animationDelay: '0ms',
        }}
        fill={colors[0]}
        d="M8,12V9.8c0,0-0.1-1.1,0.5-1.5c0.6-0.3,2.1-1.1,2.1-1.1l5.5-2.9V0L7.6,4.4C6.9,4.7,6.2,5.2,5.7,5.7
		C5.2,6.2,4.8,6.9,4.5,7.6C4.3,8,4.3,8.5,4.2,8.9v3L8,12z"
      />
      <path
        css={{
          animation: loadAnimation,
          animationIterationCount: 'infinite',
          animationDuration: '600ms',
          animationDelay: '200ms',
        }}
        fill={colors[1]}
        d="M16,12.1v2.2c0,0,0.1,1.1-0.5,1.5c-0.6,0.3-2.1,1.1-2.1,1.1l-5.5,2.9V24l8.5-4.4c0.7-0.3,1.4-0.7,2-1.3
		c0.5-0.5,0.9-1.2,1.2-1.9c0.2-0.4,0.2-0.9,0.3-1.4v-3C19.8,12.1,16,12.1,16,12.1z"
      />
      <path
        css={{
          animation: loadAnimation,
          animationIterationCount: 'infinite',
          animationDuration: '600ms',
          animationDelay: '400ms',
        }}
        fill={colors[2]}
        d="M16.1,4.3v2.2c0,0,0.1,1.1-0.5,1.5s-2.1,1.1-2.1,1.1l-3.9,2l-2.1,1.1c-0.7,0.3-1.4,0.8-2,1.3
		C5,14,4.7,14.7,4.4,15.4c-0.2,0.4-0.2,0.9-0.3,1.4v3h3.8v-2.2c0,0-0.1-1.1,0.5-1.5C9,15.7,10.5,15,10.5,15l3.9-2.1l2.1-1.1
		c0.7-0.3,1.4-0.7,2-1.3c0.5-0.6,0.9-1.2,1.1-1.9c0.2-0.4,0.2-0.9,0.3-1.4v-3C19.9,4.3,16.1,4.3,16.1,4.3z"
      />
    </>
  );
}

import React from 'react';
import { graphql } from 'gatsby';
import { getComponents } from '~/components/craft/CraftComponents';
import BlockColumns from '~/components/common/BlockColumns';
import { Craft_PageBuilderBlockColumns } from '~/query';

/**
 * Available components.
 *
 * Defines available components
 * within this component.
 */
const availableComponents = {
  BlockContent: require('~/components/craft/CraftBlockContent').default,
  BlockTreeCampaign: require('~/components/craft/CraftBlockTreeCampaign')
    .default,
};

type CraftBlockColumnsProps = {
  data: Craft_PageBuilderBlockColumns;
};

export default function CraftBlockColumns({ data }: CraftBlockColumnsProps) {
  const { children } = data;

  return (
    <BlockColumns>
      {getComponents(children, availableComponents, 'Craft_PageBuilder')}
    </BlockColumns>
  );
}

export const query = graphql`
  fragment CraftPageBuilderBlockColumnsQuery on Craft_PageBuilderBlockColumns {
    id
    # Content
    children {
      __typename
      ...CraftPageBuilderBlockContentQuery
      ...CraftPageBuilderBlockTreeCampaignQuery
    }
  }
`;

import styled from '~/utils/styled';
import { Radio, Label } from '@rebass/forms';
import { Flex, Box } from 'rebass';

export const RadioGroup = styled(Flex)({});

RadioGroup.defaultProps = {};

type RadioButtonProps = {
  radioVariant?: 'outline' | 'pills';
} & typeof RadioButtonDefaultProps;

const RadioButtonDefaultProps = {
  px: 1,
  py: '12px',
  radioVariant: 'outline',
};

export const RadioButton = styled(Label)<RadioButtonProps>(
  ({ theme, radioVariant }) => {
    switch (radioVariant) {
      case 'outline':
        return {
          flex: '1 1 100%',
          border: `1px solid ${theme.colors.primaryBright}`,
          textAlign: 'center',
          borderRightWidth: 0,
          color: theme.colors.primary,
          cursor: 'pointer',
          '&:first-of-type': {
            borderRadius: '5px 0 0 5px',
          },
          '&:last-of-type': {
            borderRadius: '0 5px 5px 0',
            borderRightWidth: 1,
          },
          '&:first-of-type:last-of-type': {
            borderRadius: 5,
            borderRightWidth: 1,
          },
          'input[type="radio"]:checked + &': {
            color: theme.colors.primaryText,
            borderColor: theme.colors.primary,
            backgroundColor: theme.colors.primary,
            fontWeight: 700,
            '&:not(:last-of-type)': {
              boxShadow: `1px 0 0 ${theme.colors.primary}`,
              position: 'relative',
            },
          },
        };
      case 'pills':
        return {
          flex: '1 1 100%',
          textAlign: 'center',
          color: theme.colors.primary,
          cursor: 'pointer',
          borderRadius: 5,
          lineHeight: 1,
          'input[type="radio"]:checked + &': {
            color: theme.colors.primaryText,
            backgroundColor: theme.colors.primary,
            fontWeight: 700,
          },
        };
    }
  },
);

RadioButton.defaultProps = RadioButtonDefaultProps;

export const RadioInput = styled('input')({
  visibility: 'hidden',
  width: 0,
  height: 0,
});

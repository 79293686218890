import React, { useRef } from 'react';
import { Box, Flex } from 'rebass';
import Segment from '~/components/common/Segment';
import media from '~/utils/mediaqueries';
import { useKeyboardShown } from '~/utils/hooks/useKeyboardShown';

type CalculatorLayoutProps = {
  main?: React.ReactNode;
  aside?: React.ReactNode;
  asideMt?: number | string;
  themeName?: string;
  className?: string;
  asideMobileSticky?: boolean;
};

export default function CalculatorLayout({
  main,
  aside,
  themeName,
  asideMt,
  className,
  asideMobileSticky = true,
}: CalculatorLayoutProps) {
  const ignoreElementRef = useRef(null);
  const keyboardShown = useKeyboardShown({ ignoreElementRef });
  const mobilePosition = asideMobileSticky ? 'sticky' : 'relative';
  const bottomPosition = asideMobileSticky ? -30 : 0;

  return (
    <Segment themeName={themeName || 'default'} className={className}>
      <Flex mx={{ lg: -2, xl: -3 }} flexWrap="wrap" justifyContent="center">
        <Box
          css={{
            flexBasis: '100%',
            [media('lg')]: { flexBasis: '53%', maxWidth: '53%' },
            [media('xl')]: { flexBasis: '64%', maxWidth: '64%' },
            [media('xxl')]: { flexBasis: '60%', maxWidth: '60%' },
          }}
          px={{ lg: 2, xl: 3 }}
        >
          {main}
        </Box>
        {aside ? (
          <Box
            css={{
              opacity: keyboardShown ? 0 : 1,
              pointerEvents: keyboardShown ? 'none' : 'all',
              transition: 'opacity 200ms ease-in-out',
              flexBasis: `calc(100% + 34px)`,
              maxWidth: `calc(100% + 34px)`,
              position: mobilePosition,
              bottom: bottomPosition,
              zIndex: 100,
              [media('lg')]: {
                opacity: 1,
                pointerEvents: 'all',
                position: 'static',
                display: 'block',
                flexBasis: '47%',
                maxWidth: '47%',
              },
              [media('xl')]: { flexBasis: '36%', maxWidth: '36%' },
              [media('xxl')]: { flexBasis: '40%', maxWidth: '40%' },
            }}
            px={{ lg: 2, xl: 3 }}
            // _ and md margins copied from Segment/Wrapper:
            mx={{ _: -1, lg: 0 }}
            mt={asideMt ? { lg: asideMt } : undefined}
            as="aside"
          >
            <Box
              css={{
                [media('lg')]: {
                  position: 'sticky',
                  top: 176,
                  marginBottom: 30,
                  marginTop: 30,
                },
              }}
              ref={ignoreElementRef}
            >
              {aside}
            </Box>
          </Box>
        ) : null}
      </Flex>
    </Segment>
  );
}

import { Box } from 'rebass';
import styled from '~/utils/styled';
import media from '~/utils/mediaqueries';
import Headline from '~/components/common/Headline';

export const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'black',
  opacity: 0.6,
});

type PageHeaderInnerProps = {
  reserveBannerSpace?: boolean;
};

export const PageHeaderInner = styled(Box)<PageHeaderInnerProps>(
  ({ reserveBannerSpace }) => ({
    position: 'relative',
    maxWidth: '80%',
    // this reserves space on the right for eventual banners and ads
    [media('xl')]: {
      marginRight: reserveBannerSpace ? '567px' : 0,
    },
  }),
);

export const PageHeaderTitle = styled(Headline)(({ theme }) => ({
  marginBottom: theme.space[1],

  [media('md')]: {
    marginBottom: theme.space[1],
  },

  [media('lg')]: {
    marginBottom: theme.space[1],
  },
}));

import { keyframes } from '@emotion/core';
import { Flex, Box } from 'rebass';
import styled from '~/utils/styled';
import media from '~/utils/mediaqueries';

import Icon from '~/components/common/Icon';

const fadeUp = keyframes`
  0% {
    transform: translateY(30%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const fadeDown = keyframes`
  0% {
    transform: translateY(-30%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Elevator = styled(Box)(
  {
    position: 'relative',
    paddingLeft: 20,
    paddingRight: 20,
    [media('md')]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    marginBottom: 40,
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 5,
  },

  props => ({
    color: props.theme.colors.text,
    background: props.theme.colors.background,
    borderColor:
      props.elevatorTheme === 'white'
        ? props.theme.colors.border
        : props.theme.colors.background,
  }),
);

export const StepSticky = styled(Box)({
  [media('md')]: {
    position: 'sticky',
    zIndex: 10,
    top: '25vh',
    left: 0,
    width: '100%',
    minHeight: '40vh',
  },
});

export const ArrowBox = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '50%',
  height: '100%',
  display: 'none',
  [media('md')]: {
    display: 'block',
  },
});

export const Arrows = styled(Box)({
  position: 'sticky',
  zIndex: 10,
  top: '25vh',
  left: 0,
  width: '100%',
  minHeight: '40vh',
  pointerEvents: 'none',
});

export const ArrowBefore = styled(Icon)(
  {
    transform: 'scale(2)',
    position: 'absolute',
    top: '20%',
    left: '25%',
    pointerEvents: 'initial',
    transition: 'opacity 0.2s ease-in-out',
  },
  ({ isDisabled }) => ({
    opacity: isDisabled ? 0.2 : 0.75,
    cursor: isDisabled ? 'normal' : 'pointer',
    '&:hover': {
      opacity: isDisabled ? 0.2 : 1,
    },
  }),
);

export const ArrowNext = styled(Icon)(
  {
    transform: 'scale(2)',
    position: 'absolute',
    bottom: '20%',
    left: '25%',
    pointerEvents: 'initial',
    transition: 'opacity 0.2s ease-in-out',
  },
  ({ isDisabled }) => ({
    opacity: isDisabled ? 0.2 : 0.75,
    cursor: isDisabled ? 'normal' : 'pointer',
    '&:hover': {
      opacity: isDisabled ? 0.2 : 1,
    },
  }),
);

export const Content = styled(Flex)({
  alignItems: 'center',
});

export const StepFlex = styled(Box)(
  {
    flexAlign: 'nowrap',
    display: 'flex',
    flexDirection: 'column',
    [media('md')]: {
      flexDirection: 'row',
      display: 'none',
    },
  },
  ({ isActive, clickDirection }) =>
    isActive && {
      display: 'flex',
      animationName: clickDirection ? fadeUp : fadeDown,
      animationDuration: '0.4s',
      animationIterationCount: '1',
      [media('md')]: {
        display: 'flex',
      },
    },
);

export const StepBox = styled(Box)({
  position: 'relative',
});

export const Step = styled(Box)({
  [media('md')]: {
    position: 'absolute',
    top: 0,
    left: 0,
    paddingTop: 80,
    paddingBottom: 80,
    minHeight: '40vh',
  },
  paddingTop: 40,
  display: 'flex',
  alignItems: 'center',
});

export const StepTopline = styled(Box)({});

export const StepHeadline = styled(Box)(
  {
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: '0.02em',
    lineHeight: 1.4,
  },
  ({ theme }) => ({
    fontFamily: theme.fonts.headline,
  }),
);

import React from 'react';
import { Box } from 'rebass';
import { graphql } from 'gatsby';

import TextWrapper from '~/components/common/TextWrapper';
import Parser from '~/components/common/Parser';
import { ALLOWED_TAGS_BLOCK as allowedTags } from '~/components/common/Parser/parse';

export default function CraftElementText({ data }) {
  const { textContent, textThemeName, textRemoveMargins } = data;

  return (
    <Box>
      <TextWrapper
        themeName={textThemeName || undefined}
        removeMargins={Boolean(textRemoveMargins) || undefined}
      >
        <Parser html={textContent?.content} allowedTags={allowedTags} />
      </TextWrapper>
    </Box>
  );
}

export const query = graphql`
  # Fragment for usage in PageBuilder
  fragment CraftPageBuilderElementTextQuery on Craft_PageBuilderElementText {
    id
    # Content
    textContent {
      content
    }
    # Appearance
    textThemeName
    textRemoveMargins
  }
  fragment CraftPageBuilderBlogElementTextQuery on Craft_PageBuilderBlogElementText {
    id
    # Content
    textContent {
      content
    }
    # Appearance
    textThemeName
    textRemoveMargins
  }
`;

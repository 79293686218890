import React from 'react';
import { Box } from 'rebass';
import Headline from '~/components/common/Headline';
import Text from '~/components/common/Text';
import { IconNames } from '~/components/common/Icon/Icon';
import {
  IconBlocksStyled,
  IconBlock,
  Header,
  IconWrap,
  Icon,
} from './IconBlocks.styles';

export type IconListItem = {
  iconName: IconNames;
  iconTitle: React.ReactNode;
  iconDescription: React.ReactNode;
};

type IconListProps = {
  items?: IconListItem[];
};

export default function IconBlocks({ items }: IconListProps) {
  return items ? (
    <IconBlocksStyled mx={{ _: -1, md: -3, lg: -4 }} mt={{ _: 3, md: 3 }}>
      {items.map(({ iconName, iconTitle, iconDescription }) => (
        <IconBlock
          px={{ _: 1, md: 3, lg: 4 }}
          mt={{ _: 1, md: 2, lg: 3 }}
          mb={{ _: 3, md: 3, lg: 3 }}
          width={{ _: 1, md: 1 / 2 }}
          key={iconName}
        >
          <Header mb={1}>
            <IconWrap mr={2}>
              <Icon name={iconName} fontSize={8} />
            </IconWrap>
            <Headline level="h4" mt={0} mb={0}>
              {iconTitle}
            </Headline>
          </Header>
          {iconDescription}
        </IconBlock>
      ))}
    </IconBlocksStyled>
  ) : null;
}

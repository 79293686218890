import { SectionType } from './Faq';

export interface FaqLdType {
  '@type': string;
  name: string;
  acceptedAnswer: {
    '@type': string;
    text: string;
  }
}

export const stripTagsFromHtml = (str: string) => (str.replace(/<\/?[^>]+(>|$)/g, ''))

export const  generateFaqMainEntities = (sections: SectionType[]) => {
  const faqLd: FaqLdType[] = [];

  sections.forEach((section) => {
    section.faqs.forEach((faq) => {
      faqLd.push({
        '@type': 'Question',
        'name': faq.question,
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': stripTagsFromHtml(faq.answer.props.html),
        }
      })
    })
  })

  return faqLd;
}

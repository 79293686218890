import styled from '~/utils/styled';
import { Flex, Box } from 'rebass';
import media from '~/utils/mediaqueries';

export const BackButton = styled(Flex)(({ theme }) => ({
  display: 'inline-flex',
  color: `${theme.colors.text} !important`,
  font: 'inherit',
  border: 'none',
  WebkitAppearance: 'none',
  background: 'transparent',
  cursor: 'pointer',
}));

BackButton.defaultProps = {
  ml: {_: 0, lg: 1},
  p: 1,
  alignItems: 'center',
};

export const BackIcon = styled(Box)(({ theme }) => ({
  lineHeight: 0,
}));

BackIcon.defaultProps = {
  fontSize: 5,
  mr: 1,
};

import React from 'react';
import { Box } from 'rebass';
import { IconNames } from '~/components/common/Icon/Icon';

import { BlockServiceStyled, Decoration, Image } from './BlockService.styles';

type BlockServiceProps = {
  children?: any;
  image?: any;
};

export default function BlockService({ children, image }: BlockServiceProps) {
  return (
    <BlockServiceStyled flexWrap="wrap">
      <Decoration>
        {image ? (
          <Image
            fluid={image}
            args={{
              width: image.width,
              maxWidth: 1280,
              aspectRatio: 1,
            }}
          />
        ) : null}
      </Decoration>
      <Box width={{ _: 1, sm: 1, md: 1, lg: 3 / 5, xl: 3 / 5 }} py={{ _: 4 }}>
        {children}
      </Box>
    </BlockServiceStyled>
  );
}

const quantifier = [3, 9, 7, 1, 3, 9, 7, 1, 3, 9, 7, 1, 1];

// Function description: Calculates the cross sum of a number
function crossSum(number: number) {
  let _num = number;
  let sum = 0;
  while (_num !== 0) {
    const add = _num % 10;
    sum += add;
    _num = Math.floor(_num / 10); // division without remainder
  }
  return sum;
}

const tempNumRegex = /-V$/;

// export function checkTemporaryRegistrationNumberValidity(
//   temporaryRegistrationNumberString: string,
// ) {
//   if (temporaryRegistrationNumberString.match(tempNumRegex)) {
//     return checkRegistrationNumberValidity(
//       String(temporaryRegistrationNumberString).replace(tempNumRegex, ''),
//     );
//   }

//   return false;
// }

// Function description: returns "true" if registrationNumberString is valid and "false" if it is not
export function checkRegistrationNumberValidity(
  registrationNumberString: string,
) {
  // Check for correct structure
  if (registrationNumberString.length !== 15) {
    return false;
  }

  if (registrationNumberString.substring(0, 2) !== 'DE') {
    return false;
  }

  // Extract the RegistrationNumber without the country code
  let onlyDigitsString = registrationNumberString.substring(2, 15);
  if (!/^\d+$/.test(onlyDigitsString)) {
    return false;
  }

  // Luhn-algorithm with varied weights and cross sum calculation
  let sum = 0;
  for (let i = 0; i < onlyDigitsString.length; i++) {
    let tempSum =
      parseInt(onlyDigitsString.substring(i, i + 1), 10) * quantifier[i];
    if (tempSum >= 10) {
      tempSum = crossSum(tempSum);
    }
    sum = sum + tempSum;
  }

  return sum % 10 === 0;
}

import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  Children,
  useEffect,
  useRef,
} from 'react';
import { Formik, FormikActions, FormikProps } from 'formik';
import isEqual from 'react-fast-compare';
import useTimeout from '~/utils/hooks/useTimeout';

type Values = { [key: string]: any };

type Errors = { [key: string]: any };

export type Validate = ((values: Values) => Errors | undefined) | undefined;

type WizardStepElement = React.ReactElement<typeof WizardStep>;

type WizardContextType = {
  activeStepIndex: number;
  activeStep: React.ReactNode;
  activeStepAttributes: any;
  isLastStep: boolean;
  formikProps?: FormikProps<Values>;
  previous: () => void;
  steps: WizardStepElement[];
  stepAttributes: any[];
};

const WizardContext = createContext<WizardContextType>({
  activeStepIndex: 0,
  activeStep: null,
  activeStepAttributes: {},
  isLastStep: false,
  previous: () => {},
  steps: [],
  stepAttributes: [],
});

function useForwardToInitialStep({
  initialStep,
  activeStepIndex,
  initialValues,
}: {
  initialStep?: number;
  activeStepIndex: number;
  initialValues: any;
}) {
  const initialStepCounter = useRef<number>();
  const formikPropsRef = useRef<FormikProps<any>>();

  const fwdTimout = useTimeout(() => {
    initialStepCounter.current = 0;
  }, 50);

  useEffect(() => {
    if (initialStep) {
      if (initialStepCounter.current === undefined) {
        initialStepCounter.current = initialStep;
      }
      if (isEqual(formikPropsRef.current?.values, initialValues)) {
        return;
      }
      if (
        initialStep > activeStepIndex &&
        (initialStepCounter.current as number) > 0
      ) {
        fwdTimout.start();

        if (formikPropsRef.current?.submitForm) {
          // console.log('click', {
          //   initialStep,
          //   activeStepIndex,
          //   initialStepCounter,
          // });
          formikPropsRef.current.submitForm();
          (initialStepCounter.current as number)--;
        }
      } else {
        initialStepCounter.current = 0;
      }
    }
  }, [
    activeStepIndex,
    initialStep,
    initialValues,
    initialStepCounter,
    formikPropsRef,
  ]);

  return { formikPropsRef };
}

type WizardProps = {
  initialValues: Values;
  children: React.ReactNode;
  steps: WizardStepElement[];
  initialStep?: number;
  onSubmit: (
    values: any,
    bag: FormikActions<any>,
  ) => boolean | undefined | void;
  onSubmitStep?: (
    values: any,
    bag: FormikActions<any>,
  ) => boolean | undefined | void;
  activeStepState?: [number, React.Dispatch<React.SetStateAction<number>>];
};

type StepAttributes = {
  isActive: boolean;
  isCompleted: boolean;
};

export default function Wizard({
  initialValues,
  children,
  steps: _steps,
  initialStep,
  onSubmit,
  onSubmitStep,
  activeStepState,
}: WizardProps) {
  const internalActiveStepState = useState(0);
  const [activeStepIndex, setActiveStepIndex] =
    activeStepState ?? internalActiveStepState;
  const [values, setValues] = useState(initialValues);
  const [completedSteps, setCompletedSteps] = useState<boolean[]>([]);

  const steps = Children.toArray(_steps) as WizardStepElement[];
  const stepCount = steps.length;
  const activeStep = steps[activeStepIndex];
  const isLastStep = activeStepIndex === stepCount - 1;

  const setCompleteStep = useCallback(
    function setCompleteStep(index: number, value: boolean) {
      const newCompletedSteps = [...completedSteps];
      newCompletedSteps[index] = value;
      setCompletedSteps(newCompletedSteps);
    },
    [setCompletedSteps, completedSteps],
  );

  const next = useCallback(
    function next(newValues: Values) {
      setCompleteStep(activeStepIndex, true);
      setValues({
        ...values,
        ...newValues,
      });
      setActiveStepIndex(Math.min(activeStepIndex + 1, stepCount - 1));
    },
    [setValues, values, setActiveStepIndex, activeStepIndex, stepCount],
  );

  const previous = useCallback(
    function previous() {
      setActiveStepIndex(Math.max(activeStepIndex - 1, 0));
    },
    [setActiveStepIndex, activeStepIndex],
  );

  const validate = useCallback(
    function validate(values: Values) {
      const validate = (activeStep as any)?.props?.validate as
        | Validate
        | undefined;
      return validate ? validate(values) : {};
    },
    [activeStep],
  );

  const handleSubmit = useCallback(
    function handleSubmit(
      values: Values,
      formikActions: FormikActions<Values>,
    ) {
      if (isLastStep) {
        setCompleteStep(activeStepIndex, true);
        onSubmit(values, formikActions);
      } else {
        formikActions.setTouched({});
        formikActions.setSubmitting(false);
        next(values);
        onSubmitStep && onSubmitStep(values, formikActions);
      }
    },
    [setCompleteStep, activeStepIndex, onSubmit, isLastStep, next],
  );

  const { formikPropsRef } = useForwardToInitialStep({
    initialStep,
    activeStepIndex,
    initialValues,
  });

  const stepAttributes: StepAttributes[] = steps.map((step, index) => ({
    ...((step as any)?.props?.attributes as any),
    isActive: index === activeStepIndex,
    isCompleted: Boolean(completedSteps[index]),
  }));

  const activeStepAttributes = stepAttributes[activeStepIndex];

  return (
    <Formik
      initialValues={values}
      enableReinitialize={false}
      validate={validate}
      onSubmit={handleSubmit}
      render={formikProps => {
        formikPropsRef.current = formikProps;
        return (
          <WizardContext.Provider
            value={{
              activeStepIndex,
              activeStep,
              activeStepAttributes,
              isLastStep,
              formikProps,
              previous,
              steps,
              stepAttributes,
            }}
          >
            <form onSubmit={formikProps.handleSubmit}>{children}</form>
          </WizardContext.Provider>
        );
      }}
    />
  );
}

type WizardStepProps = {
  children: React.ReactNode;
  validate?: Validate;
  attributes?: any;
};

export function WizardStep({ children }: WizardStepProps) {
  return <>{children}</>;
}

export function useWizard() {
  return useContext(WizardContext);
}

import React, { useState, useRef, useEffect, useCallback } from 'react';
import _throttle from 'lodash.throttle';
import { Box } from 'rebass';

import Button from '~/components/common/Button';
import TeaserTile from '~/components/common/TeaserTile';
import {
  SortsSliderStyled,
  ScrollControls,
  ScrollContainer,
  ScrollChild,
} from './SortsSlider.styles';

type SortsProps = {
  items?: any[];
};

export default function SortsSlider({ items }: SortsProps) {
  const filteredItems = items ? items.filter(Boolean) : [];

  // Scroll information props.
  const scrollContainerRef = useRef<HTMLElement>(null!);
  const [canScrollMore, setCanScrollMore] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollChildWidth, setScrollChildWidth] = useState(0);

  /**
   * Set all necessary scroll information props.
   */
  const setScrollValues = () => {
    if (scrollContainerRef.current) {
      const scrollPos = scrollContainerRef.current.scrollLeft;
      const canScrollMore = Boolean(
        scrollContainerRef.current.scrollWidth -
          scrollContainerRef.current.offsetWidth >
          scrollPos,
      );

      setScrollChildWidth(scrollContainerRef.current.children[0].scrollWidth);
      setScrollPosition(scrollPos);
      setCanScrollMore(canScrollMore);
    }
  };

  /**
   * Effect:
   * Set scroll values if scroll in container occurs.
   */
  useEffect((): any => {
    let handleScroll = _throttle(() => setScrollValues(), 200);

    // @jan: is this okay here, can this be done more gracefully (haha, generally)? ~RS
    if (scrollChildWidth <= 0) {
      setScrollValues();
    }

    scrollContainerRef.current.addEventListener('scroll', handleScroll);

    return () => {
      handleScroll.cancel();
      scrollContainerRef.current.removeEventListener('scroll', handleScroll);
    };
  }, [scrollContainerRef]);

  // Callback: scroll to left
  const scrollLeft = useCallback(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        left: scrollPosition - scrollChildWidth,
        behavior: 'smooth',
      });
    }
  }, [scrollPosition, scrollChildWidth]);

  // Callback: scroll to right
  const scrollRight = useCallback(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        left: scrollPosition + scrollChildWidth,
        behavior: 'smooth',
      });
    }
  }, [scrollPosition, scrollChildWidth]);

  return (
    <SortsSliderStyled>
      <ScrollContainer mt={{ _: 4, sm: 4 }} pb={1} ref={scrollContainerRef}>
        {filteredItems.map(sort => {
          if (!sort) return null;

          return (
            <ScrollChild key={sort.id} pr={{ sm: 2 }}>
              <Box width={{ _: 1, sm: 330 }}>
                <TeaserTile {...sort} />
              </Box>
            </ScrollChild>
          );
        })}
      </ScrollContainer>
      <ScrollControls>
        <Button
          iconName="ArrowLeft"
          disabled={scrollPosition <= 0}
          onClick={scrollLeft}
          hoverStyle="moveLeft"
          mobileWidth="auto"
          iconPosition=""
        />
        <Button
          iconName="ArrowRight"
          disabled={canScrollMore === false}
          onClick={scrollRight}
          mobileWidth="auto"
          iconPosition=""
        />
      </ScrollControls>
    </SortsSliderStyled>
  );
}

import styled from '~/utils/styled';
import { Box, Flex } from 'rebass';
import { Input as _Input } from '@rebass/forms';
import media from '~/utils/mediaqueries';

export const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.background,
  color: theme.colors.headline,
  boxShadow: '0 3px 6px rgba(0, 0, 0, .1)',
  overflow: 'hidden',
  borderRadius: 5,
  letterSpacing: '-0.01em',
}));

export const Header = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.secondary,
  color: theme.colors.secondaryText,
  textAlign: 'center',
  fontWeight: 700,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  boxShadow: '0 3px 6px rgba(0, 0, 0, .1)',
}));

export const InfoTrigger = styled(Flex)(({ theme }) => ({
  border: 'none',
  WebkitAppearance: 'none',
  boxShadow: 'inset -6px 0 6px -6px rgba(0, 0, 0, .17)',
  borderRadius: '0 0 0 5px',
  '&:hover': {
    color: theme.colors.headline,
    boxShadow: 'inset -8px 0 8px -8px rgba(0, 0, 0, .2)',
  },
  '&:focus': {
    outline: 'none',
    boxShadow: `inset 0 0 0 1px ${theme.colors.dim}, inset -6px 0 6px -6px rgba(0, 0, 0, .17)`,
  },
}));

type InfoTransitionProps = {
  showInfo: boolean;
  height: number;
};

const infoMaxHeight = 155;
const infoMaxHeightDesktop = 230;

export const InfoTransition = styled(Box)<InfoTransitionProps>(
  ({ showInfo, height }) => ({
    WebkitOverflowScrolling: 'touch',
    height: showInfo ? Math.min(height, infoMaxHeight) : 0,
    overflow: showInfo && height > infoMaxHeight ? 'scroll' : 'hidden',
    maxHeight: infoMaxHeight,
    [media('lg')]: {
      height: showInfo ? Math.min(height, infoMaxHeightDesktop) : 0,
      overflow: showInfo && height > infoMaxHeightDesktop ? 'scroll' : 'hidden',
      maxHeight: infoMaxHeightDesktop,
    },
    boxShadow: showInfo
      ? 'inset 0 -10px 10px -10px rgba(0, 0, 0, .08)'
      : 'inset 0 -20px 20px -20px rgba(0, 0, 0, 0)',
    transition:
      'height 500ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 500ms ease-in-out',
  }),
);

type InfoContentProps = {
  showInfo: boolean;
};

export const InfoContent = styled(Box)<InfoContentProps>(({ showInfo }) => ({
  transform: showInfo ? 'translateX(0)' : 'translateX(20px)',
  opacity: showInfo ? 1 : 0,
  transition: showInfo
    ? 'transform 500ms cubic-bezier(0.4, 0, 0.2, 1) 250ms, opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 250ms'
    : 'transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 400ms, opacity 500ms cubic-bezier(0.4, 0, 0.2, 1)',
}));

export const Input = styled(_Input)(({ theme }) => ({
  borderStyle: 'none',
  textAlign: 'right',
  fontWeight: 700,
  borderRadius: '0 0 5px 0',
  color: theme.colors.text,
  '&:focus': {
    color: theme.colors.headline,
    boxShadow: `inset 0 0 0 1px ${theme.colors.dim}, inset 0 0 6px rgba(0, 0, 0, 0.1)`,
  },
}));

import styled from '~/utils/styled';
import { Box, Flex, Card } from 'rebass';

import CraftGatsbyImage from '~/components/craft/CraftGatsbyImage';

import BaseHeadline from '~/components/common/Headline';
import BaseLink from '~/components/common/Link';
import BaseButton from '~/components/common/Button';

export const TeaserTileStyled = styled(Card)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  backgroundColor: theme.colors.border,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  color: theme.colors.background,

  // Keeping aspect ratio
  '&::before': {
    display: 'block',
    content: '""',
    width: '100%',
    paddingTop: (10 / 7) * 100 + '%',
    background:
      'linear-gradient(to bottom,rgba(0,0,0,0) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0.7) 100%)',
  },

  '& > *': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
}));

export const Image = styled(CraftGatsbyImage)(({ theme }) => ({
  position: 'absolute !important' as any,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',

  // Keeping aspect ratio
  '&::after': {
    display: 'block',
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background:
      'linear-gradient(to bottom,rgba(0,0,0,0) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0.7) 100%)',
  },
}));

export const Headline = styled(BaseHeadline)({
  color: 'inherit',
});

export const Link = styled(BaseLink)({
  display: 'block',

  '&:hover, &:focus, &:active': {
    WebkitTapHighlightColor: 'transparent',
  },
});

export const ContentWrapper = styled(Flex)({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'flex-end',
});

export const ContentMain = styled('div')({
  // Animate appearance of Button on non-touch devices.
  '@media (hover: hover)': {
    transform: 'translate(0, 3em)',
    transition: 'transform .3s cubic-bezier(0.5, 0, 0.20, 1.20), opacity .3s',

    [`${Link}:hover &`]: {
      opacity: 0.4,
      transform: 'translateY(0)',
    },
  },
});

export const Button = styled(BaseButton)({
  alignSelf: 'flex-end',

  // Animate appearance of Button on non-touch devices.
  '@media (hover: hover)': {
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity .2s .1s, visibility 0s .3s',

    [`${Link}:hover &`]: {
      opacity: 1,
      visibility: 'visible',
      transition: 'opacity .2s .1s, visibility 0s .1s',
    },
  },
});

import React from 'react';
import { graphql } from 'gatsby';

import CraftComponents from '~/components/craft/CraftComponents';
import BlockContent from '~/components/common/BlockContent';
import { Craft_PageBuilderBlockContent } from '~/query';

/**
 * Available components.
 *
 * Defines available components
 * within this component.
 */
const availableComponents = {
  ElementHeadline: require('~/components/craft/CraftElementHeadline').default,
  ElementText: require('~/components/craft/CraftElementText').default,
  ElementImage: require('~/components/craft/CraftElementImage').default,
  ElementButtonGroup: require('~/components/craft/CraftElementButtonGroup')
    .default,
  ElementContactInfo: require('~/components/craft/CraftElementContactInfo')
    .default,
  ElementIconList: require('~/components/craft/CraftElementIconList').default,
  ElementDownloads: require('~/components/craft/CraftElementDownloads').default,
  ElementFormContact: require('~/components/craft/CraftElementFormContact')
    .default,
  ElementVideo: require('~/components/craft/CraftElementVideo').default,
  ElementGallery: require('~/components/craft/CraftElementGallery').default,
  ElementAnchor: require('~/components/craft/CraftElementAnchor').default,
  ElementDiscount: require('~/components/craft/CraftElementDiscount').default,
  ElementList: require('~/components/craft/CraftElementList').default,
};

type CraftBlockContentProps = {
  data: Craft_PageBuilderBlockContent;
};

export default function CraftBlockContent({ data }: CraftBlockContentProps) {
  const { children, backgroundColor, py, pb } = data;

  return (
    <BlockContent py={py} pb={pb} backgroundColor={backgroundColor?.hex}>
      <CraftComponents
        components={children}
        availableComponents={availableComponents}
        prefix={/Craft_PageBuilder(Blog)?/}
      />
    </BlockContent>
  );
}

export const query = graphql`
  fragment CraftPageBuilderBlogBlockContentQuery on Craft_PageBuilderBlogBlockContent {
    id
    # Content
    children {
      __typename
      # Content: Elements
      ...CraftPageBuilderBlogElementHeadlineQuery
      ...CraftPageBuilderBlogElementTextQuery
      ...CraftPageBuilderBlogElementImageQuery
      ...CraftPageBuilderBlogElementGalleryQuery
      ...CraftPageBuilderBlogElementVideoQuery
    }
  }
  fragment CraftPageBuilderBlockContentQuery on Craft_PageBuilderBlockContent {
    id
    # Content
    children {
      __typename
      # Content: Elements
      ...CraftPageBuilderElementImageQuery
      ...CraftPageBuilderElementHeadlineQuery
      ...CraftPageBuilderElementTextQuery
      ...CraftPageBuilderElementButtonGroupQuery
      ...CraftPageBuilderElementIconListQuery
      ...CraftPageBuilderElementContactInfoQuery
      ...CraftPageBuilderElementDownloadsQuery
      ...CraftPageBuilderElementFormContactQuery
      ...CraftPageBuilderElementAnchorQuery
      ...CraftPageBuilderElementDiscountQuery
      ...CraftPageBuilderElementListQuery
    }

    py
    pb
    backgroundColor {
      hex
    }
  }
`;

import React from 'react';
import { Flex, Box } from 'rebass';

import BlockContent from '~/components/common/BlockContent';
import CraftGatsbyImage from '~/components/craft/CraftGatsbyImage';
import CraftComponents from '~/components/craft/CraftComponents';

type BlockLogosProps = {
  children?: any;
  variant?: any;
  logos?: any;
  prefix?: any;
};

export default function BlockLogos({
  children,
  variant,
  logos,
}: BlockLogosProps) {
  // Variants are shown here https://cms.zmart24.de/admin/settings/fields/edit/127
  // Stay in sync!!! ~DH
  const filteredLogos = logos ? logos.filter(Boolean) : [];

  const availableComponentsVariantOne = {
    ElementHeadline: require('~/components/craft/CraftElementHeadline').default,
  };

  switch (
    variant // variantOne: Darstellung Eins (Iconleiste)
  ) {
    case 'variantZero':
      return (
        <BlockContent py="small">
          <CraftComponents
            components={children}
            availableComponents={availableComponentsVariantOne}
            prefix="Craft_PageBuilder"
          />
          <Flex
            justifyContent="space-evenly"
            alignItems="center"
            flexWrap="wrap"
          >
            {filteredLogos.map((logo: any) => (
              <Box
                key={logo.id}
                px={{ _: '10vw', sm: 3, md: 4, lg: 1, xl: 3, xxl: 3 }} // otherwise it gets squashed on mobile, cant rely on var size sets only ~DH
                py={{ _: 2, sm: 2, md: 1, lg: 2, xl: 1, xxl: 1 }}
                width={{
                  _: 1 / Math.min(filteredLogos.length, 1),
                  sm: 1 / Math.min(filteredLogos.length, 1),
                  md: 1 / Math.min(filteredLogos.length, 2),
                  lg: 1 / Math.min(filteredLogos.length, 4),
                  xl: 1 / Math.min(filteredLogos.length, 4),
                  xxl: 1 / Math.min(filteredLogos.length, 4),
                }}
              >
                <CraftGatsbyImage fluid={logo.logoImage[0]} />
              </Box>
            ))}
          </Flex>
        </BlockContent>
      );
    // variantOne: Darstellung Eins (Iconleiste)
    case 'variantOne':
    default:
      return (
        <BlockContent py="small">
          <CraftComponents
            components={children}
            availableComponents={availableComponentsVariantOne}
            prefix="Craft_PageBuilder"
          />
          <Flex
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
          >
            {filteredLogos.map((logo: any) => (
              <Box
                key={logo.id}
                px={{ _: '10vw', sm: 3, md: 4, lg: 1, xl: 3, xxl: 3 }} // otherwise it gets squashed on mobile, cant rely on var size sets only ~DH
                py={{ _: 2, sm: 2, md: 1, lg: 2, xl: 1, xxl: 1 }}
                width={{
                  _: 1 / Math.min(filteredLogos.length, 2),
                  sm: 1 / Math.min(filteredLogos.length, 3),
                  md: 1 / Math.min(filteredLogos.length, 3),
                  lg: 1 / Math.min(filteredLogos.length, 6),
                  xl: 1 / Math.min(filteredLogos.length, 6),
                  xxl: 1 / Math.min(filteredLogos.length, 6),
                }}
              >
                <CraftGatsbyImage fluid={logo.logoImage[0]} />
              </Box>
            ))}
          </Flex>
        </BlockContent>
      );

    // variantTwo: Darstellung Zwei (Text & Icons)
    case 'variantTwo':
      const availableComponentsVariantTwo = {
        ElementHeadline: require('~/components/craft/CraftElementHeadline')
          .default,
        ElementText: require('~/components/craft/CraftElementText').default,
      };

      return (
        <BlockContent py="large">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
          >
            <Box
              width={{ _: 1, md: 4 / 7 }}
              pr={{ _: 0, sm: 0, md: 2, lg: 3, xl: 3, xxl: 3 }}
            >
              <CraftComponents
                components={children}
                availableComponents={availableComponentsVariantTwo}
                prefix="Craft_PageBuilder"
              />
            </Box>
            <Box width={{ _: 1, md: 3 / 7 }}>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
              >
                {filteredLogos.map((logo: any) => (
                  <Box
                    key={logo.id}
                    px={{ _: '10vw', sm: 2, md: 2, lg: 1, xl: 2, xxl: 2 }}
                    py={{ _: 2, sm: 2, md: 1, lg: 2, xl: 1, xxl: 1 }}
                    width={{
                      _: 1 / 2,
                      sm: 1 / 3,
                      md: 1 / 2,
                      lg: 1 / 3,
                      xl: 1 / 3,
                      xxl: 1 / 3,
                    }}
                  >
                    <CraftGatsbyImage fluid={logo.logoImage[0]} />
                  </Box>
                ))}
              </Flex>
            </Box>
          </Flex>
        </BlockContent>
      );

    // variantThree: Darstellung Drei (Text & Große Icons)
    case 'variantThree':
      const availableComponentsVariantThree = {
        ElementHeadline: require('~/components/craft/CraftElementHeadline')
          .default,
      };

      return (
        <BlockContent py="large">
          <CraftComponents
            components={children}
            availableComponents={availableComponentsVariantThree}
            prefix="Craft_PageBuilder"
          />
          <Flex
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
          >
            {filteredLogos.map((logo: any) => (
              <Box
                key={logo.id}
                px={{ _: '10vw', sm: 3, md: 4, lg: 5, xl: 5, xxl: 5 }} // otherwise it gets squashed on mobile, cant rely on var size sets only ~DH
                py={{ _: 2, sm: 2, md: 1, lg: 2, xl: 2, xxl: 3 }}
                width={{
                  _: 1 / 2,
                  sm: 1 / 3,
                  md: 1 / 3,
                  lg: 1 / 3,
                  xl: 1 / 3,
                  xxl: 1 / 3,
                }}
              >
                <CraftGatsbyImage fluid={logo.logoImage[0]} />
              </Box>
            ))}
          </Flex>
        </BlockContent>
      );
  }
}

import React, { useCallback } from 'react';

import { Flex, Box } from 'rebass';
import { CalculatorMaterialSectionTableStyled } from './CalculatorMaterialSectionTable.styles';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
import media from '~/utils/mediaqueries';
import Accordion, { AccordionItem } from '~/components/common/Accordion';
import Icon from '~/components/common/Icon';
import { IconNames } from '~/components/common/Icon/Icon';
import { Field } from 'formik';
import { Input } from '@rebass/forms';
import Button from '~/components/common/Button';
import Parser from '~/components/common/Parser';
import throttle from 'lodash.throttle';
import CalculatorContractStartInput from '../CalculatorContractStartInput';

type CalculatorMaterialSectionTableProps = {
  materials: any[];
  contractYears: string[];
};

const selectOnFocus = (event: any) => {
  const target = event.target;

  if (typeof window !== 'undefined') {
    window.requestAnimationFrame(() => {
      try {
        // @ts-ignore
        target.setSelectionRange(0, 9e3);
      } catch (o_O) {
        target.select();
      }
    });
  }
};

export const submitFormThrottled = throttle(
  (form: any) => requestAnimationFrame(() => form.submitForm()),
  666,
);

export default function CalculatorMaterialSectionTable({
  materials,
  contractYears,
}: CalculatorMaterialSectionTableProps) {
  const t = useCraftTranslations();

  return (
    <CalculatorMaterialSectionTableStyled
      pr={{ _: 0, xxl: 3 }}
      mb={2}
      mt={{ _: 2, xl: '30px' }}
    >
      <Box
        sx={{
          borderRadius: 5,
          overflow: 'hidden',
        }}
      >
        <Flex
          sx={{
            bg: 'faded',
            color: 'text',
            px: 1,
            py: '10px',
          }}
          alignItems={{ _: 'flex-start', md: 'center' }}
          justifyContent="space-between"
          flexDirection={{ _: 'column', md: 'row' }}
        >
          <Box
            sx={{
              color: 'text',
              textTransform: 'uppercase',
              fontWeight: 700,
            }}
            mb={{ _: '10px', md: 0 }}
          >
            {t('Choose contract start year:')}
          </Box>
          <Box
            sx={{
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
              bg: 'background',
              borderRadius: 5,
              overflow: 'hidden',
              p: '10px',
              minWidth: '100%',
              [media('md')]: {
                minWidth: '220px',
              },
            }}
          >
            <CalculatorContractStartInput
              contractYears={contractYears}
              label=""
              css={{
                paddingTop: '0 !important',
                paddingBottom: '0 !important',
              }}
              radioVariant="pills"
            />
          </Box>
        </Flex>
      </Box>
      <Accordion>
        {materials.map(material => (
          <AccordionItem
            key={material.id}
            name={material.title}
            renderItem={({ content, control, isOpened }) => (
              <Box
                mt={2}
                sx={{
                  borderStyle: 'solid',
                  borderWidth: 1,
                  borderColor: 'border',
                  borderRadius: 5,
                  overflow: 'hidden',
                  boxShadow: isOpened
                    ? '0px 2px 8px rgba(0, 0, 0, 0.1)'
                    : '0px 0px 3px rgba(0, 0, 0, 0)',
                  transition:
                    'box-shadow 600ms cubic-bezier(0.6, 0.4, 0.2, 0.8)',
                }}
              >
                {control}
                {content}
              </Box>
            )}
            renderControl={({ handleControlClick, isOpened }) => (
              <Flex
                sx={{
                  bg: 'tertiaryBright',
                  color: 'tertiaryBrightText',
                  px: 1,
                  py: '10px',
                }}
                alignItems={{ _: 'stretch', md: 'center' }}
                justifyContent="space-between"
                flexDirection={{ _: 'column', md: 'row' }}
              >
                <Flex
                  alignItems="center"
                  sx={{
                    color: 'moreDim',
                    maxWidth: '100%',
                    [media('md')]: {
                      maxWidth: '55%',
                    },
                  }}
                  mb={{ _: '10px', md: 0 }}
                  justifyContent="space-between"
                >
                  <Box
                    sx={{
                      mr: '1ch',
                      color: 'headline',
                      textTransform: 'uppercase',
                      fontWeight: 700,
                    }}
                  >
                    <Parser html={material.title} />
                  </Box>
                  <Button
                    iconName="Info"
                    onClick={handleControlClick}
                    iconPosition=""
                    hoverStyle="none"
                    variant="toggleInherit"
                  />
                </Flex>
                <Box
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Field
                    name={`materials.${material.identifier}`}
                    render={({ field, form }: any) => {
                      return (
                        <Input
                          {...field}
                          sx={{
                            textAlign: 'right',
                            fontWeight: 700,
                            bg: 'background',
                            color: 'headline',
                            width: '100%',
                            [media('md')]: {
                              width: '9em',
                            },
                          }}
                          id={material.identifier}
                          pr={'calc(17px + 2ch)'}
                          type="number"
                          min={0}
                          onFocus={selectOnFocus}
                          onChange={(e: any) => {
                            form.setFieldValue(
                              `materials.${material.identifier}`,
                              Math.max(0, parseFloat(e.target.value)) || 0,
                            );
                            submitFormThrottled(form);
                          }}
                        />
                      );
                    }}
                  />
                  <Box
                    css={{
                      position: 'absolute',
                      right: '1em',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  >
                    {t('kg')}
                  </Box>
                </Box>
              </Flex>
            )}
          >
            {({ handleControlClick, isOpened }) => (
              <Box p={2} fontSize={2}>
                {material.infoText}
                {material.examples
                  ? material.examples.map(
                      ({ iconName, title }: any, index: number) =>
                        iconName ? (
                          <Flex
                            key={index}
                            pt={1}
                            alignItems="center"
                            fontSize={5}
                          >
                            <Box
                              fontSize={2}
                              css={{
                                visibility: index !== 0 ? 'hidden' : undefined,
                              }}
                              pr={1}
                              flex="0 0 20%"
                            >
                              {t('e.g.')}
                            </Box>
                            <Icon name={iconName as IconNames} />
                            <Box pl={1} fontSize={2} flex="0 0 56%">
                              {title}
                            </Box>
                          </Flex>
                        ) : null,
                    )
                  : null}
                <Box pt={2}>
                  <Button
                    variant="toggleInherit"
                    iconName="ChevronUp"
                    iconPosition="before"
                    hoverStyle="none"
                    onClick={handleControlClick}
                    disabled={!isOpened}
                  >
                    {t('Hide information')}
                  </Button>
                </Box>
              </Box>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </CalculatorMaterialSectionTableStyled>
  );
}

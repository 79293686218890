import { Box } from 'rebass';
import styled from '~/utils/styled';
import media from '~/utils/mediaqueries';
import { sizes } from '~/themes/baseTheme';
import Video from '~/components/common/Video';

interface VideoWrapperProps {
  sticky?: boolean | any;
}

export const VideoWrapper = styled(Box)<VideoWrapperProps>(
  {
    overflow: 'hidden',

    // [media('sm')]: {
    //   alignSelf: 'stretch',
    // },

    [media('lg')]: {
      order: 1,
    },
  },
  ({ sticky }) =>
    sticky && {
      [media('lg')]: {
        position: 'sticky',
        // height: '100vh',
        top: 0,
        // top: sizes.header.height.lg,
        // marginTop: sizes.header.height.lg,
      },
    },
);

interface ContentWrapperProps {
  flip?: boolean;
}

export const ContentWrapper = styled(Box)<ContentWrapperProps>(
  {
    order: 2,
  },
  ({ flip }) =>
    // Adjust order when teaser is flipped
    flip && {
      [media('lg')]: {
        order: -1,
      },
    },
);

import styled from '~/utils/styled';

export const Svg = styled('svg')({
  width: '1em',
  height: '1em',
  fill: 'currentColor',
});

Svg.defaultProps = {
  xmlns: 'http://www.w3.org/2000/svg',
};

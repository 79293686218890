import { loadExternalScript } from '../hooks/useExternalScriptEffect';
import {
  useState,
  useEffect,
  useRef,
  MutableRefObject,
  useCallback,
} from 'react';

const PAYONE_API_HOSTED_IFRAMES =
  'https://secure.pay1.de/client-api/js/v1/payone_hosted_min.js';

type IframeLanguage = 'de' | 'en';

type InputRefs = {
  cardpan: MutableRefObject<HTMLSpanElement | null>;
  cardcvc2: MutableRefObject<HTMLSpanElement | null>;
  cardexpiremonth: MutableRefObject<HTMLSpanElement | null>;
  cardexpireyear: MutableRefObject<HTMLSpanElement | null>;
};

type InputOpts = {
  cardpan: { [key: string]: any };
  cardcvc2: { [key: string]: any };
  cardexpiremonth: { [key: string]: any };
  cardexpireyear: { [key: string]: any };
};

export type PayoneCreditCardResponse = {
  status: null | string;
  pseudocardpan: null | string;
  truncatedcardpan: null | string;
  cardtype: null | string;
  cardexpiredate: null | string;
  errorcode: null | string;
  errormessage: null | string;
};

async function getIframes({
  inputRefs,
  inputOpts,
  handleDetectCardtype,
  handleRendered,
  language,
}: {
  inputRefs: InputRefs;
  inputOpts: InputOpts;
  handleDetectCardtype: (detectedCardtype: {
    key: string;
    string: string;
  }) => void;
  handleRendered: () => void;
  language: IframeLanguage;
}) {
  await loadExternalScript(PAYONE_API_HOSTED_IFRAMES);
  let api = (<any>window)?.Payone?.ClientApi;

  if (!api) return;
  // TODO: Error handling

  const supportedCardtypes = ['#', 'V', 'M'];

  const fields = Object.entries(inputRefs)
    .map(([key, inputRef]) => {
      if (inputRef.current)
        return {
          key,
          element: inputRef.current,
          ...inputRef.current.dataset,
          //@ts-ignore
          ...inputOpts[key],
        };
    })
    .reduce(
      (fields, field) => (field ? { ...fields, [field.key]: field } : fields),
      {},
    );

  const config = {
    fields,
    defaultStyle: {
      input: '',
      iframe: {
        height: '47px',
        width: '100%',
      },
    },

    autoCardtypeDetection: {
      supportedCardtypes: supportedCardtypes,
      callback: (cardtype: string) => {
        handleDetectCardtype({
          key: cardtype,
          string: api.CardTypes[cardtype],
        });
      },
    },

    events: {
      rendered: handleRendered,
    },

    language: api.Language[language],
    // error: 'error',
  };

  const request = {
    aid: '43341', // your AID
    encoding: 'UTF-8', // desired encoding
    mid: '42764', // your MID
    mode: 'live', // desired mode
    portalid: '2030884', // your PortalId
    request: 'creditcardcheck', // fixed value
    responsetype: 'JSON', // fixed value
    storecarddata: 'yes', // fixed value
    // hash calculated over your request-parameter-values (alphabetical request-order) plus PMI portal key
    // hash: '1cf456bf692453613ebb992a3fb859cc347ddc7e94e2ca764efbe8b0089de6964ab1266df0831e59de89dc5291070fe7'
    hash: '0ebaf8559595320bded124e62c2ea327', // see Chapter 3.1.5.3
  };

  return new api.HostedIFrames(config, request);
}

function ensureIds(inputRefs: InputRefs) {
  Object.entries(inputRefs).forEach(([key, inputRef]) => {
    if (!inputRef.current) {
      throw new Error(
        `Payone hosted iframes: No element found for input-placeholder "${key}".`,
      );
    }
  });
}

export function useCreditCardIframes({
  language,
  shouldLoad,
  inputOpts,
}: {
  language: IframeLanguage;
  shouldLoad?: boolean;
  inputOpts: InputOpts;
}) {
  const inputRefs = {
    cardpan: useRef<HTMLSpanElement>(null),
    cardcvc2: useRef<HTMLSpanElement>(null),
    cardexpiremonth: useRef<HTMLSpanElement>(null),
    cardexpireyear: useRef<HTMLSpanElement>(null),
  };

  const [iframes, setIframes] = useState();
  const [iframesLoading, setIframesLoading] = useState(false);

  const [cardtype, setCardtype] = useState<{ key: string; string: string }>();

  const handleDetectCardtype = useCallback(
    (detectedCardtype: { key: string; string: string }) => {
      setCardtype(detectedCardtype);
    },
    [setCardtype],
  );

  useEffect(() => {
    if (!shouldLoad) return;
    ensureIds(inputRefs);

    let didCancel = false;

    (async () => {
      try {
        setIframesLoading(true);
        let iframesRes;
        await new Promise(async resolve => {
          iframesRes = await getIframes({
            inputRefs,
            inputOpts,
            handleDetectCardtype,
            handleRendered: resolve,
            language,
          });
        });
        if (!didCancel && iframesRes) {
          setIframes(iframesRes);
        }
      } catch (o_O) {
        // noop
      } finally {
        setIframesLoading(false);
      }
    })();

    return () => {
      didCancel = true;
      setIframesLoading(false);
    };
  }, [
    shouldLoad,
    setIframesLoading,
    setIframes,
    handleDetectCardtype,
    inputOpts,
    inputRefs.cardpan,
    inputRefs.cardcvc2,
    inputRefs.cardexpiremonth,
    inputRefs.cardexpireyear,
  ]);

  const creditCardCheck = useCallback(async () => {
    if (!iframes) return;
    if (iframes.isComplete()) {
      return new Promise(resolve => {
        (<any>window).payoneHostedIframesCallback = resolve;
        iframes.creditCardCheck('payoneHostedIframesCallback');
      }) as Promise<PayoneCreditCardResponse | undefined>;
    }
  }, [iframes]);

  return { inputRefs, iframes, iframesLoading, cardtype, creditCardCheck };
}

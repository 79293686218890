import React from 'react';
import { graphql } from 'gatsby';
import Parser from '~/components/common/Parser';
import { Craft_PageBuilderElementDownloads } from '~/query';
import Downloads from '~/components/common/Downloads';

type CraftElementDownloadsProps = {
  data?: Craft_PageBuilderElementDownloads;
};

export default function CraftElementDownloads({
  data,
}: CraftElementDownloadsProps) {
  if (!data) return null;

  const filesRaw = (data?.downloadsFiles ?? []) as any;
  const files = filesRaw.map(({ id, title, altText, url, size }: any) => ({
    id: id || undefined,
    title: title || undefined,
    altText: <Parser html={altText} />,
    url: url || undefined,
    size: size || undefined,
  }));

  return <Downloads items={files} />;
}

export const query = graphql`
  # Fragment for usage in PageBuilder
  fragment CraftPageBuilderElementDownloadsQuery on Craft_PageBuilderElementDownloads {
    id
    downloadsFiles {
      id
      title
      url
      size

      ... on Craft_FilesVolume {
        altText
      }
    }
  }
`;

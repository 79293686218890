import React, { lazy, Suspense } from 'react';
import { Box } from 'rebass';
import { useCraftGlobals } from '~/utils/hooks/useCraftGlobals';
import Segment from '../Segment';

const isSSR = typeof window === 'undefined';

type TreeCampaignTeaserBoxProps = {
  text: React.ReactNode;
  title: string;
};

export default function TreeCampaignTeaserBoxCraft({
  text,
  title,
}: TreeCampaignTeaserBoxProps) {
  const TreeCampaignTeaserBox = lazy(() =>
    import('~/components/common/TreeCampaignTeaserBox'),
  );

  const { treeCampaign } = useCraftGlobals();
  return !isSSR && treeCampaign && treeCampaign.treeCampaignIsActive ? (
    <Suspense fallback={<div />}>
      <TreeCampaignTeaserBox
        css={{ marginLeft: 'auto' }}
        title={title ?? ''}
        text={text}
        treeCountLabel={treeCampaign.treeCampaignTreeCountLabel ?? undefined}
        co2CountLabel={treeCampaign.treeCampaignCo2CountLabel ?? undefined}
        calcOptions={{
          maxTrees: treeCampaign.treeCampaignMaxTrees ?? 1,
          co2PerTree: treeCampaign.treeCampaignCo2PerTree ?? 1,
          startDate: new Date((treeCampaign.treeCampaignStartDate ?? 1) * 1e3),
          endDate: new Date((treeCampaign.treeCampaignEndDate ?? 1) * 1e3),
          incrementDays: treeCampaign.treeCampaignIncrementDays
            ? treeCampaign.treeCampaignIncrementDays.map(day =>
                parseInt((day as string).replace('_', ''), 10),
              )
            : [],
          incrementHoursFrom: treeCampaign.treeCampaignIncrementHoursFrom ?? 1,
          incrementHoursTo: treeCampaign.treeCampaignIncrementHoursTo ?? 1,
        }}
      />
    </Suspense>
  ) : null;
}

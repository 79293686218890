import React from 'react';
import { connect } from 'formik';
import FormRadio from '~/components/common/FormRadio/FormRadio';
import { FormFieldRow } from '~/components/common/FormField';

type CalculatorContractStartInputProps = {
  contractYears: string[];
} & Omit<
  React.ComponentProps<typeof FormRadio>,
  'options' | 'onChange' | 'name'
>;

export default connect<
  CalculatorContractStartInputProps,
  { startYear: string; startDate: string }
>(function CalculatorContractStartInput({ formik, contractYears, ...rest }) {
  return (
    <FormFieldRow>
      <FormRadio
        name="startYear"
        options={contractYears.map((year: string) => ({
          label: year,
          value: year,
        }))}
        onChange={() => {
          // this is a hack to get submitForm to work
          // hehe, spreading the hack ~RS
          setTimeout(() => {
            formik.setFieldValue('startDate', '', true);
            formik.submitForm();
          }, 0);
        }}
        hideOptionalIndicator
        {...rest}
      />
    </FormFieldRow>
  );
});

import React, { useState, useEffect } from 'react';
import { Box } from 'rebass';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
import Button from '~/components/common/Button';
import CalculatorHintBox from '../CalculatorHintBox';
import CalculatorPricingBox from '../CalculatorPricingBox';
import CalculatorVoucherInput from '../CalculatorVoucherInput';
import CalculatorContractStartInput from '../CalculatorContractStartInput';
import CalculatorContractStartDateInput from '../CalculatorContractStartDateInput';
import { ShowDesktop, ShowMobile } from '../CalculatorMediaUtils';
import CraftGlobalTrustedShops from '~/components/craft/CraftGlobalTrustedShops';
import InfoBox from '~/components/common/InfoBox';
import { useCraftGlobals } from '~/utils/hooks/useCraftGlobals';

type QuoteProps = {
  limit?: number;
  title?: string;
  message?: React.ReactNode;
  link?: any;
};

type CalculatorBlockPricingProps = {
  contractYears?: string[];
  contractYearsStartDateRequired: { [year: string]: boolean };
  price?: number;
  discount?: number;
  priceLoading: boolean;
  priceError?: any;
  quote?: QuoteProps;
  couponCodeError?: string;
  legalAndPaymentMethods?: React.ReactNode;
  onLicenseButtonClick: React.MouseEventHandler;
  calculateError?: string;
};

export default function CalculatorBlockPricing({
  contractYears,
  contractYearsStartDateRequired,
  price,
  quote,
  discount,
  priceLoading,
  couponCodeError,
  onLicenseButtonClick,
  legalAndPaymentMethods,
  calculateError,
}: CalculatorBlockPricingProps) {
  const t = useCraftTranslations();
  const { globalSiteSettings } = useCraftGlobals();
  const canChooseStart = price && price > 0;
  const canLicense = canChooseStart && !calculateError && !couponCodeError;

  // Show quote hint if price is high enough.
  // Until the threshold is hit again, respect the user hiding the box.
  const { limit, message, title, link } = quote as QuoteProps;
  const quoteLimitExceeded = limit && price && price >= limit;
  const [isQuoteHintOpen, setQuoteHintOpen] = useState(false);

  useEffect(() => {
    if (quoteLimitExceeded === true) {
      setQuoteHintOpen(true);
    } else {
      setQuoteHintOpen(false);
    }
  }, [quoteLimitExceeded]);

  if (isQuoteHintOpen) {
    return (
      <CalculatorHintBox
        title={title ? title : t('Note regarding your calculation')}
      >
        {message}

        {link && link.url && link.text && (
          <Box mb={1}>
            <Button
              width="100%"
              type="button"
              textTransform="uppercase"
              to={link.url}
              target={link.target ? link.target : undefined}
            >
              {link.text}
            </Button>
          </Box>
        )}
        <Button
          width="100%"
          variant="blankGrey"
          type="button"
          textTransform="uppercase"
          iconName="none"
          onClick={() => setQuoteHintOpen(false)}
        >
          {t('Close hint')}
        </Button>
      </CalculatorHintBox>
    );
  }

  return (
    <>
      <CalculatorPricingBox
        priceLoading={priceLoading}
        price={price}
        discount={discount}
        id="calculator-pricing-box"
      >
        <ShowMobile>
          {globalSiteSettings?.forcedVoucherCode ? null : (
            <CalculatorVoucherInput error={couponCodeError} />
          )}
          {contractYears ? (
            <CalculatorContractStartInput contractYears={contractYears} />
          ) : null}
          <CalculatorContractStartDateInput
            contractYearsStartDateRequired={contractYearsStartDateRequired}
            error={calculateError}
          />

          <Box mt={1}>
            <Button
              variant="filled"
              width="100%"
              disabled={!canLicense}
              textTransform="uppercase"
              onClick={onLicenseButtonClick}
            >
              {t('License now')}
            </Button>
          </Box>
        </ShowMobile>
        <ShowDesktop>
          {globalSiteSettings?.forcedVoucherCode ? (
            <div style={{ marginTop: '-25px' }} />
          ) : (
            <CalculatorVoucherInput error={couponCodeError} />
          )}
        </ShowDesktop>
      </CalculatorPricingBox>

      <ShowDesktop>
        {contractYears ? (
          <CalculatorContractStartInput contractYears={contractYears} />
        ) : null}
        <CalculatorContractStartDateInput
          contractYearsStartDateRequired={contractYearsStartDateRequired}
          error={calculateError}
        />

        <Box mt={2}>
          <Button
            variant="filled"
            width="100%"
            type="button"
            disabled={!canLicense}
            textTransform="uppercase"
            onClick={onLicenseButtonClick}
          >
            {t('License now')}
          </Button>
        </Box>

        {legalAndPaymentMethods}

        <Box maxWidth={140} mt={2}>
          <CraftGlobalTrustedShops />
        </Box>
      </ShowDesktop>
    </>
  );
}

import styled from '~/utils/styled';
import { Box } from 'rebass';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const SliderWrapper = styled(Box)(({theme}) => ({
  overflow: "hidden",

  '.slick-slider': {
    '.slick-dots': {
      zIndex: 1,
      bottom: 10
    },

    '.slick-arrow': {
      zIndex: 1,
      height: 55,

      '&:before': {
        display: 'none'
      }
    },

    '.slick-next': {
      right: 25
    },

    '.slick-prev': {
      left: 25
    },

    '.slick-dots li button': {
      padding: 20,

      '&:before' : {
        fontSize: 8,
        color: theme.colors.secondaryBright
      }
    },
  }
}),
props =>
  props.height === 'full' && {
    position: 'fixed',
    zIndex: 100000,
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",

    '&::before': {
      content: '" "',
      position: 'absolute',
      zIndex: 0,
      top: 0,
      left: 0,
      width: "100vw",
      height: "calc(200vh)",
      background: 'rgba(255,255,255,0.9)',
    }
  },
);

export const SlideWrapper = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    position: 'relative',
    overflow: 'hidden',
    zIndex: 0,
  }),
  props =>
    props.height === 'full' && {
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100vh",

      '.gatsby-image-wrapper img': {
        marginTop: 25,
        maxHeight: 'calc(100vh - 70px)'
      }
    },
);

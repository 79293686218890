import React from 'react';

/**
 * Get component.
 *
 * @param {string} __typename
 * @param {object} availableComponents
 * @param {any} prefix
 */
function getComponent(__typename, availableComponents, prefix) {
  const componentName = __typename.replace(prefix, '');
  return availableComponents[componentName] || false;
}

/**
 * Get components.
 *
 * @param {array} components
 * @param {object} availableComponents
 * @param {any} prefix
 */
export function getComponents(components, availableComponents, prefix) {
  if (!components) return [];
  return components.map((el, index) => {
    // If there is no id, we won't render anything.
    if (!el || !el.id) return null;

    const CraftComponent = getComponent(
      el.__typename,
      availableComponents,
      prefix,
    );
    const key = `${el.id}-${index}`;

    return CraftComponent ? <CraftComponent data={el} key={key} /> : null;
  });
}

/**
 * Render craft components.
 * Renders predefined available components dynamically.
 */
export default function CraftComponents({
  components,
  availableComponents,
  prefix,
}) {
  return <>{getComponents(components, availableComponents, prefix)}</>;
}

import React from 'react';
import { graphql } from 'gatsby';
import CraftPageBuilder from '~/components/craft/CraftPageBuilder';
import Accordion, {
  AccordionItem,
} from '~/components/common/AccordionReadMoreList';
import PageBuilderContext from '~/components/common/PageBuilderContext';
import slugify from '~/utils/slugify';
import Parser from '~/components/common/Parser';

type CraftBlockReadMoreListProps = {
  data: any;
};

export default function CraftBlockReadMoreList({
  data,
}: CraftBlockReadMoreListProps) {
  const { readMoreListItems } = data;

  const items = readMoreListItems
    ? readMoreListItems
        .map((item: any) => {
          if (!item) return undefined;

          return {
            id: item.id ?? undefined,
            title: item.title ?? undefined,
            teaserText: item.accordionContentTeasertext ? (
              <Parser html={item.accordionContentTeasertext.content} />
            ) : (
              undefined
            ),
            teaserImage: item.accordionContentTeaserimage?.[0] ?? undefined,
            pageBuilder: item.pageBuilder ?? undefined,
          };
        })
        .filter(Boolean)
    : [];

  return (
    <Accordion
      initialOpenedItems={() => {
        if (typeof window !== 'undefined') {
          const hash = window.location.hash.replace(/^#/, '');
          return { [hash]: true };
        }
        return {};
      }}
    >
      {items.map((item: any) => (
        <AccordionItem
          key={item.id}
          name={slugify(item.title)}
          title={item.title}
          teaserImage={item.teaserImage}
          teaserText={item.teaserText}
        >
          <PageBuilderContext.Provider
            value={{ defaultSegmentTheme: 'faded' }}
          >
            <CraftPageBuilder components={item.pageBuilder} />
          </PageBuilderContext.Provider>
        </AccordionItem>
      ))}
    </Accordion>
  );
}

export const query = graphql`
  fragment CraftPageBuilderBlockReadMoreListQuery on Craft_PageBuilderBlockReadMoreList {
    id

    # Items
    readMoreListItems {
      __typename
      ... on Craft_AccordionContent {
        id
        title
        accordionContentTeasertext {
          content
        }
        accordionContentTeaserimage {
          ...CraftImageFluid
        }
        pageBuilder {
          ...CraftPageBuilderQuery_NoNesting
        }
      }
    }
  }
`;

import React from 'react';
import { Flex } from 'rebass';

import {
  DiscountCode,
  DiscountLabel,
  DiscountInputFake,
  DiscountButton,
} from './Discount.styles';

type DiscountProps = {
  discountInputText?: any;
  discountCalculatorLink?: any;
  discountCode?: any;
  discountLinkText?: any;
};

export default function Discount({
  discountInputText,
  discountCode,
  discountCalculatorLink,
  discountLinkText,
}: DiscountProps) {
  return (
    <Flex flexWrap="wrap" alignItems="center">
      <DiscountInputFake alignItems="center" px={1} width={{ _: 1, lg: 1 / 2 }}>
        <DiscountLabel>{discountInputText} </DiscountLabel>
        <DiscountCode>{discountCode}</DiscountCode>
      </DiscountInputFake>
      <DiscountButton
        to={`${discountCalculatorLink?.[0]?.fullUri}/?code=${discountCode}`}
        variant="filled"
        iconPosition="after"
      >
        {discountLinkText}
      </DiscountButton>
    </Flex>
  );
}

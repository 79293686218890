import React, { useState } from 'react';
import ElevatorStep from './ElevatorStep';

import {
  Elevator,
  Arrows,
  ArrowBefore,
  ArrowNext,
  ArrowBox,
} from './Elevator.styles';

const before = (currentIndex, setIndex, setClickDirection) => {
  setClickDirection(true);
  setIndex(currentIndex <= 0 ? 0 : currentIndex - 1);
};

const next = (currentIndex, setIndex, stepsLength, setClickDirection) => {
  setClickDirection(false);
  setIndex(currentIndex >= stepsLength ? stepsLength : currentIndex + 1);
};

export default ({ steps, elevatorTheme }) => {
  const [currentIndex, setIndex] = useState(0);
  const [clickDirection, setClickDirection] = useState(true); // true -> up and false -> down ~DH
  const [stepsLength] = useState(steps?.length - 1);

  return steps ? (
    <Elevator elevatorTheme={elevatorTheme}>
      {steps.map(({ headline, topline, id, content }, index) => (
        <ElevatorStep
          index={index}
          clickDirection={clickDirection}
          currentIndex={currentIndex}
          key={id}
          headline={headline}
          topline={topline}
          content={content}
        />
      ))}

      <ArrowBox>
        <Arrows>
          <ArrowBefore
            name="ChevronUp"
            isDisabled={!(currentIndex <= 0 ? 0 : currentIndex)}
            onClick={() => before(currentIndex, setIndex, setClickDirection)}
          />
          <ArrowNext
            name="ChevronDown"
            isDisabled={currentIndex === stepsLength}
            onClick={() =>
              next(currentIndex, setIndex, stepsLength, setClickDirection)
            }
          />
        </Arrows>
      </ArrowBox>
    </Elevator>
  ) : null;
};

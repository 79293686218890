import React from 'react';
import {
  StepsStyled,
  Step,
  InnerStep,
  Title,
  Description,
  Link,
  Arrow,
} from './Steps.styles';
import { Flex } from 'rebass';
import Icon from '~/components/common/Icon';
import Parser from '~/components/common/Parser';
import BaseLink from '~/components/common/Link';

export type StepsItem =
  | {
      id?: string;
      title?: string;
      description?: {
        content?: string;
      };
    }
  | undefined;

type StepsProps = {
  steps?: StepsItem[];
};

export default function Steps({ steps }: StepsProps) {
  const filteredSteps = steps ? steps.filter(Boolean) : [];
  return (
    <StepsStyled
      mx={-1}
      mt={6}
      flexDirection={{ _: 'column', lg: 'row' }}
      alignItems={{ _: 'center', lg: 'flex-start' }}
    >
      {filteredSteps.map((step, index) => {
        if (!step) return null;

        const { id, title, description = { content: '' } } = step;

        return (
          <Step
            px={1}
            key={id}
            mb={{ _: 4, lg: 3 }}
            width={{ _: '100%', sm: '70%', lg: 'auto' }}
          >
            <InnerStep>
              <Flex justifyContent={{ _: 'center', lg: 'flex-start' }}>
                <Title level="h4">{title}</Title>
                {index !== filteredSteps.length - 1 ? (
                  <Arrow ml={2}>
                    <Icon name="ArrowRightAlt" />
                  </Arrow>
                ) : null}
              </Flex>
              {description && (
                <Description>
                  <Parser html={description.content} />
                </Description>
              )}
            </InnerStep>
          </Step>
        );
      })}
    </StepsStyled>
  );
}

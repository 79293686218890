import React from 'react';
import { graphql } from 'gatsby';

import Elevator from '~/components/common/Elevator';
import ThemeProvider from '~/components/common/ThemeProvider';
import { getComponents } from '~/components/craft/CraftComponents';
import {
  Craft_PageBuilderBlockElevator,
  Craft_ElevatorStepsStep,
} from '~/query';

type CraftBlockElevatorProps = {
  data: Craft_PageBuilderBlockElevator;
  elevatorSteps: Craft_ElevatorStepsStep;
};

export default function CraftBlockElevator({ data }: CraftBlockElevatorProps) {
  const { children, elevatorTheme } = data;

  const availableComponents = {
    BlockContent: require('~/components/craft/CraftBlockContent').default,
  };

  const blockContents = getComponents(
    children,
    availableComponents,
    'Craft_PageBuilder',
  );

  const steps = data.elevatorSteps
    ? data.elevatorSteps
        .map((step, index) => {
          if (!step) return undefined;

          return {
            id: step.id || undefined,
            headline: step.headline || undefined,
            topline: step.topline || undefined,
            content: <>{blockContents[index]}</> || undefined,
          };
        })
        .filter(Boolean)
    : [];

  return (
    <ThemeProvider name={elevatorTheme ?? undefined}>
      <Elevator steps={steps} elevatorTheme={elevatorTheme} />
    </ThemeProvider>
  );
}

export const query = graphql`
  fragment CraftPageBuilderBlockElevatorQuery on Craft_PageBuilderBlockElevator {
    id

    elevatorTheme

    # Steps
    elevatorSteps {
      __typename
      ... on Craft_ElevatorStepsStep {
        id
        topline
        headline
      }
    }

    # Content
    children {
      __typename
      ...CraftPageBuilderBlockContentQuery
    }
  }
`;

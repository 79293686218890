import React from 'react';
import { Box, Flex } from 'rebass';
import Measure from 'react-measure';
import Icon from '~/components/common/Icon';
import {
  Wrapper,
  Header,
  Input,
  InfoTrigger,
  InfoTransition,
  InfoContent,
} from './CalculatorMaterialInput.styles';
import { IconNames } from '~/components/common/Icon/Icon';
import { Field } from 'formik';
import Parser from '~/components/common/Parser';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
import throttle from 'lodash.throttle';

type Example = {
  iconName?: string;
  title?: string;
  titleShort?: string;
};

type CalculatorMaterialInputProps = {
  title: string;
  identifier: string;
  examples?: Example[];
  infoText?: React.ReactNode;
  showInfo: boolean;
  toggleShowInfo: () => void;
};

const selectOnFocus = (event: any) => {
  const target = event.target;

  if (typeof window !== 'undefined') {
    window.requestAnimationFrame(() => {
      try {
        // @ts-ignore
        target.setSelectionRange(0, 9e3);
      } catch (o_O) {
        target.select();
      }
    });
  }
};

const submitFormThrottled = throttle(
  (form: any) => requestAnimationFrame(() => form.submitForm()),
  666,
);

export default function CalculatorMaterialInput({
  title,
  identifier,
  infoText,
  examples,
  showInfo,
  toggleShowInfo,
}: CalculatorMaterialInputProps) {
  const t = useCraftTranslations();
  return (
    <Wrapper>
      <Header px="5px" py={1} fontSize={1}>
        <Parser html={title} />
      </Header>
      <Measure bounds>
        {({ measureRef, contentRect }) => {
          const height = (contentRect.bounds && contentRect.bounds.height) || 0;
          return (
            <InfoTransition showInfo={showInfo} height={height}>
              <InfoContent
                p={1}
                fontSize={0}
                showInfo={showInfo}
                ref={measureRef}
              >
                {infoText}
                {examples
                  ? examples.map(({ iconName, title }, index) =>
                      iconName ? (
                        <Flex
                          key={index}
                          pt={1}
                          alignItems="center"
                          fontSize={5}
                        >
                          <Box
                            fontSize={0}
                            css={{
                              visibility: index !== 0 ? 'hidden' : undefined,
                            }}
                            pr={1}
                            flex="0 0 20%"
                          >
                            {t('e.g.')}
                          </Box>
                          <Icon name={iconName as IconNames} />
                          <Box pl={1} fontSize={0} flex="0 0 56%">
                            {title}
                          </Box>
                        </Flex>
                      ) : null,
                    )
                  : null}
              </InfoContent>
            </InfoTransition>
          );
        }}
      </Measure>
      <Flex>
        <InfoTrigger
          flex="0 0 64px"
          bg="tertiaryBright"
          alignItems="center"
          justifyContent="center"
          fontSize={4}
          color="dim"
          as="button"
          type="button"
          onClick={toggleShowInfo}
        >
          <Icon name="Info" />
        </InfoTrigger>
        <Field
          name={`materials.${identifier}`}
          render={({ field, form }: any) => {
            return (
              <Input
                {...field}
                id={identifier}
                pl={{ _: 1, xxl: 2 }}
                pr={{ _: '38px', xxl: '56px' }}
                py={1}
                type="number"
                min={0}
                onFocus={selectOnFocus}
                onBlur={(e: any) => {
                  form.setFieldValue(
                    `materials.${identifier}`,
                    Math.max(0, parseFloat(e.target.value)) || 0,
                  );

                  submitFormThrottled(form);
                }}
              />
            );
          }}
        />
        <Box
          pl="0.4em"
          pr={{ _: 1, xxl: 2 }}
          py={1}
          css={{ position: 'absolute', right: 0 }}
        >
          {t('kg')}
        </Box>
      </Flex>
    </Wrapper>
  );
}

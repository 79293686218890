import { ObjectFitProperty, ObjectPositionProperty, Position } from 'csstype';
import { Box } from 'rebass';
import styled from '~/utils/styled';
import media from '~/utils/mediaqueries';
import { sizes } from '~/themes/baseTheme';
import CraftGatsbyImage from '~/components/craft/CraftGatsbyImage';

interface ImageWrapperProps {
  sticky?: boolean;
}

export const ImageWrapper = styled(Box)<ImageWrapperProps>(
  {
    overflow: 'hidden',

    [media('sm')]: {
      alignSelf: 'stretch',
    },

    [media('lg')]: {
      order: 1,
    },
  },
  ({ sticky }) =>
    sticky && {
      [media('lg')]: {
        position: 'sticky',
        height: '100vh',
        top: 0,
        marginTop: -sizes.header.height.lg,
      },
    },
);

export const ImageAspectRatio = styled('div')({
  position: 'relative',
  height: '100%',

  '&:before': {
    display: 'block',
    content: '""',
    width: '100%',
    paddingTop: `${(2 / 3) * 100}%`,

    [media('lg')]: {
      paddingTop: `${(1 / 1) * 100}%`,
    },
  },
});

interface ImageProps {
  objectFit?:
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'contain'
    | 'cover'
    | 'fill'
    | 'none'
    | 'scale-down';
  objectPosition?: string;
  alt?: string;
}

export const Image = styled(CraftGatsbyImage)<ImageProps>({
  position: 'absolute !important' as any,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

interface ContentWrapperProps {
  flip?: boolean;
  stretch?: boolean;
}

export const ContentWrapper = styled(Box)<ContentWrapperProps>(
  {
    order: 2,
  },
  ({ flip, stretch }) =>
    // Adjust padding when teaser is stretched
    stretch && {
      [media('lg')]: {
        paddingLeft: flip ? undefined : 0,
        paddingRight: flip ? 0 : undefined,
      },
    },
  ({ flip }) =>
    // Adjust order when teaser is flipped
    flip && {
      [media('lg')]: {
        order: -1,
      },
    },
);

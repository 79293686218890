import React, { Children } from 'react';
import { Flex, Box } from 'rebass';

type BlockColumnsProps = {
  children: React.ReactNode;
};

export default function BlockColumns({ children }: BlockColumnsProps) {
  return (
    <Flex mx={-5} flexWrap="wrap">
      {Children.map(children, child => (
        <Box px={5} width={{ _: '100%', lg: '50%' }}>
          {child}
        </Box>
      ))}
    </Flex>
  );
}

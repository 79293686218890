import React from 'react';
import { graphql } from 'gatsby';

import CraftComponents from '~/components/craft/CraftComponents';
import Segment from '~/components/common/Segment';
import { Craft_PageBuilderSegment } from '~/query';

/**
 * Available components.
 *
 * Defines available components
 * within this component.
 */
// prettier-ignore
const availableComponents = {
  BlockAccordion: require('~/components/craft/CraftBlockAccordion').default,
  BlockColumns: require('~/components/craft/CraftBlockColumns').default,
  BlockContent: require('~/components/craft/CraftBlockContent').default,
  BlockElevator: require('~/components/craft/CraftBlockElevator').default,
  BlockFacts: require('~/components/craft/CraftBlockFacts').default,
  BlockIcons: require('~/components/craft/CraftBlockIcons').default,
  BlockImageText: require('~/components/craft/CraftBlockImageText').default,
  BlockLogo: require('~/components/craft/CraftBlockLogo').default,
  BlockLogos: require('~/components/craft/CraftBlockLogos').default,
  BlockReadMoreList: require('~/components/craft/CraftBlockReadMoreList').default,
  BlockService: require('~/components/craft/CraftBlockService').default,
  BlockSorts: require('~/components/craft/CraftBlockSorts').default,
  BlockSteps: require('~/components/craft/CraftBlockSteps').default,
  BlockTreeCampaign: require('~/components/craft/CraftBlockTreeCampaign').default,
  BlockVideoText: require('~/components/craft/CraftBlockVideoText').default,
};

type CraftSegmentProps = {
  data: Craft_PageBuilderSegment;
  noPadding?: boolean;
};

export default function CraftSegment({ data, noPadding }: CraftSegmentProps) {
  const {
    children,
    segmentThemeName,
    segmentAlignItems,
    segmentHeight,
    segmentInnerWidth,
    segmentBackgroundImage,
    segmentBackgroundImagePortrait,
    segmentBorderBottom,
    segmentOverflow,
  } = data;

  return (
    <Segment
      themeName={segmentThemeName ?? undefined}
      alignItems={segmentAlignItems ?? undefined}
      backgroundImage={segmentBackgroundImage?.[0] ?? undefined}
      backgroundImagePortrait={segmentBackgroundImagePortrait?.[0] ?? undefined}
      height={segmentHeight ?? undefined}
      borderBottom={segmentBorderBottom ?? undefined}
      width={segmentInnerWidth ?? undefined}
      noPadding={noPadding ?? undefined}
      overflow={segmentOverflow ?? undefined}
    >
      <CraftComponents
        components={children}
        availableComponents={availableComponents}
        prefix={/Craft_PageBuilder(Blog)?/}
      />
    </Segment>
  );
}

export const query = graphql`
  fragment CraftSegmentQuery_NoNesting on Craft_PageBuilderSegment {
    id
    # Content
    children {
      __typename

      # Blocks
      ...CraftPageBuilderBlockColumnsQuery
      ...CraftPageBuilderBlockContentQuery
      ...CraftPageBuilderBlockElevatorQuery
      ...CraftPageBuilderBlockFactsQuery
      ...CraftPageBuilderBlockIconsQuery
      ...CraftPageBuilderBlockImageTextQuery
      ...CraftPageBuilderBlockLogoQuery
      ...CraftPageBuilderBlockLogosQuery
      ...CraftPageBuilderBlockServiceQuery
      ...CraftPageBuilderBlockSortsQuery
      ...CraftPageBuilderBlockStepsQuery
      ...CraftPageBuilderBlockTreeCampaignQuery
      ...CraftPageBuilderBlockVideoTextQuery
    }
    # Appearance
    segmentThemeName
    segmentAlignItems
    segmentHeight
    segmentInnerWidth
    segmentBackgroundImage {
      ...CraftImageFluid
    }
    segmentBackgroundImagePortrait {
      ...CraftImageFluid
    }
    segmentBorderBottom
    segmentOverflow
  }

  # this is a almost an exact duplicate of the no-nesting query, with added nested types
  #  - CraftPageBuilderBlockAccordionQuery
  #  - CraftPageBuilderBlockReadMoreListQuery

  fragment CraftSegmentQuery on Craft_PageBuilderSegment {
    ...CraftSegmentQuery_NoNesting
    children {
      __typename

      ...CraftPageBuilderBlockAccordionQuery
      ...CraftPageBuilderBlockReadMoreListQuery
    }
  }
`;

export const defaultData = {
  aid: '43341', // your AID
  encoding: 'UTF-8', // desired encoding
  mid: '42764', // your MID
  mode: 'live', // desired mode
  portalid: '2030884', // your PortalId
  // request: '<request>', // fixed value
  responsetype: 'JSON', // fixed value
  storecarddata: 'yes', // fixed value
  // hash calculated over your request-parameter-values (alphabetical request-order) plus PMI portal key
  // hash: '0ebaf8559595320bded124e62c2ea327'
};

/**
 * template for md5-hash generation:
 * =================================
 *
 * 43341<clearingtype><currency>UTF-842764live2030884<request>JSONyes<key>
 */

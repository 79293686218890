import { useCallback, useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { FormikActions } from 'formik';

import { CalculatorWizardStep01CompanyDataValues } from '~/components/calculator/CalculatorWizardSteps/CalculatorWizardStep01CompanyData/CalculatorWizardStep01CompanyData';
import { CalculatorWizardStep02TaxDataValues } from '~/components/calculator/CalculatorWizardSteps/CalculatorWizardStep02TaxData/CalculatorWizardStep02TaxData';
import { CalculatorWizardStep03LicenseValues } from '~/components/calculator/CalculatorWizardSteps/CalculatorWizardStep03License/CalculatorWizardStep03License';
import { CalculatorWizardStep04PaymentMethodValues } from '~/components/calculator/CalculatorWizardSteps/CalculatorWizardStep04PaymentMethod/CalculatorWizardStep04PaymentMethod';
import { useCraftCalculatorConcludeContract } from '~/utils/hooks/useCraftCalculatorConcludeContract';

type WizardValues = CalculatorWizardStep01CompanyDataValues &
  CalculatorWizardStep02TaxDataValues &
  CalculatorWizardStep03LicenseValues &
  CalculatorWizardStep04PaymentMethodValues;

type JSONReplacer = (this: any, key: string, value: any) => any;

function replace<T>(obj: T, replacer: JSONReplacer) {
  return JSON.parse(JSON.stringify(obj, replacer)) as T;
}

export function useWizardSubmit({
  amounts,
  startYear,
  startDate,
  couponNumber,
}: {
  amounts: {
    materialId: number;
    amount: number;
  }[];
  startYear: number;
  startDate?: number;
  couponNumber: string;
}) {
  const concludeContract = useCraftCalculatorConcludeContract();
  const concludeContractURI = (concludeContract &&
    concludeContract.fullUri) as string;

  const requestPaymentMutation = gql`
    mutation RequestPaymentMutation(
      $contract: ContractInput!
      $paymentMethod: PaymentMethod!
      $startYear: Int!
      $startDate: Int
      $redirectURI: String!
      $errorURI: String!
      $backURI: String!
      $couponNumber: String
    ) {
      requestPayment(
        input: {
          contract: $contract
          paymentMethod: $paymentMethod
          startYear: $startYear
          startDate: $startDate
          redirectURI: $redirectURI
          errorURI: $errorURI
          backURI: $backURI
          couponNumber: $couponNumber
        }
      ) {
        redirectURI
      }
    }
  `;

  const [
    requestPayment,
    { data: requestPaymentData, error: requestPaymentError },
  ] = useMutation(requestPaymentMutation);

  const [
    networkRequestPaymentError,
    setNetworkRequestPaymentError,
  ] = useState();

  const handleWizardSubmit = useCallback(
    function handleWizardSubmit(
      values: WizardValues,
      formik: FormikActions<WizardValues>,
    ) {
      const { companyData, taxData, license, paymentMethod } = values;

      // TODO: Fix these URLs

      const port = location.port ? `:${location.port}` : '';
      const redirectURI = `${location.protocol}//${location.hostname}${port}${concludeContractURI}`;

      const backURI = location.href;

      const payload = replace(
        {
          variables: {
            contract: {
              companyData,
              taxData,
              license,
              amounts,
            },
            startYear,
            startDate,
            paymentMethod,
            redirectURI,
            backURI,
            errorURI: redirectURI,
            couponNumber,
          },
        },
        (_, value) => {
          if (value === '') {
            return undefined;
          } else {
            return value;
          }
        },
      );

      (async () => {
        try {
          setNetworkRequestPaymentError(undefined);
          await requestPayment(payload);
        } catch (e) {
          setNetworkRequestPaymentError(e?.message);
        } finally {
          formik.setSubmitting(false);
        }
      })();
    },
    [requestPayment, amounts],
  );

  const requestPaymentRedirectURI =
    requestPaymentData &&
    requestPaymentData.requestPayment &&
    requestPaymentData.requestPayment.redirectURI;

  useEffect(() => {
    if (requestPaymentRedirectURI) {
      // 👋 seeya
      if (typeof window !== 'undefined') {
        // Track Conversion after successful submit
        // @ts-ignore
        const dataLayer = window.dataLayer || [];
        // Calculate total amount
        const amount = amounts?.reduce((acc: number, item: {materialId: number; amount: number;}) =>  acc += item.amount, 0) ?? 0;
        // If amount is >0 track a purchase in analytics
        console.info("Track Purchase with amount", amount)
        !!amount && dataLayer.push({
          event: 'purchase',
          ecommerce: {
            value: amount,
          },
        })

        // Redirect to desired success page
        window.location = requestPaymentRedirectURI;
      }
    }
  }, [requestPaymentRedirectURI]);

  return {
    handleWizardSubmit,
    requestPaymentError: requestPaymentError ?? networkRequestPaymentError,
  };
}

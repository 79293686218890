import { useEffect, useCallback, useState, useRef } from 'react';

function mq(query: string) {
  return typeof window !== 'undefined' && window.matchMedia(query).matches;
}

const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

function getIsTouchDevice() {
  if (
    typeof window !== 'undefined' &&
    ('ontouchstart' in window ||
      // @ts-ignore
      window.TouchEvent ||
      // @ts-ignore
      (window.DocumentTouch && document instanceof DocumentTouch))
  ) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://github.com/Modernizr/Modernizr/issues/1814
  var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}

const isTouchDevice = getIsTouchDevice();

const keyboardTags = ['INPUT', 'TEXTAREA'];

export function useKeyboardShown({
  ignoreElementRef,
}: {
  ignoreElementRef: React.RefObject<Node> | undefined;
}) {
  const [keyboardShown, setKeyboardShown] = useState(() => {
    if (typeof document !== 'undefined' && document.activeElement) {
      return keyboardTags.includes(document.activeElement.tagName);
    }
    return false;
  });

  const innerHeight = useRef(0);

  const handleFocus = useCallback(
    e => {
      if (e.target === window) {
        return;
      }

      if (
        ignoreElementRef &&
        ignoreElementRef.current &&
        ignoreElementRef.current.contains &&
        ignoreElementRef.current.contains(e.target)
      ) {
        return;
      }

      const tagName = e.target.tagName;
      if (keyboardTags.includes(tagName) && isTouchDevice) {
        setKeyboardShown(true);
      }
    },
    [setKeyboardShown, ignoreElementRef],
  );
  const handleBlur = useCallback(() => setKeyboardShown(false), [
    setKeyboardShown,
  ]);

  const handleResize = useCallback(() => {
    const lastInnerHeight = innerHeight.current;
    innerHeight.current = window.innerHeight;

    if (innerHeight.current - lastInnerHeight > 200) {
      if (
        document.activeElement &&
        (document.activeElement as any).blur &&
        typeof (document.activeElement as any).blur === 'function'
      ) {
        (document.activeElement as any).blur();
      }
    }
  }, [innerHeight]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('focus', handleFocus, true);
      window.addEventListener('blur', handleBlur, true);
      if (isTouchDevice) {
        window.addEventListener('resize', handleResize, true);
      }
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('focus', handleFocus);
        window.removeEventListener('focus', handleBlur);
        if (isTouchDevice) {
          window.removeEventListener('resize', handleResize);
        }
      }
    };
  });

  return keyboardShown;
}

import React from 'react';
import { graphql } from 'gatsby';
import Parser from '~/components/common/Parser';
import { Craft_PageBuilderElementIconList, Craft_IconlistIcon } from '~/query';
import IconList from '~/components/common/IconList';
import { IconNames } from '~/components/common/Icon/Icon';

type CraftElementIconListProps = {
  data?: Craft_PageBuilderElementIconList;
};

export default function CraftElementIconList({
  data,
}: CraftElementIconListProps) {
  if (!data) return null;
  const iconListRaw = (data?.iconlist ?? []) as Craft_IconlistIcon[];
  const iconList = iconListRaw.map(
    ({ iconTitle, iconTitleShort, iconName }) => ({
      iconTitle: iconTitle ? <Parser html={iconTitle} /> : null,
      iconTitleShort: iconTitleShort ? <Parser html={iconTitleShort} /> : null,
      iconName: iconName as IconNames,
    }),
  );

  return (
    <IconList
      py={data?.py}
      pb={data?.pb}
      direction={data?.iconlistDirection || 'row'}
      items={iconList}
    />
  );
}

export const query = graphql`
  # Fragment for usage in PageBuilder
  fragment CraftPageBuilderElementIconListQuery on Craft_PageBuilderElementIconList {
    id
    iconlistDirection
    py
    pb
    iconlist {
      ... on Craft_IconlistIcon {
        id
        enabled
        iconTitle
        iconTitleShort
        iconName
      }
    }
  }
`;

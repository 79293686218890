import React from 'react';
import { graphql } from 'gatsby';

import { Craft_PageBuilderBlockFacts } from '~/query';

import CraftComponents from '~/components/craft/CraftComponents';
import BlockContent from '~/components/common/BlockContent';
import Facts from '~/components/common/Facts';

/**
 * Available components.
 *
 * Defines available components
 * within this component.
 */
const availableComponents = {
  ElementHeadline: require('~/components/craft/CraftElementHeadline').default,
};

type CraftBlockFactsProps = {
  data: Craft_PageBuilderBlockFacts;
};

export default function CraftBlockFacts({ data }: CraftBlockFactsProps) {
  const { children, factsDate } = data;

  const facts = data.facts
    ? data.facts
        .map((fact: any) => {
          if (!fact) return undefined;

          return {
            id: fact.id || undefined,
            number: fact.factNumber || undefined,
            text: fact.factText || undefined,
          };
        })
        .filter(Boolean)
    : [];

  return (
    <BlockContent>
      {children ? (
        <CraftComponents
          components={children}
          availableComponents={availableComponents}
          prefix="Craft_PageBuilder"
        />
      ) : null}
      <Facts facts={facts} factsDate={factsDate} />
    </BlockContent>
  );
}

export const query = graphql`
  fragment CraftPageBuilderBlockFactsQuery on Craft_PageBuilderBlockFacts {
    id
    # Content
    children {
      __typename
      # Content: Elements
      ...CraftPageBuilderElementHeadlineQuery
    }

    # Facts
    factsDate
    facts {
      __typename
      ... on Craft_FactsFact {
        id
        factNumber
        factText
      }
    }
  }
`;

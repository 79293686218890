import { loadExternalScript } from '../hooks/useExternalScriptEffect';
import { defaultData } from './const';

const PAYONE_API = 'https://secure.pay1.de/client-api/js/ajax.js';
const CB_NAME = '49b78f56a75fdb3cdb4047312a318921'; // md5 processPayoneResponse

type Opts = {
  request: 'managemandate';
  hash: string;
  data: {
    [key: string]: any;
  };
};

type Status = 'APPROVED' | 'ERROR' | 'INVALID';

export type PayoneResponse = {
  status: Status;
  customerMessage?: string;
  errorCode?: string;
  errorMessage?: string;
  mandateIdentification?: string;
  mandateText?: string;
  creditorIdentifier?: string;
  iban?: string;
  bic?: string;
};

function decodeURI_withPlus(str: string | undefined) {
  return str ? decodeURIComponent(str.replace(/\+/g, '%20')) : undefined;
}

export async function request(opts: Opts) {
  await loadExternalScript(PAYONE_API);

  const cbName = `${CB_NAME}_${Date.now()}`;

  return new Promise<PayoneResponse>(resolve => {
    function processPayoneResponse(res: any) {
      resolve({
        status: res.get('status'),
        customerMessage: res.get('customermessage'),
        errorCode: res.get('errorcode'),
        errorMessage: res.get('errormessage'),
        mandateIdentification: res.get('mandate_identification'),
        mandateText: decodeURI_withPlus(res.get('mandate_text')),
        creditorIdentifier: res.get('creditor_identifier'),
        iban: res.get('iban'),
        bic: res.get('bic'),
      });
      delete (<any>window)[cbName];
    }

    (<any>window)[cbName] = processPayoneResponse;

    var request = new (<any>window).PayoneRequest(
      {
        ...defaultData,
        ...opts.data,
        request: opts.request,
        hash: opts.hash,
      },
      {
        return_type: 'object',
        callback_function_name: cbName,
      },
    );

    request.checkAndStore();
  });
}

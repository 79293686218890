import React from 'react';
import { Input } from '@rebass/forms';
import FormField, { FormFieldRow } from '~/components/common/FormField';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
import InfoBox from '~/components/common/InfoBox';
import {
  checkRegistrationNumberValidity,
  // checkTemporaryRegistrationNumberValidity,
} from '~/utils/registrationNumber';
import { Validate } from '../../../common/Wizard/Wizard';
import { connect } from 'formik';

type CalculatorWizardStep03LicenseProps = {
  registrationNumberInfo: React.ReactNode;
  checkRegNumber?: boolean;
};

const CalculatorWizardStep03LicenseImpl = connect<
  CalculatorWizardStep03LicenseProps,
  CalculatorWizardStep03LicenseValues
>(function CalculatorWizardStep03LicenseImpl({
  formik,
  registrationNumberInfo,
  checkRegNumber,
}) {
  const t = useCraftTranslations();

  const registrationNumber = formik.values?.license?.registrationNumber ?? '';
  // const temporaryRegistrationNumber =
  //   formik.values?.license?.temporaryRegistrationNumber ?? '';

  function validateRegistrationNumber(registrationNumber: any) {
    if (
      registrationNumber &&
      !checkRegistrationNumberValidity(String(registrationNumber))
    ) {
      return `${t('license.registrationNumber')} ${t('is invalid')}`;
    }
  }

  // function validateTemporaryRegistrationNumber(
  //   temporaryRegistrationNumber: any,
  // ) {
  //   if (
  //     temporaryRegistrationNumber &&
  //     !checkTemporaryRegistrationNumberValidity(
  //       String(temporaryRegistrationNumber),
  //     )
  //   ) {
  //     return `${t('license.temporaryRegistrationNumber')} ${t('is invalid')}`;
  //   }
  // }

  function validateRegistrationNumberIfStated(registrationNumber: any) {
    if (
      registrationNumber &&
      !checkRegistrationNumberValidity(String(registrationNumber))
    ) {
      return `${t('license.registrationNumber')} ${t('is invalid')}`;
    }
  }

  // function validateTemporaryRegistrationNumberIfStated(
  //   temporaryRegistrationNumber: any,
  // ) {
  //   if (
  //     temporaryRegistrationNumber &&
  //     !checkTemporaryRegistrationNumberValidity(
  //       String(temporaryRegistrationNumber),
  //     )
  //   ) {
  //     return `${t('license.temporaryRegistrationNumber')} ${t('is invalid')}`;
  //   }
  // }

  return (
    <>
      <InfoBox>{registrationNumberInfo}</InfoBox>
      <FormFieldRow>
        <FormField
          name="license.registrationNumber"
          type="text"
          component={Input}
          onChange={(e: any, form: any) => {
            form.setFieldValue(
              'license.registrationNumber',
              e.target.value.replace(/\s/g, ''),
            );
          }}
          hideOptionalIndicator={checkRegNumber}
          validate={
            checkRegNumber
              ? validateRegistrationNumber
              : validateRegistrationNumberIfStated
          }
        />
      </FormFieldRow>

      {/* <FormFieldRow>
        <FormField
          name="license.temporaryRegistrationNumber"
          type="text"
          component={Input}
          onChange={(e: any, form: any) => {
            form.setFieldValue(
              'license.temporaryRegistrationNumber',
              e.target.value.replace(/\s/g, ''),
            );
          }}
          hideOptionalIndicator={checkRegNumber}
          validate={
            checkRegNumber
              ? validateTemporaryRegistrationNumber
              : validateTemporaryRegistrationNumberIfStated
          }
        />
      </FormFieldRow> */}
    </>
  );
});

export default function CalculatorWizardStep03License(
  props: CalculatorWizardStep03LicenseProps,
) {
  return <CalculatorWizardStep03LicenseImpl {...props} />;
}

/**
 * The initial values for this component are aggregated
 * into the main wizard component
 */
export type CalculatorWizardStep03LicenseValues = {
  license: {
    registrationNumber?: string;
    temporaryRegistrationNumber: string;
  };
};

CalculatorWizardStep03License.initialValues = {
  license: {
    registrationNumber: '',
    temporaryRegistrationNumber: '',
  },
} as CalculatorWizardStep03LicenseValues;

CalculatorWizardStep03License.title = 'Step 3';
CalculatorWizardStep03License.description = 'LUCID registration number';

CalculatorWizardStep03License.validate = undefined as Validate;

import styled from '~/utils/styled';

interface SlideShowWrapperProps {
  fadeOut?: boolean;
}
// This needs to be down here because we reference the above styles.
export const GalleryWrapperStyled = styled('figure')(
  props => ({
    position: 'relative',
    margin: 0,
    marginTop: props.theme.space[1],
    marginBottom: props.theme.space[2],
  }),
);

export const SlideShowWrapper = styled('div')<SlideShowWrapperProps>(props => ({
  transition: 'opacity 300ms ease-out',
  opacity: props.fadeOut ? 0 : 1,
}));

export const GalleryStyled = styled('div')( {
    flex: 'none', 
});

export const CaptionStyled = styled('figcaption')(props => ({
  fontSize: props.theme.fontSizes[1],
  marginTop: props.theme.space[1],
  fontWeight: 700,
}));

export const CloseButtonWrapper = styled('figcaption')(props => ({
  cursor: 'pointer', 
  position: "absolute", 
  zIndex: 1, 
  top: 25, 
  right: 25, 
}));

import { Flex, Box } from 'rebass';
import styled from '~/utils/styled';
import media from '~/utils/mediaqueries';
import IconBase from '~/components/common/Icon';

export const BlockLogoStyled = styled(Flex)({});

export const Decoration = styled(Box)({
  position: 'relative',
  zIndex: 0,
});

type IconProps = {
  scaleUp: boolean;
};

export const Icon = styled(IconBase)<IconProps>(({ scaleUp }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  opacity: 0.1,
  [media('lg')]: {
    opacity: 1,
  },
  svg: {
    position: 'absolute',
    width: '70vw',
    height: '70vw',
    top: '20%',
    left: 0,
    transform: 'translate(-40%, -30%)',
    [media('lg')]: {
      width: scaleUp ? '45vw' : 'auto',
      height: scaleUp ? '45vw' : '100%',
      top: '50%',
      left: 0,
      transform: 'translate(-40%, -55%)',
      maxWidth: 700,
    },
  },
}));

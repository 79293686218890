import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import uuid from 'uuid/v4';

import { breakpoints } from '~/themes/baseTheme';

import {
  TextImageStyled,
  TextImageWrapperStyled,
  PictureStyled,
  PictureWrapperStyled,
  ImgStyled,
  CaptionStyled,
} from './TextImage.styles';

/**
 * Simple image compontent that accepts a
 * set of breakpoints for responsive images.
 *
 * Usage:
 * <Image
 *   src="my_img_url"
 *   alt="my_img_alt_text"
 *   srcSet={{ xs: 'my_xs_img_url', sm: 'my_sm_img_url', lg: 'my_lg_img_url' }}
 * />
 */
export default function TextImage({
  src,
  alt,
  srcSet,
  maxWidth,
  className,
  appearance,
  caption,
}) {
  const id = uuid();
  let additionalSources = [];

  if (srcSet) {
    // Match srcSet to breakpoints as additional sources.
    additionalSources = Object.keys(srcSet).map(breakpoint => ({
      key: `source-${id}-${breakpoint}`,
      minWidth: breakpoints[breakpoint] || 0,
      src: srcSet[breakpoint],
    }));

    // Sort source items.
    // If the sources aren't in the right order the images will be shown wrong.
    additionalSources = additionalSources.sort((firstSource, secondSource) => {
      if (firstSource.minWidth > secondSource.minWidth) return -1;
      if (firstSource.minWidth < secondSource.minWidth) return 1;

      return 0;
    });
  }

  return (
    <TextImageStyled>
      <TextImageWrapperStyled maxWidth={maxWidth}>
        <PictureWrapperStyled appearance={appearance}>
          <PictureStyled className={className}>
            {additionalSources.map(source => (
              <source
                key={source.key}
                srcSet={encodeURI(source.src)}
                media={`(min-width: ${source.minWidth}px)`}
              />
            ))}
            <ImgStyled src={encodeURI(src)} alt={alt} />
          </PictureStyled>
        </PictureWrapperStyled>
        <Box px={{ _: '30px', md: 4, lg: 5, xl: 6 }} >
          {caption && <CaptionStyled>{caption}</CaptionStyled>}
        </Box>
      </TextImageWrapperStyled>
    </TextImageStyled>
  );
}

TextImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  srcSet: PropTypes.object,
  maxWidth: PropTypes.number,
  appearance: PropTypes.string,
};

TextImage.defaultProps = {
  src: null,
  alt: null,
  srcSet: null,
  maxWidth: null,
  appearance: null,
};

import React from 'react';
import { Box } from 'rebass';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
import { HintBox, Header } from './CalculatorHintBox.styles';

type CalculatorHintBoxProps = {
  title?: React.ReactNode;
  children?: React.ReactNode;
};

export default function CalculatorHintBox({
  title,
  children,
}: CalculatorHintBoxProps) {
  const t = useCraftTranslations();

  return (
    <HintBox my={2}>
      <Header>{title ? title : t('Note regarding your calculation')}</Header>
      <Box px={{ _: 1, lg: 2 }} py={{ _: 1, lg: 1 }} bg="tertiaryBright" color="tertiaryBrightText">
        {children ? children : null}
      </Box>
    </HintBox>
  );
}

export type PaymentMethod =
  | 'CreditCard'
  | 'Debit'
  | 'Invoice'
  | 'PayPal'
  | 'Alipay';

type Countries = {
  [shorthand: string]: {
    de: string;
    en: string;
    _iso: string;
    _opts: {
      EUVAT: boolean;
    };
    _payment: PaymentMethod[];
  };
};

export const countries: Countries = {
  DE: {
    de: 'Deutschland',
    en: 'Germany',
    _iso: 'DE',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  AT: {
    de: 'Österreich',
    en: 'Austria',
    _iso: 'AT',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  BE: {
    de: 'Belgien',
    en: 'Belgium',
    _iso: 'BE',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  BG: {
    de: 'Bulgarien',
    en: 'Bulgaria',
    _iso: 'BG',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  CA: {
    de: 'Kanada',
    en: 'Canada',
    _iso: 'CA',
    _opts: { EUVAT: false },
    _payment: ['CreditCard', 'PayPal'],
  },
  IL: {
    de: 'Israel',
    en: 'Israel',
    _iso: 'IL',
    _opts: { EUVAT: false },
    _payment: ['CreditCard', 'PayPal'],
  },
  CH: {
    de: 'Schweiz',
    en: 'Switzerland',
    _iso: 'CH',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  CN: {
    de: 'China',
    en: 'China',
    _iso: 'CN',
    _opts: { EUVAT: false },
    _payment: ['CreditCard', 'PayPal'],
  },
  CY: {
    de: 'Zypern',
    en: 'Cyprus',
    _iso: 'CY',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit'],
  },
  CZ: {
    de: 'Tschechien',
    en: 'Czech Republic',
    _iso: 'CZ',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  DK: {
    de: 'Dänemark',
    en: 'Denmark',
    _iso: 'DK',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  EGY: {
    de: 'Ägypten',
    en: 'Egypt',
    _iso: 'EG',
    _opts: { EUVAT: false },
    _payment: ['CreditCard', 'PayPal'],
  },
  ES: {
    de: 'Spanien',
    en: 'Spain',
    _iso: 'ES',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  EE: {
    de: 'Estland',
    en: 'Estonia',
    _iso: 'EE',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  FI: {
    de: 'Finnland',
    en: 'Finland',
    _iso: 'FI',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  FR: {
    de: 'Frankreich',
    en: 'France',
    _iso: 'FR',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  GB: {
    de: 'Großbritannien',
    en: 'United Kingdom',
    _iso: 'GB',
    _opts: { EUVAT: false },
    _payment: ['CreditCard', 'PayPal', 'Invoice'],
  },
  GR: {
    de: 'Griechenland',
    en: 'Greece',
    _iso: 'GR',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  HR: {
    de: 'Kroatien',
    en: 'Croatia',
    _iso: 'HR',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit'],
  },
  HU: {
    de: 'Ungarn',
    en: 'Hungary',
    _iso: 'HU',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  ID: {
    de: 'Indonesien',
    en: 'Indonesia',
    _iso: 'ID',
    _opts: { EUVAT: false },
    _payment: ['CreditCard', 'PayPal'],
  },
  IN: {
    de: 'Indien',
    en: 'India',
    _iso: 'IN',
    _opts: { EUVAT: false },
    _payment: ['CreditCard', 'PayPal'],
  },
  IR: {
    de: 'Irland',
    en: 'Ireland',
    _iso: 'IE',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit'],
  },
  IS: {
    de: 'Island',
    en: 'Iceland',
    _iso: 'IS',
    _opts: { EUVAT: false },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  IT: {
    de: 'Italien',
    en: 'Italy',
    _iso: 'IT',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  JP: {
    de: 'Japan',
    en: 'Japan',
    _iso: 'JP',
    _opts: { EUVAT: false },
    _payment: ['CreditCard', 'PayPal'],
  },
  LI: {
    de: 'Liechtenstein',
    en: 'Liechtenstein',
    _iso: 'LI',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  LT: {
    de: 'Litauen',
    en: 'Lithuania',
    _iso: 'LT',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  LU: {
    de: 'Luxemburg',
    en: 'Luxembourg',
    _iso: 'LU',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  LV: {
    de: 'Lettland',
    en: 'Latvia',
    _iso: 'LV',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  M: {
    de: 'Malta',
    en: 'Malta',
    _iso: 'MT',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  MA: {
    de: 'Marokko',
    en: 'Morocco',
    _iso: 'MA',
    _opts: { EUVAT: false },
    _payment: ['CreditCard', 'PayPal'],
  },
  NO: {
    de: 'Norwegen',
    en: 'Norway',
    _iso: 'NO',
    _opts: { EUVAT: false },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  NL: {
    de: 'Niederlande',
    en: 'Netherlands',
    _iso: 'NL',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  NZ: {
    de: 'Neuseeland',
    en: 'New Zealand',
    _iso: 'NZ',
    _opts: { EUVAT: false },
    _payment: ['CreditCard', 'PayPal'],
  },
  PH: {
    de: 'Phillipinen',
    en: 'Philippines',
    _iso: 'PH',
    _opts: { EUVAT: false },
    _payment: ['CreditCard', 'PayPal'],
  },
  PL: {
    de: 'Polen',
    en: 'Poland',
    _iso: 'PL',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  PO: {
    de: 'Portugal',
    en: 'Portugal',
    _iso: 'PT',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  RO: {
    de: 'Rumänien',
    en: 'Romania',
    _iso: 'RO',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit'],
  },
  SA: {
    de: 'Saudi Arabien',
    en: 'Saudi Arabia',
    _iso: 'SA',
    _opts: { EUVAT: false },
    _payment: ['CreditCard', 'PayPal'],
  },
  SE: {
    de: 'Schweden',
    en: 'Sweden',
    _iso: 'SE',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  SG: {
    de: 'Singapur',
    en: 'Singapore',
    _iso: 'SG',
    _opts: { EUVAT: false },
    _payment: ['CreditCard', 'PayPal'],
  },
  SK: {
    de: 'Slowakei',
    en: 'Slovakia',
    _iso: 'SK',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  SLO: {
    de: 'Slowenien',
    en: 'Slovenia',
    _iso: 'SI',
    _opts: { EUVAT: true },
    _payment: ['CreditCard', 'PayPal', 'Debit', 'Invoice'],
  },
  KR: {
    de: 'Südkorea',
    en: 'South Korea',
    _iso: 'KR',
    _opts: { EUVAT: false },
    _payment: ['CreditCard', 'PayPal'],
  },
  TR: {
    de: 'Türkei',
    en: 'Turkey',
    _iso: 'TR',
    _opts: { EUVAT: false },
    _payment: ['CreditCard', 'PayPal'],
  },
  TW: {
    de: 'Taiwan',
    en: 'Taiwan',
    _iso: 'TW',
    _opts: { EUVAT: false },
    _payment: ['CreditCard', 'PayPal'],
  },
  UA: {
    de: 'Ukraine',
    en: 'Ukraine',
    _iso: 'UA',
    _opts: { EUVAT: false },
    _payment: ['CreditCard', 'PayPal'],
  },
  US: {
    de: 'USA',
    en: 'United States',
    _iso: 'US',
    _opts: { EUVAT: false },
    _payment: ['CreditCard', 'PayPal'],
  },
  ZA: {
    de: 'Südafrika',
    en: 'South Africa',
    _iso: 'ZA',
    _opts: { EUVAT: false },
    _payment: ['CreditCard', 'PayPal'],
  },
};

import React, { Fragment } from 'react';

export function replaceComponents(
  str: string,
  replace: string | RegExp,
  ...components: (React.ReactNode | undefined)[]
) {
  return str.split(replace).map((s, i) => {
    if (components[i]) {
      return (
        <Fragment key={i}>
          {s}
          {components[i]}
        </Fragment>
      );
    } else {
      return s;
    }
  });
}

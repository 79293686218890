import React from 'react';
import { Box } from 'rebass';
import ProgressStepper from '~/components/common/ProgressStepper';
import { useWizard } from '~/components/common/Wizard';
import ThemeProvider from '~/components/common/ThemeProvider';
import CalculatorPricingBox from '../CalculatorPricingBox';
import { ShowDesktop } from '../CalculatorMediaUtils';

type CalculatorBlockPricingProps = {
  priceLoading: boolean;
  price?: number;
  discount?: number;
  children?: React.ReactNode;
};

export default function CalculatorBlockPricing({
  priceLoading,
  price,
  discount,
  children,
}: CalculatorBlockPricingProps) {
  const { stepAttributes } = useWizard();

  return (
    <>
      <ThemeProvider name="default">
        {stepAttributes.length > 0 ? (
          <ShowDesktop>
            <Box
              css={{
                borderRadius: 5,
                overflow: 'hidden',
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
              }}
              fontSize={0}
              p={2}
              bg="background"
              color="text"
            >
              <ProgressStepper steps={stepAttributes} />
            </Box>
          </ShowDesktop>
        ) : null}
        <CalculatorPricingBox
          price={price}
          priceLoading={priceLoading}
          discount={discount}
          id="calculator-pricing-box"
        >
          {children}
        </CalculatorPricingBox>
      </ThemeProvider>
    </>
  );
}

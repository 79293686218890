import { css } from '@emotion/core';
import styled from '~/utils/styled';
import { Box, Flex } from 'rebass';
import media from '~/utils/mediaqueries';
import Link from '../Link';
import Button from '../Button';

type StepDotProps = {
  isActive?: boolean;
  isCompleted?: boolean;
};

export const StepDot = styled(Box)<StepDotProps>(
  ({ isActive, isCompleted, theme }) => ({
    width: 13,
    height: 13,
    border: 2,
    borderRadius: 13,
    borderColor:
      isActive || isCompleted ? theme.colors.primary : theme.colors.border,
    backgroundColor: isCompleted  ? theme.colors.primary : undefined,
    borderStyle: 'solid',
  }),
);

type StepIndicatorProps = {
  isActive?: boolean;
  isCompleted?: boolean;
  isLastStep?: boolean;
};

export const StepIndicator = styled(Box)<StepIndicatorProps>(
  ({ isActive, isCompleted, isLastStep, theme }) => ({
    position: 'relative',
    transform: 'translateY(7px)',
  }),
);

type StepLineProps = {
  nextIsActiveOrCompleted?: boolean;
  isLastStep?: boolean;
};

export const StepLine = styled(Box)<StepLineProps>(
  ({ nextIsActiveOrCompleted, isLastStep, theme }) => ({
    display: isLastStep ? 'none' : undefined,
    width: 2,
    backgroundColor: nextIsActiveOrCompleted
      ? theme.colors.primary
      : theme.colors.border,
    height: 'calc(100% - 13px)',
    position: 'absolute',
    marginTop: 13,
    left: '50%',
    transform: 'translateX(calc(-50% + 0.5px))',
  }),
);

type StepTextProps = {
  isActive?: boolean;
  isCompleted?: boolean;
};

export const StepText = styled(Box)<StepTextProps>(
  ({ isActive, isCompleted, theme }) => ({
    color: isActive || isCompleted ? 'inherit' : theme.colors.dim,
  }),
);

type StepTitleProps = {
  isActive?: boolean;
};

export const StepTitle = styled(Box)<StepTitleProps>(({ isActive, theme }) => ({
  color: isActive ? theme.colors.primary : 'inherit',
  fontWeight: isActive ? 700 : 600,
}));

export const StepDescription = styled(Box)(({ theme }) => ({
  paddingTop: 3,
}));

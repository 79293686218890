import React from 'react';
import { graphql } from 'gatsby';

import TextImage from '~/components/common/TextImage';

export default function CraftElementImage({ data }) {
  const { imgSubtitle, imageFile, imageMaxWidth } = data;

  if (!imageFile) {
    console.warn('No image given for craft image element.');
    return null;
  }

  // Define responsive sizes for this image.
  const srcSet = {
    xs: imageFile?.[0]?.url_540 || undefined,
    sm: imageFile?.[0]?.url_720 || undefined,
    md: imageFile?.[0]?.url_960 || undefined,
    lg: imageFile?.[0]?.url_1200 || undefined,
  };

  const isSvg = imageFile?.[0]?.url_1200.match(/.svg/gim);

  return (
    <TextImage
      src={imageFile?.[0]?.url_1200 || undefined}
      srcSet={isSvg ? null : srcSet}
      caption={imgSubtitle || undefined}
      // altText={imageFile?.[0]?.altText || undefined}
      maxWidth={parseInt(imageMaxWidth, 10) || undefined}
    />
  );
}

export const query = graphql`
  fragment ImageFieldsQuery on Craft_ImagesVolume {
    width
    height
    altText
    url_540: url(crop: { width: 540 })
    url_720: url(crop: { width: 720 })
    url_960: url(crop: { width: 960 })
    url_1200: url(crop: { width: 1200 })
  }

  # Fragment for usage in PageBuilder
  fragment CraftPageBuilderElementImageQuery on Craft_PageBuilderElementImage {
    id

    # Content
    imageMaxWidth
    imageFile {
      ...ImageFieldsQuery
    }
  }

  # Fragment for usage in PageBuilder
  fragment CraftPageBuilderBlogElementImageQuery on Craft_PageBuilderBlogElementImage {
    id

    # Content
    imgSubtitle
    imageFile {
      ...ImageFieldsQuery
    }
  }
`;

import React, { FocusEvent, ChangeEvent } from 'react';
import { Field, ErrorMessage } from 'formik';
import { Label } from '@rebass/forms';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
import {
  LabelText,
  OptionalText,
  Description,
  ErrorText,
  LabelGroup,
} from './FormField.styles';
import { Flex } from 'rebass';

type FormFieldProps = {
  component: React.ComponentType;
  children?: React.ReactNode;
  name: string;
  label?: string;
  required?: boolean;
  description?: React.ReactNode;
  validate?: any;
  type?: string;
  hideOptionalIndicator?: boolean;
  onFocus?: (event: FocusEvent<any>, form: any) => void;
  onChange?: (event: ChangeEvent<any>, form: any) => void;
  onBlur?: (event: FocusEvent<any>, form: any) => void;
};

export default function FormField({
  children,
  component,
  name,
  label,
  required,
  description,
  validate,
  type,
  hideOptionalIndicator,
  onFocus,
  onChange,
  onBlur,
  ...rest
}: FormFieldProps) {
  const t = useCraftTranslations();

  const _label = label || t(name);

  const validateRequired = (value: any) =>
    value ? undefined : `${_label} ${t('is required')}`;

  const _validate = validate || (required ? validateRequired : () => undefined);

  const Component = component;

  return (
    <Label px={1} py="13px" title={_label}>
      <LabelGroup css={{ display: type === 'hidden' ? 'none' : undefined }}>
        <Flex>
          <LabelText>{_label}</LabelText>
          {required || hideOptionalIndicator ? null : (
            <OptionalText>{t('Optional')}</OptionalText>
          )}
        </Flex>
        {description ? <Description>{description}</Description> : null}
      </LabelGroup>
      <Field
        {...rest}
        css={{ display: type === 'hidden' ? 'none' : undefined }}
        validate={_validate}
        name={name}
        render={({ field, form }: any) => {
          const touchedField = form.touched[field.name];
          const error = form.errors[field.name];

          return (
            <Component
              {...field}
              type={type}
              variant={touchedField ? (error ? 'invalid' : 'valid') : 'input'}
              onBlur={(e: FocusEvent) => {
                if (field.onBlur) field.onBlur(e);
                if (onBlur) onBlur(e, form);
              }}
              onFocus={onFocus}
              onChange={(e: ChangeEvent) => {
                if (field.onChange) field.onChange(e);
                if (onChange) onChange(e, form);
              }}
              rows={6}
            >
              {children}
            </Component>
          );
        }}
      />
      <ErrorMessage name={name} component={ErrorText} />
    </Label>
  );
}

export { FormFieldRow } from './FormField.styles';

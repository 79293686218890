import styled from '~/utils/styled';
import { Text, Flex } from 'rebass';
import media from '~/utils/mediaqueries';
import BaseIcon from '~/components/common/Icon';

export const IconListStyled = styled(Flex)({
  flexWrap: 'wrap',
});

export const IconItem = styled(Text)({
  display: 'inline-flex',
  alignItems: 'center',
  paddingBottom: '1.3333em',
  fontWeight: 600,
});

export const Icon = styled(BaseIcon)({});

export const TitleDesktop = styled('span')({
  display: 'none',

  [media('md')]: {
    display: 'inline',
  },
});

export const TitleMobile = styled('span')({
  display: 'inline',

  [media('md')]: {
    display: 'none',
  },
});

import styled from '~/utils/styled';
import { Radio, Label } from '@rebass/forms';
import { Flex, Box } from 'rebass';
import Icon from '../Icon';
import media from '~/utils/mediaqueries';

export const RadioGroup = styled(Flex)({
  flexWrap: 'wrap',
  justifyContent: 'center',
});

RadioGroup.defaultProps = {
  mx: -1,
};

export const RadioButton = styled(Box)<{ btnCount: number }>(
  ({ theme, btnCount }) => ({
    flex: '1 1 50%',
    maxWidth: '50%',
    [media('lg')]: {
      flex: btnCount % 2 ? '1 1 33.3333%' : '1 1 50%',
      maxWidth: btnCount % 2 ? '33.3333%' : '50%',
    },
    'input[type="radio"]:checked + &': {
      label: {
        borderColor: theme.colors.dim,
        boxShadow: '0 3px 6px rgba(0, 0, 0, .1)',
        opacity: 1,
      },
    },
  }),
);

RadioButton.defaultProps = {
  p: 1,
};

export const RadioButtonInner = styled(Label)(({ theme }) => ({
  border: `1px solid ${theme.colors.border}`,
  textAlign: 'center',
  cursor: 'pointer',
  borderRadius: 5,
  textTransform: 'uppercase',
  fontSize: theme.fontSizes[0],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  opacity: 0.7,
  fontWeight: 600,
}));

RadioButtonInner.defaultProps = {
  py: '25px',
  px: 1,
};

export const PaymentIcon = styled(Icon)(({ theme }) => ({
  fontSize: theme.fontSizes[5],
  paddingBottom: theme.space[1],
}));

export const RadioInput = styled('input')({
  visibility: 'hidden',
  width: 0,
  height: 0,
});

import React from 'react';
import { graphql } from 'gatsby';

import CraftComponents from '~/components/craft/CraftComponents';
import BlockContent from '~/components/common/BlockContent';
import { Craft_PageBuilderBlockSteps } from '~/query';
import Steps from '~/components/common/Steps';

/**
 * Available components.
 *
 * Defines available components
 * within this component.
 */
const availableComponents = {
  ElementHeadline: require('~/components/craft/CraftElementHeadline').default,
  ElementAnchor: require('~/components/craft/CraftElementAnchor').default,
};

type CraftBlockStepsProps = {
  data: Craft_PageBuilderBlockSteps;
};

export default function CraftBlockSteps({ data }: CraftBlockStepsProps) {
  const { children } = data;

  const steps = data.steps
    ? data.steps
        .map(step => {
          if (!step) return undefined;

          return {
            id: step.id || undefined,
            title: step.stepTitle || undefined,
            description: step.stepDescription
              ? {
                  content: step.stepDescription.content || undefined,
                }
              : undefined,
          };
        })
        .filter(Boolean)
    : [];

  return (
    <BlockContent>
      {children ? (
        <CraftComponents
          components={children}
          availableComponents={availableComponents}
          prefix={/Craft_PageBuilder(Blog)?/}
        />
      ) : null}
      <Steps steps={steps} />
    </BlockContent>
  );
}

export const query = graphql`
  fragment CraftPageBuilderBlockStepsQuery on Craft_PageBuilderBlockSteps {
    id
    # Content
    children {
      __typename
      # Content: Elements
      ...CraftPageBuilderElementHeadlineQuery
      ...CraftPageBuilderElementAnchorQuery
    }

    # Steps
    steps {
      __typename
      ... on Craft_StepsStep {
        id
        stepTitle
        stepDescription {
          content
        }
      }
    }
  }
`;

import React from 'react';
import { graphql } from 'gatsby';
import Calculator from '~/components/common/Calculator';
import { Craft_PageBuilderSegmentCalculator } from '~/query';
import Parser, { ALLOWED_TAGS_BLOCK } from '~/components/common/Parser';
import { useCraftMaterials } from '~/utils/hooks/useCraftMaterials';
import { useCraftGlobals } from '~/utils/hooks/useCraftGlobals';

type CraftSegmentCalculatorProps = {
  data: Craft_PageBuilderSegmentCalculator;
};

export default function CraftSegmentCalculator({
  data,
}: CraftSegmentCalculatorProps) {
  const { materials } = useCraftMaterials();
  const { globalCalculator } = useCraftGlobals();

  const {
    contractYears,
    calculatorRegistrationNumberInfo,
    calculatorQuoteTitle,
    calculatorQuoteLink,
    calculatorQuoteValue,
    calculatorQuoteMessage,
    calculatorPaymentMehodsImage,
    calculatorHeaderImage,
  } = globalCalculator ?? {};

  const { calculatorShowHeader, calculatorVariant } = data;

  const paymentIconsImage =
    (calculatorPaymentMehodsImage && calculatorPaymentMehodsImage[0]) ||
    undefined;

  const headerImage =
    (calculatorHeaderImage && calculatorHeaderImage[0]) || undefined;

  let _contractYears = [] as string[],
    _defaultContractYear: string | undefined,
    _contractYearsStartDateRequired = {} as { [year: string]: boolean };

  if (contractYears) {
    _contractYears = contractYears
      .map((contractYear: any) => {
        if (contractYear) {
          return String(contractYear.year);
        }
        return '';
      })
      .filter(Boolean);

    const defaultContractYear =
      contractYears.find((_: any) => _?.default)?.year ?? undefined;
    _defaultContractYear = defaultContractYear
      ? String(defaultContractYear)
      : undefined;

    _contractYearsStartDateRequired = contractYears.reduce(
      (aggregate, contractYear) => {
        if (contractYear) {
          return {
            ...aggregate,
            [String(contractYear.year)]: Boolean(
              contractYear.startDateRequired,
            ),
          };
        }
        return aggregate;
      },
      {} as typeof _contractYearsStartDateRequired,
    );
  }

  const registrationNumberInfo = (
    <Parser
      html={
        (calculatorRegistrationNumberInfo &&
          calculatorRegistrationNumberInfo.content) ||
        ''
      }
    />
  );

  // Quote object
  const quote = {
    limit: calculatorQuoteValue ? Number(calculatorQuoteValue) : undefined,
    title: calculatorQuoteTitle,
    message: (
      <Parser
        html={(calculatorQuoteMessage && calculatorQuoteMessage.content) || ''}
        allowedTags={ALLOWED_TAGS_BLOCK}
      />
    ),
    link: {
      url: calculatorQuoteLink?.url ?? undefined,
      text: calculatorQuoteLink?.text ?? undefined,
      target: calculatorQuoteLink?.target ?? undefined,
    },
  };

  return (
    <Calculator
      materials={materials}
      contractYears={_contractYears}
      contractYearsStartDateRequired={_contractYearsStartDateRequired}
      defaultContractYear={_defaultContractYear}
      registrationNumberInfo={registrationNumberInfo}
      quote={quote}
      showHeader={calculatorShowHeader ?? true}
      variant={calculatorVariant ?? 'imageList'}
      headerImage={headerImage}
      paymentIconsImage={paymentIconsImage}
    />
  );
}

export const query = graphql`
  fragment CraftSegmentCalculatorQuery on Craft_PageBuilderSegmentCalculator {
    id
    calculatorShowHeader
    calculatorVariant
  }
`;

import React from 'react';
import { Box } from 'rebass';
import Slider from 'react-slick';
import Button from '~/components/common/Button';
import CraftGatsbyImage from '~/components/craft/CraftGatsbyImage';

import { SliderWrapper, SlideWrapper } from './SlideShow.styles';

export default function SlideShow({
  images,
  height,
  options,
  children,
  initialSlide,
}: any) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    maxHeight: '100vh',
    prevArrow: (
      <Button
        iconName="ArrowLeft"
        hoverStyle="moveLeft"
        mobileWidth="auto"
        bg="rgba(255,255,255,0.5)"
        iconPosition=""
      />
    ),
    nextArrow: (
      <Button
        iconName="ArrowRight"
        mobileWidth="auto"
        bg="rgba(255,255,255,0.5)"
        iconPosition=""
      />
    ),
    accessibility: true,
    ...options,
  };

  return (
    <SliderWrapper height={height}>
      {children}
      <Slider {...settings}>
        {images.map((image: any) => {
          return (
            <Box key={image?.id}>
              <SlideWrapper height={height}>
                <CraftGatsbyImage fluid={image} objectFit="contain" />
              </SlideWrapper>
            </Box>
          );
        })}
      </Slider>
    </SliderWrapper>
  );
}

SlideShow.defaultProps = {
  images: null,
  height: 'full',
  options: null,
};

type SlideShow = {
  images: any;
  height: string;
  options: any;
};

import React from 'react';
import { graphql } from 'gatsby';

import Parser from '~/components/common/Parser';
import Button from '~/components/common/Button';

export default function CraftElementButton({ data }) {
  const { buttonLink, buttonVariant } = data;

  return (
    <Button
      to={buttonLink?.entry?.fullUri || buttonLink?.url}
      target={buttonLink?.target}
      variant={buttonVariant}
    >
      <Parser html={buttonLink?.customText || buttonLink?.text} />
    </Button>
  );
}

export const query = graphql`
  # Fragment for usage in PageBuilder
  fragment CraftPageBuilderElementButtonQuery on Craft_PageBuilderElementButton {
    id
    # Content
    buttonLink {
      entry {
        fullUri
      }
      url
      target
      text
      customText
    }
    buttonVariant
  }
`;

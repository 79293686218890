import React from 'react';
import { Box, Flex } from 'rebass';
import Accordion, { AccordionItem } from '../Accordion';
import {
  AccordionItemRenderProps,
  AccordionItemWrapperRenderProps,
} from '../Accordion/Accordion';
import Button from '../Button';
import Headline from '../Headline';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
import media from '~/utils/mediaqueries';
import CraftGatsbyImage from '~/components/craft/CraftGatsbyImage';

export default function AccordionReadMoreList({ children, ...rest }: any) {
  return (
    <Accordion {...rest}>
      <Flex flexWrap="wrap" mx={-5}>
        {children}
      </Flex>
    </Accordion>
  );
}

/**
 * The css is autogenerated for only `MAX_READ_MORE_LIST_ITEMS` items (see CMS
 * limit as well).
 *
 * We have a list of alternating accordion controls and contents.
 *
 * The list needs to be visually (!) reordered from:
 *  - control 1 (nth-of-type(1))
 *  - content 1 (nth-of-type(2))
 *
 *  - control 2 (nth-of-type(3))
 *  - content 2 (nth-of-type(4))
 *
 *  - control 3 (nth-of-type(5))
 *  - content 3 (nth-of-type(6))
 *
 *  - control 4 (nth-of-type(7))
 *  - content 4 (nth-of-type(8))
 *
 * to contain the first 2 controls then the first 2 contents:
 *  - control 1 (nth-of-type(1), order: 1)
 *  - control 2 (nth-of-type(3), order: 1)
 *  - content 1 (nth-of-type(2), order: 2)
 *  - content 2 (nth-of-type(4), order: 2)
 *
 *  - control 3 (nth-of-type(5), order: 3)
 *  - control 4 (nth-of-type(7), order: 3)
 *  - content 3 (nth-of-type(6), order: 4)
 *  - content 4 (nth-of-type(8), order: 4)
 *
 * The forumla below generates the nth-of-type()-order-combinations
 * progamatically.
 */
const MAX_READ_MORE_LIST_ITEMS = 20;

let controlOrder: { [nth: string]: { order: number } } = {};
let contentOrder: { [nth: string]: { order: number } } = {};

for (let i = 0; i < MAX_READ_MORE_LIST_ITEMS / 2; i++) {
  controlOrder[
    `&:nth-of-type(${(i + 1) * 4 - 3}), &:nth-of-type(${(i + 1) * 4 - 1})`
  ] = {
    order: (i + 1) * 2 - 1,
  };

  contentOrder[
    `&:nth-of-type(${(i + 1) * 4 - 2}), &:nth-of-type(${(i + 1) * 4})`
  ] = {
    order: (i + 1) * 2,
  };
}

export function AccordionReadMoreListItem({
  name,
  title,
  children,
  teaserText,
  teaserImage,
}: any) {
  const t = useCraftTranslations();
  return (
    <AccordionItem
      name={name}
      renderItem={({ control, content }: AccordionItemWrapperRenderProps) => (
        <>
          <Box
            sx={{
              px: 5,
              pb: 2,
              [media('lg')]: {
                flexBasis: '50%',
                maxWidth: '50%',
                ...controlOrder,
              },
            }}
          >
            {control}
          </Box>
          <Box
            sx={{
              px: 5,
              [media('lg')]: {
                flexBasis: '100%',
                maxWidth: '100%',
                ...contentOrder,
              },
            }}
          >
            <Box
              sx={{
                width: '100vw',
                marginLeft: -2,
                [media('md')]: {
                  marginLeft: -4,
                },
                [media('lg')]: {
                  marginLeft: -5,
                },
                [media('xl')]: {
                  marginLeft: -6,
                },
                [media('xxl')]: {
                  marginLeft: 'calc((1240px - 100vw)/2)',
                },
              }}
            >
              {content}
            </Box>
          </Box>
        </>
      )}
      renderControl={({
        name,
        handleControlClick,
        isOpened,
      }: AccordionItemRenderProps) => (
        <Flex flexDirection="column" sx={{ height: '100%' }}>
          <a id={name} />
          <Headline level="h4">{title}</Headline>
          {teaserImage ? (
            <CraftGatsbyImage fluid={teaserImage} aspectRatio={4} />
          ) : null}
          <Box mt={2}>{teaserText}</Box>
          <Box
            py={1}
            sx={{
              marginTop: 'auto',
              'button svg': {
                transform: isOpened ? 'rotate(45deg)' : 'rotate(0deg)',
                transition: 'transform .3s ease-in-out',
              },
            }}
          >
            <Button
              variant="toggle"
              onClick={() => {
                if (!isOpened) {
                  requestAnimationFrame(() => {
                    history.replaceState({}, '', `#${name}`);
                  });
                }
                handleControlClick();
              }}
              iconName="Plus"
              iconPosition="after"
              hoverStyle="none"
              iconSize="small"
            >
              {isOpened ? t('Close') : t('Read more')}
            </Button>
          </Box>
        </Flex>
      )}
    >
      <Box>{children}</Box>
    </AccordionItem>
  );
}

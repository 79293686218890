import { Flex, Box } from 'rebass';
import styled from '~/utils/styled';

import Button from '~/components/common/Button';
import media from '~/utils/mediaqueries';

export const DiscountInputFake = styled(Flex)(
  {
    borderRadius: 4,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    height: 56,
    fontSize: 13,
    letterSpacing: 0.3,
    marginBottom: 12,
    [media('lg')]: {
      marginBottom: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderRightWidth: 0,
    },
  },
  ({ theme }) => ({
    borderColor: theme.colors.headline,
  }),
);

export const DiscountLabel = styled(Box)({
  fontWeight: 700,
  letterSpacing: 0.3,
  paddingRight: 8,
  display: 'none',
  userSelect: 'none',
  [media(400)]: {
    display: 'block',
  },
});

export const DiscountCode = styled(Box)({
  letterSpacing: 0.3,
});

export const DiscountButton = styled(Button)({
  borderTopLeftRadius: 4,
  borderBottomLeftRadius: 4,
  [media('md')]: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
});

import React, { ChangeEvent } from 'react';
import { Field, ErrorMessage } from 'formik';
import { Box } from 'rebass';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
import { RadioGroup, RadioButton, RadioInput } from './FormRadio.styles';
import {
  LabelText,
  OptionalText,
  Description,
  ErrorText,
  LabelGroup,
} from '../FormField/FormField.styles';
import { Flex } from 'rebass';

type Options = {
  label: React.ReactNode;
  value: string;
};

type FormRadioProps = {
  options: Options[];
  name: string;
  label?: string;
  required?: boolean;
  description?: React.ReactNode;
  validate?: any;
  hideOptionalIndicator?: boolean;
  className?: string;
  radioVariant?: 'outline' | 'pills';
  onChange?: (event: React.ChangeEvent<any>, form: any) => void;
};

export default function FormRadio({
  options,
  name,
  label,
  required,
  description,
  validate,
  hideOptionalIndicator,
  onChange,
  className,
  radioVariant,
  ...rest
}: FormRadioProps) {
  const t = useCraftTranslations();

  const _label = label ?? t(name);

  const validateRequired = (value: any) =>
    value ? undefined : `${_label} ${t('is required')}`;

  const _validate = validate || (required ? validateRequired : () => undefined);

  return (
    <Box px={1} py="13px" width="100%" title={_label} className={className}>
      {_label ? (
        <LabelGroup>
          <Flex>
            <LabelText>{_label}</LabelText>
            {required || hideOptionalIndicator ? null : (
              <OptionalText>{t('Optional')}</OptionalText>
            )}
          </Flex>
          {description ? <Description>{description}</Description> : null}
        </LabelGroup>
      ) : null}

      <Field
        {...rest}
        validate={_validate}
        name={name}
        render={({ field, form }: any) => {
          return (
            <RadioGroup>
              {options.map(({ value, label }: any) => {
                const id = `${name}_${value}`;

                return (
                  <React.Fragment key={id}>
                    <RadioInput
                      {...field}
                      type="radio"
                      value={value}
                      id={id}
                      checked={field.value === value}
                      onChange={(e: any) => {
                        if (field.onChange) field.onChange(e);
                        if (onChange) onChange(e, form);
                      }}
                    />
                    <RadioButton htmlFor={id} radioVariant={radioVariant}>
                      {label}
                    </RadioButton>
                  </React.Fragment>
                );
              })}
            </RadioGroup>
          );
        }}
      />
      <ErrorMessage name={name} component={ErrorText} />
    </Box>
  );
}

import React from 'react';
import { graphql } from 'gatsby';
import slugify from '~/utils/slugify';

export default function CraftElementAnchor({ data }) {
  const { anchorNavName, anchorNavPath } = data;

  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      name={slugify(anchorNavPath || anchorNavName)}
      id={slugify(anchorNavPath || anchorNavName)}
    />
  );
}

export const query = graphql`
  # Fragment for usage in PageBuilder
  fragment CraftPageBuilderElementAnchorQuery on Craft_PageBuilderElementAnchor {
    id
    anchorNavName
    anchorNavPath
  }
`;

import styled from '~/utils/styled';
import { Flex, Box } from 'rebass';
import media from '~/utils/mediaqueries';

export const CalculatorStepSectionStyled = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  minHeight: '100vh',
  paddingTop: 100,
  [media('lg')]: {
    paddingTop: 175,
  },
}));

export const Step = styled(Box)(({ theme }) => ({
  borderRadius: 5,
  overflow: 'hidden',
}));

Step.defaultProps = {
  bg: 'background',
  color: 'text',
  mt: 1,
  mb: 2,
};

export const StepHeader = styled(Box)(({ theme }) => ({}));

StepHeader.defaultProps = {
  px: { _: 1, lg: 3 },
  py: 1,
  bg: 'tertiaryBright',
  color: 'tertiaryBrightText',
};

export const StepContent = styled(Box)(({ theme }) => ({}));

StepContent.defaultProps = {
  px: { _: 1, lg: 3 },
  py: { _: 1, lg: 2 },
};

import React from 'react';
import { graphql } from 'gatsby';

import CraftComponents from '~/components/craft/CraftComponents';
import BlockImageText from '~/components/common/BlockImageText';
import { Craft_PageBuilderBlockImageText } from '~/query';

/**
 * Available components.
 *
 * Defines available components
 * within this component.
 */
const availableComponents = {
  ElementHeadline: require('~/components/craft/CraftElementHeadline').default,
  ElementText: require('~/components/craft/CraftElementText').default,
  ElementImage: require('~/components/craft/CraftElementImage').default,
  ElementButtonGroup: require('~/components/craft/CraftElementButtonGroup')
    .default,
  ElementIconList: require('~/components/craft/CraftElementIconList').default,
  ElementDownloads: require('~/components/craft/CraftElementDownloads').default,
  ElementFormContact: require('~/components/craft/CraftElementFormContact')
    .default,
  ElementAnchor: require('~/components/craft/CraftElementAnchor').default,
  ElementContactInfo: require('~/components/craft/CraftElementContactInfo')
    .default,
};

type CraftBlockImageTextProps = {
  data: Craft_PageBuilderBlockImageText;
};

export default function CraftBlockImageText({
  data,
}: CraftBlockImageTextProps) {
  const {
    children,
    imageTextImage,
    imageTextImageFit,
    imageTextImagePositionY,
    imageTextImageRemovePadding,
    imageTextFlip,
    imageTextStretch,
    imageTextSticky,
  } = data;

  return (
    <BlockImageText
      flip={Boolean(imageTextFlip)}
      stretch={Boolean(imageTextStretch)}
      sticky={Boolean(imageTextSticky)}
      imageRemovePadding={Boolean(imageTextImageRemovePadding)}
      image={imageTextImage ? imageTextImage[0] : undefined}
      imageFit={imageTextImageFit ? imageTextImageFit : undefined}
      imagePositionY={
        imageTextImagePositionY ? imageTextImagePositionY : undefined
      }
    >
      <CraftComponents
        components={children}
        availableComponents={availableComponents}
        prefix={/Craft_PageBuilder(Blog)?/}
      />
    </BlockImageText>
  );
}

export const query = graphql`
  fragment CraftPageBuilderBlockImageTextQuery on Craft_PageBuilderBlockImageText {
    id
    # Content
    children {
      __typename
      # Content: Elements
      ...CraftPageBuilderElementImageQuery
      ...CraftPageBuilderElementHeadlineQuery
      ...CraftPageBuilderElementTextQuery
      ...CraftPageBuilderElementButtonGroupQuery
      ...CraftPageBuilderElementIconListQuery
      ...CraftPageBuilderElementDownloadsQuery
      ...CraftPageBuilderElementFormContactQuery
      ...CraftPageBuilderElementAnchorQuery
      ...CraftPageBuilderElementContactInfoQuery
    }

    # Appearance
    imageTextImageFit
    imageTextImagePositionY
    imageTextImageRemovePadding
    imageTextImage {
      ...CraftImageFluid
    }
    imageTextFlip
    imageTextStretch
    imageTextSticky
  }
`;

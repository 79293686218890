import { request, PayoneResponse } from './request';
import { useState, useEffect } from 'react';
import { countries } from '../calculator';

type ManageMandateData = {
  lastname?: string;
  firstname?: string;
  company?: string;
  street?: string;
  zip?: string;
  city: string;
  country: string;
  email: string;
  language: string;
  iban?: string;
  bic?: string;
};

export async function manageMandate(
  data: ManageMandateData,
  onRequest: () => any,
) {
  if (!data.lastname || !data.iban) {
    return;
  }
  onRequest();
  return request({
    request: 'managemandate',
    hash: 'c1ac7723be42530712c81f69f399ed84',
    data: {
      clearingtype: 'elv',
      currency: 'EUR',
      ...data,
      country: countries[data.country]._iso ?? data.country ?? undefined,
    },
  });
}

export function useMandate(data: ManageMandateData) {
  const [mandateResponse, setMandateResponse] = useState<PayoneResponse>();
  const [mandateLoading, setMandateLoading] = useState(false);

  useEffect(() => {
    let didCancel = false;

    (async () => {
      try {
        setMandateResponse(undefined);
        const res = await manageMandate(data, () => {
          setMandateLoading(true);
        });
        if (!didCancel) {
          if (res) {
            setMandateResponse(res);
          } else {
            setMandateResponse({ status: 'ERROR' });
          }
        }
      } catch (o_O) {
        if (!didCancel) {
          setMandateResponse({ status: 'ERROR' });
        }
      } finally {
        setMandateLoading(false);
      }
    })();

    return () => {
      didCancel = true;
      setMandateLoading(false);
    };
  }, [data, setMandateResponse]);

  return [mandateResponse, mandateLoading] as [PayoneResponse, boolean];
}

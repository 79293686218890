import styled from '~/utils/styled';
import { Flex, Box } from 'rebass';

import media from '~/utils/mediaqueries';

export const SortsSliderStyled = styled(Box)({
  position: 'relative',
  width: '100%',
});

export const ScrollControls = styled(Box)({
  position: 'relative',
  paddingTop: '1rem',
  textAlign: 'right',

  [media('sm')]: {
    position: 'absolute',
    right: 0,
    top: 0,
    paddingBottom: '1rem',
    paddingTop: '0',
    transform: 'translate(0, -100%)',
  },

  'button + button': {
    marginLeft: '1rem',
  },
});

export const ScrollContainer = styled(Flex)(({ theme }) => ({
  overflowX: 'auto',
  scrollSnapType: 'x mandatory',
  WebkitOverflowScrolling: 'touch',
  width: '100%',

  '& > div:last-child': {
    paddingRight: 0,
  },

  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 8px 0 rgba(0,0,0,0.1)',
  },

  '&::-webkit-scrollbar': {
    height: 8,
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.colors.primary,
    borderRadius: 2,
  },
}));

export const ScrollChild = styled(Box)({
  flex: 'none',
  scrollSnapAlign: 'center',
  scrollSnapStop: 'always',
  width: '100%',

  [media('sm')]: {
    width: 'auto',
    scrollSnapAlign: 'start',
  },
});

import React from 'react';
import { Box } from 'rebass';
import { IconNames } from '~/components/common/Icon/Icon';

import { BlockLogoStyled, Decoration, Icon } from './BlockLogo.styles';

type BlockContentProps = {
  children?: any;
  icon?: IconNames;
};

export default function BlockContent({ children, icon }: BlockContentProps) {
  const iconName = icon ?? 'LogoLarge';
  return (
    <BlockLogoStyled flexWrap="wrap">
      <Decoration width={{ _: 1, sm: 1, md: 1, lg: 2 / 5, xl: 2 / 5 }}>
        <Icon name={iconName} scaleUp={iconName === 'LogoLarge'} />
      </Decoration>
      <Box width={{ _: 1, sm: 1, md: 1, lg: 3 / 5, xl: 3 / 5 }} py={{ _: 5 }}>
        {children}
      </Box>
    </BlockLogoStyled>
  );
}

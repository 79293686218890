import styled from '~/utils/styled';
import { Box } from 'rebass';
import CraftGatsbyImage from '~/components/craft/CraftGatsbyImage';
import media from '~/utils/mediaqueries';

type CalculatorMaterialStyledProps = {
  dim: boolean;
};

export const CalculatorMaterialStyled = styled(Box)<
  CalculatorMaterialStyledProps
>(({ theme, dim }) => ({
  position: 'relative',
  maxWidth: 300,
  transition:
    'opacity 500ms cubic-bezier(0.4, 0, 0.2, 1), transform 500ms cubic-bezier(0.4, 0, 0.2, 1)',
  opacity: dim ? 0.5 : 1,
  transform: dim ? 'scale(0.97)' : 'scale(1)',
  margin: '0 auto',
  [media('lg')]: {
    margin: 0,
  },
  '&:hover, &:focus-within': {
    opacity: 1,
    transform: 'scale(1)',
  },
}));

export const ImageBox = styled(CraftGatsbyImage)(({}) => ({
  borderRadius: 5,
  width: '70%',
  height: 222,
  [media('lg')]: {
    height: 333,
  },
}));

type ContentBoxProps = {
  showInfo: boolean;
};

export const ContentBox = styled(Box)<ContentBoxProps>(({ showInfo }) => ({
  position: 'absolute',
  right: 0,
  bottom: 30,
  width: '81%',
  [media('lg')]: {
    width: '77%',
  },
  transform: showInfo ? 'translate3d(0, 60px, 0)' : 'translate3d(0, 0, 0)',
  transition: 'transform 500ms cubic-bezier(0.4, 0, 0.2, 1)',
}));

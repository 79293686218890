import React, { useContext } from 'react';
import { Input } from '@rebass/forms';
import FormField, { FormFieldRow } from '~/components/common/FormField';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
// import { checkVAT, countries as vatCountries } from 'jsvat';
import InfoBox from '~/components/common/InfoBox';
import { countries } from '~/utils/calculator';
import { connect } from 'formik';
import { Validate } from '../../../common/Wizard/Wizard';
import { CalculatorWizardStep01CompanyDataValues } from '../CalculatorWizardStep01CompanyData/CalculatorWizardStep01CompanyData';
import { PageContext } from '~/components/common/Layout';

const CalculatorWizardStep02TaxDataImpl = connect<
  {},
  CalculatorWizardStep02TaxDataValues & CalculatorWizardStep01CompanyDataValues
>(function CalculatorWizardStep02TaxDataImpl({ formik }) {
  const t = useCraftTranslations();


  const pageLanguage = useContext(PageContext).language;
  const language =
    pageLanguage === 'de' || pageLanguage === 'en' ? pageLanguage : 'en';

  const country = formik.values?.companyData?.country;
  const EUVAT = countries[country]?._opts.EUVAT ?? false;
  const countryName = countries[country]?.[language] ?? false;

  if (!EUVAT) {
    return (
      <InfoBox>
        {t(
          'Customers from %s are not required to provide further tax information. Please click "Continue".',
        ).replace(/%s/, countryName)}
      </InfoBox>
    );
  }

  const EUVATNumber = formik.values?.taxData?.EUVATNumber ?? '';
  const DETaxNumber = formik.values?.taxData?.DETaxNumber ?? '';

  // function validateEUVATNumber(EUVATNumber: any) {
  //   if (!EUVATNumber && !DETaxNumber) {
  //     return `${t('taxData.EUVATNumber')} ${t('is required if')} ${t(
  //       'taxData.DETaxNumber',
  //     )} ${t('is not provided')}`;
  //   } else if (
  //     EUVATNumber &&
  //     !checkVAT(String(EUVATNumber), vatCountries).isValid
  //   ) {
  //     return `${t('taxData.EUVATNumber')} ${t('is invalid')}`;
  //   }
  // }

  // function validateDETaxNumber(DETaxNumber: any) {
  //   if (!EUVATNumber && !DETaxNumber) {
  //     return `${t('taxData.DETaxNumber')} ${t('is required if')} ${t(
  //       'taxData.EUVATNumber',
  //     )} ${t('is not provided')}`;
  //   }
  // }

  return (
    <>
      <InfoBox>
        {t(
          'Mit einem Firmensitz in Deutschland können Sie alternativ zur Ust-Id. Nr auch Ihre Steuernummer angeben.',
        )}
      </InfoBox>
      <FormFieldRow>
        <FormField
          name="taxData.EUVATNumber"
          type="text"
          component={Input}
          //validate={validateEUVATNumber}
          hideOptionalIndicator
          onChange={(e: any, form: any) => {
            form.setFieldValue(
              'taxData.EUVATNumber',
              e.target.value.replace(/\s/g, ''),
            );
          }}
        />

        <FormField
          name="taxData.DETaxNumber"
          type="text"
          component={Input}
          // validate={validateDETaxNumber}
          hideOptionalIndicator
        />
      </FormFieldRow>

      <FormFieldRow>
        <FormField
          name="taxData.commerceNumber"
          type="text"
          component={Input}
        />
      </FormFieldRow>
    </>
  );
});

export default function CalculatorWizardStep02TaxData() {
  return <CalculatorWizardStep02TaxDataImpl />;
}

/**
 * The initial values for this component are aggregated
 * into the main wizard component
 */
export type CalculatorWizardStep02TaxDataValues = {
  taxData: {
    EUVATNumber: string;
    DETaxNumber: string;
    commerceNumber: string;
  };
};

CalculatorWizardStep02TaxData.initialValues = {
  taxData: {
    EUVATNumber: '',
    DETaxNumber: '',
    commerceNumber: '',
  },
} as CalculatorWizardStep02TaxDataValues;

CalculatorWizardStep02TaxData.title = 'Step 2';
CalculatorWizardStep02TaxData.description = 'Company information';

CalculatorWizardStep02TaxData.validate = undefined as Validate;

import React, { FocusEvent, ChangeEvent } from 'react';
import { Field, ErrorMessage } from 'formik';
import { Label } from '@rebass/forms';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
import {
  LabelText,
  OptionalText,
  Description,
  LabelGroup,
  ErrorText,
} from '../FormField/FormField.styles';
import { Flex } from 'rebass';
import { useDebouncedCallback } from 'use-debounce';

type FieldProps = React.ComponentProps<typeof Field>;

type FormFieldPseudoProps = {
  children?: React.ReactNode;
  label: string;
  optional?: boolean;
  description?: React.ReactNode;
  error?: string;
} & FieldProps;

export default function FormFieldPseudo({
  children,
  label,
  optional,
  description,
  error,
}: FormFieldPseudoProps) {
  const t = useCraftTranslations();

  return (
    <Label px={1} py="13px" title={label}>
      <LabelGroup>
        <Flex>
          <LabelText>{label}</LabelText>
          {optional ? <OptionalText>{t('Optional')}</OptionalText> : null}
        </Flex>
        {description ? <Description>{description}</Description> : null}
      </LabelGroup>
      {children}
      {error ? <ErrorText>{error}</ErrorText> : null}
    </Label>
  );
}

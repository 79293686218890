import styled from '~/utils/styled';
import { Flex, Box, Text } from 'rebass';
import media from '~/utils/mediaqueries';
import Headline from '~/components/common/Headline';

export const StepsStyled = styled(Flex)({
  textAlign: 'left',
  counterReset: 'stepCount',
  flexWrap: 'wrap',
  justifyContent: 'center',
});

export const Step = styled(Box)(({ theme }) => ({
  counterIncrement: 'stepCount',
  position: 'relative',
  textAlign: 'center',
  flexBasis: '100%',
  flexShrink: 1,
  flexGrow: 0,

  [media('lg')]: {
    flexBasis: '33.333%',
    maxWidth: '33.333%',
    textAlign: 'left',
  },

  '&::before': {
    content: 'counter(stepCount, decimal-leading-zero)',
    position: 'absolute',
    color: theme.colors.border,
    fontSize: '6rem',
    fontWeight: 500,
    top: '-3.2rem',
    left: '50%',
    transform: 'translateX(-50%)',
    fontFamily: theme.fonts.headline,
    lineHeight: 1,

    [media('lg')]: {
      transform: 'translateX(0)',
      left: '0',
      fontSize: '5.5rem',
      top: '-3rem',
    },
  },
}));

export const InnerStep = styled(Text)(({ theme }) => ({
  zIndex: 1,
  position: 'relative',
  paddingLeft: '1rem',
  paddingRight: '1rem',

  [media('md')]: {
    paddingLeft: '3rem',
    paddingRight: '3rem',
  },

  [media('lg')]: {
    paddingLeft: '4rem',
    paddingRight: 0,
  },
}));

export const Link = styled(Box)(({ theme }) => ({
  display: 'block',
  textDecoration: 'none',
}));

export const Title = styled(Headline)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 700,
  fontFamily: theme.fonts.headline,
  marginTop: 0,

  [media('xl')]: {
    marginTop: 0,
  },
}));

export const Arrow = styled(Text)(({ theme }) => ({
  position: 'relative',
  fontSize: '3.5rem',
  color: theme.colors.border,
  display: 'none',
  lineHeight: 1,
  marginTop: '-0.8rem',

  [media('lg')]: {
    display: 'block',
  },
}));

export const Description = styled(Box)({});

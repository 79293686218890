import React from 'react';
import { graphql } from 'gatsby';

import CraftComponents from '~/components/craft/CraftComponents';
import BlockContent from '~/components/common/BlockContent';
import IconBlocks from '~/components/common/IconBlocks';
import Parser from '~/components/common/Parser';
import { IconNames } from '~/components/common/Icon/Icon';
import { Craft_PageBuilderBlockIcons, Craft_IconlistIcon } from '~/query';

/**
 * Available components.
 *
 * Defines available components
 * within this component.
 */
const availableComponents = {
  ElementHeadline: require('~/components/craft/CraftElementHeadline').default,
  ElementAnchor: require('~/components/craft/CraftElementAnchor').default,
};

type CraftBlockIconsProps = {
  data: Craft_PageBuilderBlockIcons;
};

export default function CraftBlockIcons({ data }: CraftBlockIconsProps) {
  const { children } = data;

  // Extract the icons
  const iconListRaw = (data?.iconlist ?? []) as Craft_IconlistIcon[];
  const iconList = iconListRaw.map(({ iconTitle, iconName, description }) => ({
    iconName: iconName as IconNames,
    iconTitle: iconTitle ? <Parser html={iconTitle} /> : null,
    iconDescription: description ? <Parser html={description} /> : null,
  }));

  return (
    <BlockContent>
      <CraftComponents
        components={children}
        availableComponents={availableComponents}
        prefix={/Craft_PageBuilder(Blog)?/}
      />
      <IconBlocks items={iconList} />
    </BlockContent>
  );
}

export const query = graphql`
  fragment CraftPageBuilderBlockIconsQuery on Craft_PageBuilderBlockIcons {
    id
    # Content
    children {
      __typename
      # Content: Elements
      ...CraftPageBuilderElementHeadlineQuery
      ...CraftPageBuilderElementAnchorQuery
    }
    iconlist {
      ... on Craft_IconlistIcon {
        id
        iconTitle
        iconName
        description
      }
    }
  }
`;

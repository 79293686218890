import React from 'react';

import {
  FactsStyled,
  Fact,
  FactText,
  FactNumber,
  FactsDate,
} from './Facts.styles';

import { Flex, Box } from 'rebass';

export type FactsItem =
  | {
      id?: string;
      number?: string;
      text?: string;
    }
  | undefined;

export type FactsProps = {
  facts?: FactsItem[];
  factsDate?: string | null | undefined;
};

export default function Facts({ facts, factsDate }: FactsProps) {
  const filteredFacts = facts ? facts.filter(Boolean) : [];
  let j = 0;
  let k = false;

  return (
    <FactsStyled
      flexWrap="wrap"
      flexDirection={{ _: 'column', lg: 'row' }}
      alignItems={{ _: 'center', lg: 'flex-start' }}
    >
      {filteredFacts.map((fact, i) => {
        if (j === 2) {
          k = !k;
          j = 0;
        }
        j++;

        if (!fact) return null;

        return (
          <Fact
            key={fact.id}
            py={1}
            width={{ _: 1, sm: 1 / 2, lg: 1 / 2 }}
            alignItems="center"
          >
            <FactNumber mb={0} mt={0} mr={1} colorize={k}>
              {fact.number}
            </FactNumber>
            <FactText>{fact.text}</FactText>
          </Fact>
        );
      })}
      <FactsDate width={{ _: 1 }}>{factsDate}</FactsDate>
    </FactsStyled>
  );
}

import React from 'react';
import { Box } from 'rebass';
import { graphql } from 'gatsby';

import Parser from '~/components/common/Parser';
import Headline from '~/components/common/Headline';

export default function CraftElementHeadline({ data }) {
  const {
    headlineText,
    headlineElement,
    headlineElementSemantic,
    headlineStyle,
    headlineAlignment,
  } = data;

  return (
    <Box sx={{ textAlign: headlineAlignment || undefined }}>
      <Headline
        level={headlineElement || undefined}
        tag={headlineElementSemantic || undefined}
        appearance={headlineStyle || undefined}
      >
        <Parser html={headlineText?.content} />
      </Headline>
    </Box>
  );
}

export const query = graphql`
  # Fragment for usage in PageBuilder
  fragment CraftPageBuilderElementHeadlineQuery on Craft_PageBuilderElementHeadline {
    id
    # Content
    headlineText {
      content
    }
    # Appearance
    headlineElement
    headlineElementSemantic
    headlineAlignment
  }
  fragment CraftPageBuilderBlogElementHeadlineQuery on Craft_PageBuilderBlogElementHeadline {
    id
    # Content
    headlineText {
      content
    }
    # Appearance
    headlineElement
    headlineElementSemantic
  }
`;

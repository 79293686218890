import React from 'react';
import { Box, Flex } from 'rebass';
import {
  StepIndicator,
  StepDot,
  StepLine,
  StepText,
  StepTitle,
  StepDescription,
} from './ProgressStepper.styles';

type Step = {
  title: string;
  description: string;
  isActive: boolean;
  isCompleted: boolean;
};

type ProgressStepperProps = {
  steps: Step[];
};

export default function ProgressStepper({ steps }: ProgressStepperProps) {
  return (
    <Box mt={-2}>
      {steps.map(({ title, description, isActive, isCompleted }, index) => (
        <Flex mt={2} key={`${title}_${description}`}>
          <StepIndicator mb={-2}>
            <StepLine
              isLastStep={index === steps.length - 1}
              nextIsActiveOrCompleted={
                steps[index + 1]
                  ? steps[index + 1].isActive || steps[index + 1].isCompleted
                  : false
              }
            />
            <StepDot isActive={isActive} isCompleted={isCompleted} />
          </StepIndicator>
          <StepText
            ml={3}
            isActive={isActive}
            isCompleted={isCompleted}
            fontSize={1}
          >
            <StepTitle isActive={isActive}>{title}</StepTitle>
            <StepDescription>{description}</StepDescription>
          </StepText>
        </Flex>
      ))}
    </Box>
  );
}

import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { Box } from 'rebass';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
import {
  RadioGroup,
  RadioButton,
  RadioButtonInner,
  RadioInput,
  PaymentIcon,
} from './FormRadioPayment.styles';
import { ErrorText } from '../FormField/FormField.styles';
import { IconNames } from '../Icon/Icon';

type FieldProps = React.ComponentProps<typeof Field>;

type Options = {
  label: React.ReactNode;
  iconName?: IconNames;
  value: string;
};

type FormRadioPaymentProps = {
  options: Options[];
  name: string;
  label?: string;
  required?: boolean;
  description?: React.ReactNode;
  validate?: any;
  hideOptionalIndicator?: boolean;
};

export default function FormRadioPayment({
  options,
  name,
  label,
  required,
  description,
  validate,
  hideOptionalIndicator,
  ...rest
}: FormRadioPaymentProps) {
  const t = useCraftTranslations();

  const _label = label || t(name);

  const validateRequired = (value: any) =>
    value ? undefined : `${_label} ${t('is required')}`;

  const _validate = validate || (required ? validateRequired : () => undefined);

  return (
    <Box px={1} py="13px" width="100%" title={_label}>
      <Field
        {...rest}
        validate={_validate}
        name={name}
        render={({ field }: any) => {
          return (
            <RadioGroup>
              {options.map(({ value, label, iconName }) => {
                const id = `${name}_${value}`;
                return (
                  <React.Fragment key={id}>
                    <RadioInput
                      {...field}
                      type="radio"
                      value={value}
                      id={id}
                      checked={field.value === value}
                    />
                    <RadioButton btnCount={options.length}>
                      <RadioButtonInner htmlFor={id}>
                        {iconName ? <PaymentIcon name={iconName} /> : null}
                        {label}
                      </RadioButtonInner>
                    </RadioButton>
                  </React.Fragment>
                );
              })}
            </RadioGroup>
          );
        }}
      />
      <ErrorMessage name={name} component={ErrorText} />
    </Box>
  );
}

import React from 'react';
import { graphql } from 'gatsby';
import kebabCase from 'lodash.kebabcase';
import ButtonGroup, { ButtonGroupItem } from '~/components/common/ButtonGroup';
import { Craft_PageBuilderElementButtonGroup } from '~/query';
import CraftElementButton from '../CraftElementButton/CraftElementButton';

type CraftElementButtonGroupProps = {
  data: Craft_PageBuilderElementButtonGroup;
};

export default function CraftElementButtonGroup({
  data,
}: CraftElementButtonGroupProps) {
  const { children, justifyContent } = data;
  if (!children || !children.length) return null;

  return (
    <ButtonGroup
      justifyContent={kebabCase(justifyContent) || undefined}
      py={data?.py}
    >
      {children.map((buttonData, index) => {
        if (
          buttonData &&
          buttonData.__typename === 'Craft_PageBuilderElementButton'
        ) {
          return (
            <ButtonGroupItem key={buttonData.id || index}>
              <CraftElementButton data={buttonData} />
            </ButtonGroupItem>
          );
        }
        return null;
      })}
    </ButtonGroup>
  );
}

export const query = graphql`
  fragment CraftPageBuilderElementButtonGroupQuery on Craft_PageBuilderElementButtonGroup {
    id
    py
    # Content
    children {
      __typename
      ... on Craft_PageBuilderElementButton {
        id
      }
      # Content: Elements
      ...CraftPageBuilderElementButtonQuery
    }

    justifyContent
  }
`;

import React from 'react';
import { graphql } from 'gatsby';
import Snackbar from '~/components/common/Snackbar';
import { Craft_PageBuilderSegmentSnackbar } from '~/query';
import Parser, { ALLOWED_TAGS_BLOCK } from '~/components/common/Parser';

type CraftSegmentSnackbarProps = {
  data: Craft_PageBuilderSegmentSnackbar;
};

export default function CraftSegmentSnackbar({
  data,
}: CraftSegmentSnackbarProps) {
  const {
    id,
    snackbarAutoHideDuration,
    snackbarContent,
    snackbarVariant,
    snackbarShowAgainAfter,
  } = data;

  return (
    <Snackbar
      // convert seconds to ms
      autoHideDuration={
        snackbarAutoHideDuration ? snackbarAutoHideDuration * 1e4 : undefined
      }
      variant={snackbarVariant ?? undefined}
      id={id ?? 'snackbar'}
      // convert hours to ms
      showAgainAfter={
        snackbarShowAgainAfter ? snackbarShowAgainAfter * 3.6e6 : undefined
      }
    >
      <Parser
        html={snackbarContent?.content ?? ''}
        allowedTags={ALLOWED_TAGS_BLOCK}
      />
    </Snackbar>
  );
}

export const query = graphql`
  fragment CraftSegmentSnackbarQuery on Craft_PageBuilderSegmentSnackbar {
    id
    snackbarAutoHideDuration
    snackbarContent {
      content
    }
    snackbarVariant
    snackbarShowAgainAfter
  }
`;

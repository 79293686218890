import React, { useState } from 'react';
import {
  BackButton,
  BackIcon,
} from './CalculatorWizardActiveStepSection.styles';
import { useWizard } from '~/components/common/Wizard';
import Button from '~/components/common/Button';
import ButtonGroup, { ButtonGroupItem } from '~/components/common/ButtonGroup';
import Icon from '~/components/common/Icon';
import { ShowDesktop, ShowMobile } from '../CalculatorMediaUtils';
import { useCraftTranslations } from '~/utils/hooks/useCraftGlobals';
import ProgressStepper from '~/components/common/ProgressStepper';
import CalculatorStepSection from '../CalculatorStepSection';
import { ApolloError } from 'apollo-client';
import FormikErrors from '~/components/common/FormikErrors';

type CalculatorWizardActiveStepSectionProps = {
  onBackButtonClick: React.MouseEventHandler;
  requestPaymentError?: ApolloError;
  externalPaymentError?: boolean;
};

export default function CalculatorWizardActiveStepSection({
  onBackButtonClick,
  requestPaymentError,
  externalPaymentError,
}: CalculatorWizardActiveStepSectionProps) {
  const {
    activeStep,
    activeStepIndex,
    activeStepAttributes,
    previous,
    isLastStep,
    formikProps,
    stepAttributes,
  } = useWizard();

  const [showSteps, setShowSteps] = useState(false);

  const t = useCraftTranslations();

  return (
    <CalculatorStepSection
      headline={t('Licensing')}
      subHeadline={`${activeStepAttributes.title}: ${activeStepAttributes.description}`}
      headerContent={
        <ShowMobile>
          <Button
            variant="toggle"
            type="button"
            onClick={() => setShowSteps(!showSteps)}
            iconName="none"
          >
            {showSteps ? t('Hide steps') : t('Show steps')}
          </Button>
        </ShowMobile>
      }
      aboveSectionContent={
        <BackButton onClick={onBackButtonClick}>
          <BackIcon>
            <Icon name="ChevronLeft" />
          </BackIcon>
          {t('Back to calculator')}
        </BackButton>
      }
    >
      {showSteps ? (
        <ShowMobile>
          <ProgressStepper steps={stepAttributes} />
        </ShowMobile>
      ) : null}
      <FormikErrors
        errors={[
          requestPaymentError && requestPaymentError.message
            ? {
                message:
                  (requestPaymentError.graphQLErrors &&
                    requestPaymentError.graphQLErrors[0] &&
                    requestPaymentError.graphQLErrors[0].message) ||
                  requestPaymentError.message,
              }
            : undefined,
          externalPaymentError
            ? {
                message:
                  'The payment provider aborted the payment process. Please try again.',
              }
            : undefined,
        ]}
      />
      {activeStep}
      <ButtonGroup justifyContent="flex-end">
        <ShowDesktop width="44%">
          {activeStepIndex > 0 && (
            <ButtonGroupItem width="100%">
              <Button
                width="100%"
                iconName="none"
                textTransform="uppercase"
                type="button"
                onClick={previous}
                variant="primary"
              >
                {t('Back')}
              </Button>
            </ButtonGroupItem>
          )}{' '}
        </ShowDesktop>
        {!isLastStep && (
          <ButtonGroupItem width="56%">
            <Button
              width="100%"
              iconName="none"
              textTransform="uppercase"
              type="submit"
              variant="filled"
            >
              {t('Continue')}
            </Button>
          </ButtonGroupItem>
        )}
        <ShowMobile width="100%">
          {activeStepIndex > 0 && (
            <ButtonGroupItem width="100%">
              <Button
                width="100%"
                iconName="none"
                textTransform="uppercase"
                type="button"
                onClick={previous}
                variant="primary"
              >
                {t('Back')}
              </Button>
            </ButtonGroupItem>
          )}
        </ShowMobile>
        {isLastStep && (
          <ButtonGroupItem width="56%">
            <Button
              width="100%"
              iconName={
                formikProps && formikProps.isSubmitting
                  ? 'none'
                  : 'ChevronRight'
              }
              textTransform="uppercase"
              type="submit"
              variant="filled"
              disabled={formikProps && formikProps.isSubmitting} //  || (formikProps && !formikProps.isValid)
            >
              {formikProps && formikProps.isSubmitting
                ? t('Preparing payment…')
                : t('Prepare payment')}
            </Button>
          </ButtonGroupItem>
        )}
      </ButtonGroup>
    </CalculatorStepSection>
  );
}

import React from 'react';
import { graphql } from 'gatsby';

import BlockLogo from '~/components/common/BlockLogo';
import CraftComponents from '~/components/craft/CraftComponents';
import { Craft_PageBuilderBlockLogo } from '~/query';

type CraftBlockLogoProps = {
  data: Craft_PageBuilderBlockLogo;
};

export default function CraftBlockLogo({ data }: CraftBlockLogoProps) {
  const { children, icon } = data;

  const availableComponents = {
    ElementHeadline: require('~/components/craft/CraftElementHeadline').default,
    ElementText: require('~/components/craft/CraftElementText').default,
    ElementContactInfo: require('~/components/craft/CraftElementContactInfo')
      .default,
  };

  return (
    <BlockLogo icon={icon ?? undefined}>
      <CraftComponents
        components={children}
        availableComponents={availableComponents}
        prefix="Craft_PageBuilder"
      />
    </BlockLogo>
  );
}

export const query = graphql`
  fragment CraftPageBuilderBlockLogoQuery on Craft_PageBuilderBlockLogo {
    id
    icon
    # Content
    children {
      __typename
      # Content: Elements
      ...CraftPageBuilderElementHeadlineQuery
      ...CraftPageBuilderElementTextQuery
      ...CraftPageBuilderElementContactInfoQuery
    }
  }
`;

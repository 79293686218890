import React from 'react';
import { graphql } from 'gatsby';

import {
  Craft_SortsSort,
  Craft_PageBuilderBlockSorts,
} from '~/query';

import CraftComponents from '~/components/craft/CraftComponents';
import BlockContent from '~/components/common/BlockContent';
import SortsSlider from '~/components/common/SortsSlider';

/**
 * Available components.
 *
 * Defines available components
 * within this component.
 */
const availableComponents = {
  ElementHeadline: require('~/components/craft/CraftElementHeadline').default,
  ElementAnchor: require('~/components/craft/CraftElementAnchor').default,
};

type CraftBlockSortsProps = {
  data: Craft_PageBuilderBlockSorts;
};

export default function CraftBlockSorts({ data }: CraftBlockSortsProps) {
  const { children } = data;

  const definedItems = data.sortItems
    ? data.sortItems
        .map((sort: any) => {
          if (!sort) return undefined;

          return {
            id: sort.id || undefined,
            title: sort.sortTeaserTitle || undefined,
            text: sort.sortTeaserText || undefined,
            image: sort.sortTeaserImage[0]
              ? sort.sortTeaserImage[0]
              : undefined,
            link: sort.sortTeaserLink
              ? {
                  target: sort.sortTeaserLink.target || undefined,
                  text: sort.sortTeaserLink.text || undefined,
                  url: sort.sortTeaserLink.url || undefined,
                }
              : undefined,
          };
        })
        .filter(Boolean)
    : [];

  return (
    <BlockContent>
      {children ? (
        <CraftComponents
          components={children}
          availableComponents={availableComponents}
          prefix={/Craft_PageBuilder(Blog)?/}
        />
      ) : null}
      <SortsSlider items={definedItems} />
    </BlockContent>
  );
}

export const query = graphql`
  fragment CraftPageBuilderBlockSortsQuery on Craft_PageBuilderBlockSorts {
    id
    # Content
    children {
      __typename
      # Content: Elements
      ...CraftPageBuilderElementHeadlineQuery
      ...CraftPageBuilderElementAnchorQuery
    }

    # Items
    sortItems {
      __typename
      ... on Craft_SortsSort {
        id
        sortTeaserTitle
        sortTeaserText
        sortTeaserImage {
          ...CraftImageFluid
        }
        sortTeaserLink {
          target
          text
          url
        }
      }
    }
  }
`;
